import React, { useState, useEffect } from 'react';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';

const Admin = () => {
  const [name, setName] = useState('');
  const [rights, setRights] = useState({
    canAccessAsStudent: false,
    canAccessAsParent: false,
    canAccessAsAdmin: false,
  });
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const response = await fetch(`${API_BASE_URL}/users`);
    if (response.ok) {
      const data = await response.json();
      setUsers(data.users);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userData = {
      name,
      rights,
    };

    // Send the user data to your backend API
    const response = await fetch(`${API_BASE_URL}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });

    if (response.ok) {
      const newUser = await response.json();
      // console.log('User created:', newUser);
      fetchUsers(); // Fetch users again to update the list
    } else {
      console.error('Failed to create user');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* ...form fields */}
        <button type="submit">Create User</button>
      </form>

      <h2>Users:</h2>
      <ul>
        {users.map((user) => (
          <li key={user.name}>
            Name: {user.name}, Rights: {JSON.stringify(user.rights)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Admin;
