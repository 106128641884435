import { shuffleArray } from "./utility";

const proverbs = [
    { proverb: 'A stitch in time saves nine', meaning: 'Solving problems before they happen saves time and money.' },
    { proverb: 'Better late than never', meaning: 'It’s better to do something late than not do it at all.' },
    { proverb: 'Birds of a feather flock together', meaning: 'People who are similar spend time together.' },
    { proverb: 'Cleanliness is next to godliness', meaning: 'Keeping your body, mind, and environment clean is virtuous.' },
    { proverb: 'Don’t count your chickens before they hatch', meaning: 'Don’t make plans based on future events that might not happen.' },
    { proverb: 'Every cloud has a silver lining', meaning: 'There is something good in every bad situation.' },
    { proverb: 'Fortune favors the bold', meaning: 'People who bravely go after what they want are more successful than those who try to live safely.' },
    { proverb: 'Haste makes waste', meaning: 'Rushing through things causes mistakes that result in time or resources being wasted.' },
    { proverb: 'If it ain’t broke, don’t fix it', meaning: 'Do not try to change something that already works well.' },
    { proverb: 'Look before you leap', meaning: 'Consider possible consequences before taking action.' },
    { proverb: 'No man is an island', meaning: 'Everyone needs help from other people to thrive.' },
    { proverb: 'Practice makes perfect', meaning: 'The more you do something, the better you will become at it.' },
    { proverb: 'The early bird catches the worm', meaning: 'The person who takes the earliest opportunity to do something will gain the advantage over others.' },
    { proverb: 'The pen is mightier than the sword', meaning: 'Writing is more effective than military power or violence.' },
    { proverb: 'When in Rome, do as the Romans do', meaning: 'Adapt yourself to the customs of the places you visit.' },
    { proverb: 'You can’t make an omelette without breaking eggs', meaning: 'It’s hard to achieve something important without causing unpleasant effects.' },
    { proverb: 'Absence makes the heart grow fonder', meaning: 'Being away from someone makes you like them more.' },
    { proverb: 'An apple a day keeps the doctor away', meaning: 'Eating healthily can lead to better overall health.' },
    { proverb: 'You can lead a horse to water, but you can’t make him drink', meaning: 'You can give someone an opportunity but not force them to take it.' },
    { proverb: 'Don’t bite the hand that feeds you', meaning: 'Don’t hurt anyone that helps you.' },
    { proverb: 'Actions speak louder than words', meaning: 'People’s actions show their true intentions, rather than what they say.' },
    { proverb: 'A penny saved is a penny earned', meaning: 'It’s wise to save money.' },
    { proverb: 'All that glitters is not gold', meaning: 'Appearances can be deceptive.' },
    { proverb: 'Beggars can’t be choosers', meaning: 'People with no other options must be content with what is offered.' },
    { proverb: 'Blood is thicker than water', meaning: 'Family relationships are stronger than other relationships.' }
];


export const generateProverbQuestion = () => {
    // Step 1: Randomly select a proverb
    const randomIndex = Math.floor(Math.random() * proverbs.length);
    const selectedProverb = proverbs[randomIndex];

    // Step 2: Formulate the question
    const question = `What is the moral or meaning of the proverb "${selectedProverb.proverb}"?`;

    // Step 3: Select two other meanings for incorrect options
    let incorrectOptions = [];
    while (incorrectOptions.length < 2) {
        const randomIncorrectIndex = Math.floor(Math.random() * proverbs.length);
        if (randomIncorrectIndex !== randomIndex) {
            const incorrectMeaning = proverbs[randomIncorrectIndex].meaning;
            if (!incorrectOptions.includes(incorrectMeaning)) {
                incorrectOptions.push(incorrectMeaning);
            }
        }
    }

    // Step 4: Shuffle the options
    const buttonOptions = shuffleArray([selectedProverb.meaning, ...incorrectOptions]);

    return {
        question,
        answer: selectedProverb.meaning,
        buttonOptions
    };
};
