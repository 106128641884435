import React, { useEffect, useState } from 'react';
import '../App.css';
import { useAuth0 } from '@auth0/auth0-react';
import useUserData from '../hooks/useUserData';
import { ReactComponent as MySVG } from '../images/fground.svg';


import { ReactComponent as PageBoxSVG } from '../images/pagebox.svg';
import { ReactComponent as PointsBoxSVG } from '../images/pointsbox.svg';
import { ReactComponent as LevelBoxSVG } from '../images/levelbox.svg';
import { ReactComponent as QuestionBoxSVG } from '../images/questionbox.svg';
import FynSVG from '../components/fyn';
import { greetings } from '../components/fyn';
import anime from 'animejs/lib/anime.es.js';
import { addPoints } from '../components/PointsComponent';
import { generateTextQuestion } from '../comprehensionQuestions/generateTextQuestion';
import { ReactComponent as GroundSVG } from '../images/assets/grass.svg';
import { generateComprehensionQuestions } from '../comprehensionQuestions/bookTitleFind';
import { generateComprehensionQuestion } from '../comprehensionQuestions/comprehension';

const ComprehensionCounty = () => {
  const { isAuthenticated, user } = useAuth0();
  const { isStudent, isParent, isAdmin, points, userData, fetchUserData, fetchGameData, updatePoints, studentName, levelUp } = useUserData();
  const [addedPoints, setAddedPoints] = useState(0);
  const [questionType, setQuestionType] = useState('simple');
  const [questionData, setQuestionData] = useState({});
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [isDancing, setIsDancing] = useState(false);
  const [level, setLevel] = useState(1);
  const [userRoles, setUserRoles] = useState([]);
  const [customMessage, setCustomMessage] = useState("");
  const [allQuestions, setAllQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(1);



  const [gameMode, setGameMode] = useState('');
  const [selectedQuestionType, setSelectedQuestionType] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [score, setScore] = useState(0);
  const [questionCount, setQuestionCount] = useState(1);
  const [gameEnded, setGameEnded] = useState(false);
  const positiveMessages = ['Well done!', 'Great job!', 'Excellent work!', 'Keep it up!', 'Fantastic!'];
  const [inputNumber, setInputNumber] = useState('');
  const pageName = "Comprehension County";
  const dbName = 'comprehensionCounty'

  const [book, setBook] = useState('');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [questions, setQuestions] = useState([]);



  useEffect(() => {
    if (isAuthenticated) {
      fetchUserData(user.sub, user.nickname, user.email);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (user && userRoles.length > 0) {
        const gameData = await fetchGameData(user.sub, dbName);
        if (gameData && gameData.level) {
          setLevel(gameData.level);  // Update the level state variable
        }
      }
    };

    fetchData();
  }, [user, userRoles, fetchGameData]);  // Don't forget to add fetchGameData to the dependency array

  useEffect(() => {
    if (isAuthenticated && user) {
      const roles = user['https://fynschool.com/roles'];
      const message = roles ? "Welcome, ${studentName}!" : "Welcome, guest!";
      const randomMessage =
        roles ? customMessages[Math.floor(Math.random() * customMessages.length)] : "Welcome, guest!";
      setCustomMessage(randomMessage);
      setUserRoles(roles || []);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    document.title = "Fynschool Comprehension County"
  }, []);

  const customMessages = [
    "Hello, dear guest!",
    "Nice to see you again!",
    "How's your day going?",
    "Ready to explore?",
    "I love my life as a fish!",
    "Hi, I'm Fyn your friendly fish",
    "Welcome to Comprehension County!"
  ];


  useEffect(() => {
    if (gameMode) {
      generateQuestion();
      setStartTime(Date.now());
    }
  }, [gameMode]);


  useEffect(() => {
    if (currentIndex >= 10) {
      endGame();
    }
  }, [currentIndex, allQuestions]);  // Added allQuestions to dependency array



  const goBackToSplash = () => {
    setGameMode('');
    resetGame();
  };

  function formatText(input) {
    // split the string into words on uppercase letters
    let words = input.split(/(?=[A-Z])/);

    // capitalize the first letter of each word and join them with a space
    let output = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    return output;
}

  const fetchBooksAndTitles = async (dbName) => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';
    const response = await fetch(`${API_BASE_URL}/api/books_and_titles/${dbName}`);

    if (response.ok) {
      const data = await response.json();
      // console.log("Books and Titles:", data);

      // Generate a random index
      const randomIndex = Math.floor(Math.random() * data.length);

      // Pick a random element from the array
      const randomElement = data[randomIndex];

      // Extract book and title and set the state variables
      setBook(randomElement._id.book);
      setTitle(randomElement._id.title);
      setText(randomElement._id.text);
      return randomElement._id;

    } else {
      // console.log("Failed to fetch books and titles");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const questionData = await generateComprehensionQuestions(dbName, book, title);
      if (questionData !== null) {
        setQuestionData(questionData);
        setSelectedQuestionType('multipleChoice');
      }
    };

    if (gameMode === 'multipleChoice') {
      fetchData();
    }
  }, [gameMode, dbName, book, title]);

  useEffect(() => {
    if (questionCount >= 10) {
      endGame();
    }
  }, [questionCount]);

  const generateQuestion = async () => {
    let questionTypes = ['multipleChoice'];
    let selectedQuestionType;

    if (gameMode === 'random') {
      selectedQuestionType = questionTypes[Math.floor(Math.random() * questionTypes.length)];
    } else {
      selectedQuestionType = gameMode;
    }

    // console.log("Selected Question Type:", selectedQuestionType); // Debugging line

    if (selectedQuestionType === 'multipleChoice') {
      // Fetch books and titles first and store the returned value in bookAndTitle
      const bookAndTitle = await fetchBooksAndTitles(dbName); // This line is changed
      if (bookAndTitle) {
        const { book, title } = bookAndTitle;
        const questionData = await generateComprehensionQuestions(dbName, book, title);
        setAllQuestions(questionData);
        setSelectedQuestionType('multipleChoice');
      }
    }
  };


  const handleAddPoints = (pointsToAdd) => {
    updatePoints(user.sub, pointsToAdd, selectedQuestionType); // Pass the selectedQuestionType as the gameName
};


const handleAnswerClick = async (selectedAnswer) => {
  if (selectedQuestionType === 'multipleChoice') {
    if (selectedAnswer === questionData[questionCount - 1].answer) {
      correctAnswer();
    } else {
      const audio = new Audio("/sounds/wrong.mp3");
      audio.volume = 0.2;
      audio.play();
    }
    // Simply increment the questionCount to move to the next question
    setQuestionCount(prevQuestionCount => prevQuestionCount + 1);
  } else if (selectedQuestionType === 'timesTables') {
    if (selectedAnswer === questionData[questionCount - 1].answer) {
      correctAnswer();
    }
    generateQuestion(); // Generate a new question
  }
};


const correctAnswer = () => {
  const audio = new Audio("/sounds/correct.mp3");
  audio.volume = 0.2;
  audio.play();

  setIsDancing(true); // Start dancing
  setTimeout(() => setIsDancing(false), 1000);
  setScore(prevScore => prevScore + 1);
};

const renderQuestionAndButtons = () => {
  let questionContent = null;

  if (questionData && questionData.length > 0 && questionCount > 0) {
    if (selectedQuestionType === 'multipleChoice') {
      questionContent = <p className="questionMaths">{questionCount}. {questionData[questionCount - 1].question}</p>;
    } else if (selectedQuestionType === 'numberLine') {
      questionContent = (
        <div className="number-line-question questionMaths">
          <NumberLineQuestion questionData={questionData} handleAnswerClick={handleAnswerClick} />
        </div>
      );
    } else {
      questionContent = <p className='questionMaths'>{questionCount}. {questionData.question}</p>;
    }
  } else {
    questionContent = <p>Loading questions...</p>;
  }

  let additionalContent = null;
  if (selectedQuestionType === 'columnMath' || selectedQuestionType === 'numberInWords') {
    additionalContent = <p className='questionMaths'>{inputNumber ? inputNumber : lastInputNumber ? lastInputNumber : '\u00A0'}</p>;
  }

  return (
    <div className="question">
      {questionContent}
      {renderButtons()}
      {additionalContent}
      <div className="back-button-container">
        <button onClick={goBackToSplash} className='fyns-button'>Back</button>
      </div>
    </div>
  );
};


  const endGame = () => {
    if (gameEnded) return;  // Add this line
    setGameEnded(true);

    setEndTime(Date.now());

    // Calculate and add points here
    let pointsToAdd = 0;
    if (selectedQuestionType === 'multipleChoice') {
      pointsToAdd = score * 3;
    } else if (selectedQuestionType === 'timesTables') {
      pointsToAdd = score * 2;
    }
    handleAddPoints(pointsToAdd);

    if (score === 10) {
      if (userRoles.length > 0) {
        levelUp(user.sub, dbName);
      }
      const audio = new Audio("/sounds/endgame.mp3");
      audio.volume = 0.2;
      audio.play();
    } else {
      const audio = new Audio("/sounds/gameover.mp3");
      audio.volume = 0.2;
      audio.play();
    }

    setInputNumber('');

  };

  const resetGame = () => {
    setGameMode('');
    setScore(0);
    setQuestionCount(1);
    setGameEnded(false);
    setStartTime(null);
    setEndTime(null);
    setInputNumber('');
    setBook('')
    setTitle('')
    setText('')
  };


  const renderButtons = () => {
    if (selectedQuestionType === 'multipleChoice') {
      // Check if questionData[questionCount - 1] exists before accessing buttonOptions
      const currentQuestion = questionData[questionCount - 1];
      if (currentQuestion && currentQuestion.buttonOptions) {
        return currentQuestion.buttonOptions.map((option, index) => (
          <button key={index} onClick={() => handleAnswerClick(option)}>
            {option}
          </button>
        ));
      } else {
        // Handle the case when questionData[questionCount - 1] is undefined
        return <p>Loading options...</p>;
      }
    }
  };


  return (
    <>
<div className='App'>
    <div className="App-background">
    <div className="sky">
      <GroundSVG className="grass" />
      </div>
      </div>

        </div>
    <div className='App-mid'>
        <FynSVG
        className={`svg-fyn ${isDancing ? "dancing" : ""}`}
        messages={greetings}
        defaultResponses={["I'm fine", "Help me Fyn"]}
        onResponse={(response) => {
          if (response === "I'm fine") {
            return "You got it!";
          } else if (response === "Help me Fyn") {
            // show the overlay
            setOverlayVisible(true);
            return "I got you!";
          }
        }}
        customMessages={customMessages} // Pass the custom message here
      />

          {overlayVisible && (
              <div onClick={() => setOverlayVisible(false)} style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  zIndex: 1000, // make sure it's above everything else
              }}>


              </div>
          )}
          <div className='pagebox'>
            <PageBoxSVG />
            <p className='page'>{pageName}</p>
          </div>
          <div className='pointsbox'>
            <PointsBoxSVG />
            <p className='points'> {points.toString()}</p>
          </div>
          <div className='levelbox'>
            <LevelBoxSVG />
            <p className='level'>Level: {level}</p>  {/* Use the level state variable here */}
          </div>
          {book && (
            <div className='questionbox'>
              <QuestionBoxSVG />
              <p className='questionText'>{formatText(book)}</p>
            </div>
          )}
        </div>


        <div className="App-header">
          <div className="button-container">
          {book && (
            <div className="book-info questionMaths">
              <div>
                {text.replace(/\\n\\n/g, '\n\n').split('\n\n').map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            </div>
          )}


      {gameMode ? (
            gameEnded ? (
              <div className="game-summary questionMaths">
                <p>{positiveMessages[Math.floor(Math.random() * positiveMessages.length)]}</p>
                <p>{score} out of 10</p>
                <p>Time: {Math.floor((endTime - startTime) / 1000)} seconds</p>
                <button onClick={resetGame}>Play Again...</button>
              </div>
            ) : (
              renderQuestionAndButtons()
            )
            ) : (
              <div className="splash-page">
            <button onClick={() => setGameMode('multipleChoice')}>Start Comprehension</button>
            </div>
          )
        }
          </div>
      </div>
    </>
  );

};

export default ComprehensionCounty;
