import { getRandomNumber, shuffleArray } from "./utility";

export const generateRoundingQuestion = (level) => {
    let numberToRound;
    let roundingBase; // 'ten', 'hundred', 'thousand', etc.
    let roundingPrecision; // 10, 100, 1000, etc.
    let maxBaseLevel; // Maximum rounding base level unlocked based on the current level

    // Determine the maximum base level unlocked
    if (level <= 5) {
        maxBaseLevel = 1;
    } else if (level <= 10) {
        maxBaseLevel = 2;
    } else if (level <= 18) {
        maxBaseLevel = 3;
    } else if (level <= 23) {
        maxBaseLevel = 4;
    } else if (level <= 25) {
        maxBaseLevel = 5;
    } else {
        maxBaseLevel = 6;
    }

    // Randomly choose a rounding base from those unlocked
    const chosenBaseLevel = getRandomNumber(1, maxBaseLevel);

    switch (chosenBaseLevel) {
        case 1:
            numberToRound = getRandomNumber(1, 99);
            roundingBase = 'ten';
            roundingPrecision = 10;
            break;
        case 2:
            numberToRound = getRandomNumber(100, 999);
            roundingBase = 'hundred';
            roundingPrecision = 100;
            break;
        case 3:
            numberToRound = getRandomNumber(1000, 9999);
            roundingBase = 'thousand';
            roundingPrecision = 1000;
            break;
        case 4:
            numberToRound = getRandomNumber(10000, 99999);
            roundingBase = 'ten thousand';
            roundingPrecision = 10000;
            break;
        case 5:
            numberToRound = getRandomNumber(100000, 999999);
            roundingBase = 'hundred thousand';
            roundingPrecision = 100000;
            break;
        case 6:
            numberToRound = getRandomNumber(1000000, 9999999);
            roundingBase = 'million';
            roundingPrecision = 1000000;
            break;
        default:
            // Fallback to the simplest case
            numberToRound = getRandomNumber(1, 99);
            roundingBase = 'ten';
            roundingPrecision = 10;
            break;
    }

    const correctAnswer = Math.round(numberToRound / roundingPrecision) * roundingPrecision;

    // Generate wrong answers; ensure they are unique and not equal to the correct answer
    let wrongAnswer1, wrongAnswer2;
    do {
        wrongAnswer1 = correctAnswer + roundingPrecision * getRandomNumber(-3, 3);
    } while (wrongAnswer1 === correctAnswer);

    do {
        wrongAnswer2 = correctAnswer + roundingPrecision * getRandomNumber(-3, 3);
    } while (wrongAnswer2 === correctAnswer || wrongAnswer2 === wrongAnswer1);

    const question = `What is ${numberToRound} rounded to the nearest ${roundingBase}?`;

    return {
        question,
        answer: correctAnswer,
        buttonOptions: shuffleArray([correctAnswer, wrongAnswer1, wrongAnswer2]),
    };
};
