import BaseScene from "./BaseScene"; // Adjust the path as necessary
import rrBackground from "../assets/backgrounds/ReasoningRainforest.jpg";
import backButton from "../assets/buttons/BackButton.png";
import { loadAllAssets } from './sharedAssets';

import sliderBarRR from "../assets/sliderBarRR.png"
import sliderHandleRR from "../assets/sliderHandleRR.png"

class ReasoningRainforestScene extends BaseScene {
    constructor() {
        super('ReasoningRainforestScene');
        this.currentTopicIndex = 0; // Start at the first topic
        this.currentButton = null; // To keep track of the current button displayed
        this.lastDirection = 1; // Default direction (down)

        this.level = 1;
    }

    init() {
        this.registry.set('debug', false);
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        if (isPortrait) {
            this.registry.set('isPortrait', true);
        }
    }

    preload() {
        this.preloadCommon();
        loadAllAssets(this);
        this.load.image('rrBackgroundImage', rrBackground); // Note the key 'backgroundImage' here
        this.load.image('backButton', backButton);

        this.load.image('sliderBar', sliderBarRR);
        this.load.image('sliderHandle', sliderHandleRR);
    }

    create() {
        const debug = this.registry.get('debug');
        const isPortrait = this.registry.get('isPortrait');
        const rrBg = this.setBackground('rrBackgroundImage'); // Use the key you've defined in preload
        this.backgroundOverlay()
        const backButtonXPosition = isPortrait? this.sys.game.config.width * 0.05 : this.sys.game.config.width * 0.05;
        const backButtonYPosition = isPortrait ? this.sys.game.config.height * 0.15 : this.sys.game.config.height * 0.2;
        this.createButton(backButtonXPosition, backButtonYPosition, 'backButton', null, () => this.scene.start('MenuScene'));

        this.backgroundOverlay()
        const userData = this.game.registry.get('userData') || {};
        const userRoles =this.game.registry.get('userRoles') || [];
        const baseFontSize = parseFloat(this.responsiveFontSize(10, 0.03).replace('px', ''));
        const strokeThickness = this.sys.game.config.width * 0.006;

        const fontSize = this.sys.game.config.width * 0.05;
        const fontSizeSmall = this.sys.game.config.width * 0.02;

        const pageBackgroundX = this.sys.game.config.width * -0.05;
        const pageBackgroundY = isPortrait ? this.sys.game.config.height * 0.1 : this.sys.game.config.height * 0.10;
        const pageBackgroundWidth = this.sys.game.config.width * 0.35;
        const pageBackgroundHeight = this.sys.game.config.height * 0.05;
        const pageXPosition = this.sys.game.config.width * 0.2;

        // Create the UI background
        this.createGradientBackground(pageBackgroundX, pageBackgroundY, pageBackgroundWidth, pageBackgroundHeight, 0xFBBC05, 0.8);


        // // Now, add other elements to your scene, like the animated text
        this.createAnimatedText(pageXPosition, pageBackgroundY, `Reasoning Rainforest`, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        }).setDepth(1000);

        const pointsBackgroundX = isPortrait ? this.sys.game.config.width * 0.65 : this.sys.game.config.width * 0.8;
        const pointsBackgroundY = isPortrait ? this.sys.game.config.height * 0.1 : this.sys.game.config.height * 0.10;
        const pointsBackgroundWidth = this.sys.game.config.width * 0.5;
        const pointsBackgroundHeight = this.sys.game.config.height * 0.07;
        const starYPosition = isPortrait ? this.sys.game.config.height * 0.07 : this.sys.game.config.height * 0.03;
        const starXPosition = isPortrait ? this.sys.game.config.width * 0.85 : this.sys.game.config.width * 0.9;
        const starScale = isPortrait ? 0.1 : 0.2;
        this.createGradientBackground(pointsBackgroundX, pointsBackgroundY, pointsBackgroundWidth, pointsBackgroundHeight, 0xFBBC05, 0.8, true);


        this.add.image(starXPosition, starYPosition, 'pointsStar')
        .setOrigin(0, 0) // Adjust origin as needed, e.g., 0.5 for center
        .setDepth(1000)
        .setScale(starScale);


        const musicButtonXPosition = this.sys.game.config.width * 0.95;
        const musicButtonYPosition = this.sys.game.config.height * 0.2;
        this.musicButton = this.createButton(
            musicButtonXPosition, // X position
            musicButtonYPosition, // Y position
            'musicButton', // Texture key for the button
            null, // Hover texture (null if not used)
            () => this.toggleMusicAndTint(), // Action on click
            0.5, // Scale multiplier
            false // Availability
        );

        const sliderX = this.sys.game.config.width * 0.5;
        const sliderY = this.sys.game.config.height * 0.8; // Note: Adjusting this to use height instead of width for placement

        // Create and style the text label for the slider
        let difficultyLabel = this.createAnimatedText(sliderX, this.sys.game.config.height * 0.9, "Choose the Difficulty!", {
            fontFamily: 'gaegu',
            fontSize: `${this.getBaseFontSize()}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: this.getStrokeThickness(),
            align: 'center'
        });

        const sliderBarScale = isPortrait ? this.sys.game.config.width * 0.5 : this.sys.game.config.width * 0.15;
        const sliderHandleScale =  sliderBarScale * 0.2;
        this.createSlider(sliderX, sliderY, sliderBarScale, sliderBarScale, sliderHandleScale, sliderHandleScale, difficultyLabel )



        const backButtonScale = isPortrait ? 0.5 : 1;
        this.createButton(backButtonXPosition, backButtonYPosition, 'backButton', null, () => this.scene.start('MenuScene'), backButtonScale);

        const menuButtonXPosition = isPortrait ? this.sys.game.config.width * 0.83 : this.sys.game.config.width * 0.7;
        const menuButtonYupPosition = isPortrait ? this.sys.game.config.height * 0.35 : this.sys.game.config.height * 0.4;
        const menuButtonYdownPosition = isPortrait ? this.sys.game.config.height * 0.75 : this.sys.game.config.height * 0.6;
        // Rotated and functional nav buttons
        const navButtonUp = this.createButton(menuButtonXPosition, menuButtonYupPosition, 'navButton', null, () => this.changeTopic(1));
        navButtonUp.angle = -90;  // Rotates the button to face North

        const navButtonDown = this.createButton(menuButtonXPosition, menuButtonYdownPosition, 'navButton', null, () => this.changeTopic(-1));
        navButtonDown.angle = 90;  // Rotates the button to face South


        this.currentTopic = [

            {
                key: 'palindrome',
                button: 'palindromeButton',
                reactComponent: 'generatePalindromeQuestion',
                parentScene: 'ReasoningRainforestScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'codeword',
                button: 'codewordButton',
                reactComponent: 'generateCodeWordQuestion',
                parentScene: 'ReasoningRainforestScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: userRoles.includes('developer')
            },
            {
                key: 'trueFalse',
                button: 'trueOrNotButton',
                reactComponent: 'generateTrueFalseQuestion',
                parentScene: 'ReasoningRainforestScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            }
        ];

        this.showCurrentButton(this.lastDirection);

        // Set up key listeners
        this.setupKeyboardInputs(
            () => this.changeTopic(1),
            () => this.changeTopic(-1)
        );
        // this.input.keyboard.on('keydown-UP', () => this.changeTopic(1));
        // this.input.keyboard.on('keydown-DOWN', () => this.changeTopic(-1));
    }
}

export default ReasoningRainforestScene;
