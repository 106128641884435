import { getRandomNumber, shuffleArray } from "./utility";

const sweets = [
    { name: "Chocolate Coin", price: 10 },
    { name: "Chewing Gum", price: 50 },
    { name: "Lollipop", price: 20 },
    { name: "Chocolate Mice", price: 13 },
    { name: "Jelly Beans", price: 15 },
    { name: "Candy Cane", price: 25 },
    { name: "Bubble Gum", price: 7 },
    { name: "Sherbet Lemons", price: 30 },
    { name: "Fizzy Cola Bottles", price: 22 },
    { name: "Gummy Bears", price: 18 },
    { name: "Licorice Wheels", price: 12 },
    { name: "Marshmallows", price: 17 },
    { name: "Toffee Apple", price: 35 },
    { name: "Cotton Candy", price: 40 },
];

const coins = [200, 100, 50, 20, 10, 5, 2, 1]; // Coins in pence

export const generateMoneyQuestion = (level) => {
    const sweet = sweets[getRandomNumber(0, sweets.length - 1)];
    const question = `How can you pay exactly for a ${sweet.name} costing ${sweet.price}p using coins?`;

    const correctAnswer = calculateCoinCombination(sweet.price);
    const wrongAnswer1 = calculateCoinCombination(sweet.price + getRandomNumber(1, 3) * 5); // Slightly more than the price
    const wrongAnswer2 = calculateCoinCombination(sweet.price - getRandomNumber(1, 3) * 5); // Slightly less than the price

    // Ensure all answers are valid
    const buttonOptions = [correctAnswer, wrongAnswer1, wrongAnswer2].filter(Boolean);

    return {
        question,
        answer: correctAnswer,
        buttonOptions: shuffleArray(buttonOptions)
    };
};

const calculateCoinCombination = (amount) => {
    let total = 0;
    let combination = [];

    const addCoinToCombination = (coin) => {
        total += coin;
        combination.push(coin === 100 ? "£1" : coin === 200 ? "£2" : `${coin}p`);
    };

    let index = 0;
    while (total < amount && index < coins.length) {
        while (total + coins[index] <= amount) {
            addCoinToCombination(coins[index]);
        }
        index++;
    }

    return combination.length > 0 ? combination.join(' + ') : null;
};
