import React, { useEffect, useState } from 'react';
import { Link, Outlet } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import { useAuth0 } from "@auth0/auth0-react";
import '../Home.css';

import LogoPNG from '../images/logo.png';
import bigLogoPNG from '../images/biglogo.png';
import WebImagePNG from '../images/webimage.png';

const Mathslevels = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
    const redirectUri = isLocalhost ? 'http://localhost:3000/mathsmeadow' : 'https://www.fynschool.com/mathsmeadow';


    useEffect(() => {
        document.title = "Fynschool Maths Levels";
      }, []);

      const priceStyle = {
        textDecoration: 'line-through',
        color: '#999'
    };

    const discountStyle = {
        color: 'red',
        fontWeight: 'bold'
    };

    const popularStyle = {
        fontWeight: 'bold',
        fontSize: '1.5em'
    };

    return (
        <div>


            <div className='pageWrapper'>
                <div className='page1'>
                <div className='levels-container'>

                    <h1>Maths Meadow Levels.</h1>
                    <h2>Simple Sums</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Level</th>
                                <th>Number Range</th>
                                <th>Operators</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1-3</td>
                                <td>Up to 5</td>
                                <td>+ and -</td>
                            </tr>
                            <tr>
                                <td>1-6</td>
                                <td>Up to 10</td>
                                <td>+ and -</td>
                            </tr>
                            <tr>
                                <td>1-9</td>
                                <td>Up to 10. Multiply up to 5</td>
                                <td>+, - and x</td>
                            </tr>
                            <tr>
                                <td>1-12</td>
                                <td>Up to 15. Multiply up to 10</td>
                                <td>+, - and x</td>
                            </tr>
                            <tr>
                                <td>1-15</td>
                                <td>Up to 20. Multiply up to 10</td>
                                <td>+, - and x</td>
                            </tr>
                            <tr>
                                <td>1-18</td>
                                <td>Up to 20. Multiply up to 10. Divide to 5</td>
                                <td>+, -, x and ÷</td>
                            </tr>
                            <tr>
                                <td>1-21</td>
                                <td>Up to 30. Multiply up to 12. Divide to 10</td>
                                <td>+, -, x and ÷</td>
                            </tr>
                            <tr>
                                <td>1-24</td>
                                <td>Up to 50. Multiply up to 12. Divide to 12</td>
                                <td>+, -, x and ÷</td>
                            </tr>
                            <tr>
                                <td>24 and up</td>
                                <td>Up to 20. Multiply up to 20. Divide to 20</td>
                                <td>+, -, x and ÷</td>
                            </tr>

                        </tbody>
                    </table>

            </div>
            </div>


            </div>

        </div>
    );
};

export default Mathslevels;