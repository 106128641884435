// generateSequenceQuestion.js

import { getRandomNumber, shuffleArray, getRandomDecimal } from "./utility";

export const generateSequenceQuestion = (level) => {
    const sequenceLength = 5;
    const missingIndex = getRandomNumber(0, sequenceLength - 1);

    let sequence = [];
    let correctAnswer = '';
    let wrongAnswers = [];

    // Determine if decimals should be introduced based on level
    let introduceDecimals = level > 20;
    let decimalPrecision = introduceDecimals ? 1 : 0;

    // Define pattern types based on level
    // 1: Simple Arithmetic (Addition/Subtraction)
    // 2: Arithmetic with Multiplication
    let availablePatternTypes;

    if (level <= 10) {
        availablePatternTypes = [1]; // Only Simple Arithmetic
    } else if (level <= 20) {
        availablePatternTypes = [1, 2]; // Simple Arithmetic and Arithmetic with Multiplication
    } else {
        availablePatternTypes = [1, 2]; // Continue with Simple Arithmetic and Arithmetic with Multiplication
        // If you want to introduce more patterns at higher levels, you can add them back with proper safeguards
    }

    // Select patternType randomly from available types
    const patternType = availablePatternTypes[getRandomNumber(0, availablePatternTypes.length - 1)];

    // Define sequence based on patternType
    switch (patternType) {
        case 1:
            // Simple Arithmetic Sequence (Addition or Subtraction)
            ({ sequence, correctAnswer } = generateSimpleArithmeticSequence(level, sequenceLength, missingIndex, decimalPrecision));
            break;
        case 2:
            // Arithmetic Sequence with Multiplication
            ({ sequence, correctAnswer } = generateArithmeticWithMultiplicationSequence(level, sequenceLength, missingIndex, decimalPrecision));
            break;
        default:
            // Fallback to Simple Arithmetic if patternType is somehow invalid
            ({ sequence, correctAnswer } = generateSimpleArithmeticSequence(level, sequenceLength, missingIndex, decimalPrecision));
            break;
    }

    // Generate wrong answers with a maximum of 5 attempts each to prevent infinite loops
    wrongAnswers = generateWrongAnswers(correctAnswer, decimalPrecision, 2, 5);

    const question = `Complete the sequence: ${sequence.join(', ')}`;

    return {
        question,
        answer: correctAnswer,
        buttonOptions: shuffleArray([correctAnswer, ...wrongAnswers]),
        key: "sequence",
        scene: "Question1Scene"
    };
};

// Helper Functions

const generateSimpleArithmeticSequence = (level, sequenceLength, missingIndex, decimalPrecision) => {
    let sequence = [];
    let correctAnswer = '';
    let increment;

    // Define increment based on level
    if (level <= 5) {
        increment = getRandomNumber(1, 3);
    } else {
        increment = getRandomNumber(2, 8);
    }

    // Randomly decide between addition and subtraction
    const operation = getRandomNumber(0, 1) === 0 ? 'add' : 'subtract';

    let startingNumber;

    // For subtraction at low levels, ensure no negative numbers
    if (operation === 'subtract' && level <= 10) {
        // Calculate the minimum starting number to avoid negatives
        const minStartingNumber = increment * (sequenceLength - 1) + 1;
        const maxStartingNumber = minStartingNumber + 10; // Adjust as needed
        startingNumber = getRandomNumber(minStartingNumber, maxStartingNumber);
    } else {
        // For addition or higher level subtraction
        startingNumber = introduceDecimals(level, true) ? getRandomDecimal(1, 20, decimalPrecision) : getRandomNumber(1, 20);
    }

    for (let i = 0; i < sequenceLength; i++) {
        let value;
        if (operation === 'add') {
            value = startingNumber + increment * i;
        } else {
            value = startingNumber - increment * i;
            // Ensure positive numbers for subtraction at lower levels
            if (value < 0 && level <= 10) {
                // Fallback to addition if negative
                value = startingNumber + increment * i;
            }
        }

        // Format value with decimal precision if needed
        value = decimalPrecision > 0 ? value.toFixed(decimalPrecision) : value.toString();

        sequence.push(i === missingIndex ? '_' : value);
    }

    // Calculate correct answer
    if (operation === 'add') {
        correctAnswer = decimalPrecision > 0 ? (startingNumber + increment * missingIndex).toFixed(decimalPrecision) : (startingNumber + increment * missingIndex).toString();
    } else {
        correctAnswer = decimalPrecision > 0 ? (startingNumber - increment * missingIndex).toFixed(decimalPrecision) : (startingNumber - increment * missingIndex).toString();
    }

    // Debugging Log (Optional)
    // console.log(`Pattern: Simple Arithmetic (${operation}), Starting Number: ${startingNumber}, Increment: ${increment}, Sequence: ${sequence.join(', ')}, Correct Answer: ${correctAnswer}`);

    return { sequence, correctAnswer };
};

const generateArithmeticWithMultiplicationSequence = (level, sequenceLength, missingIndex, decimalPrecision) => {
    let sequence = [];
    let correctAnswer = '';
    let increment = getRandomNumber(1, 5);
    let multiplier = level <= 15 ? getRandomNumber(2, 4) : getRandomNumber(2, 6);
    let startingNumber = introduceDecimals(level, false) ? getRandomDecimal(1, 10, decimalPrecision) : getRandomNumber(1, 10); // Starting number between 1 and 10

    for (let i = 0; i < sequenceLength; i++) {
        let value = (startingNumber + increment * i) * multiplier;
        value = decimalPrecision > 0 ? value.toFixed(decimalPrecision) : value.toString();
        sequence.push(i === missingIndex ? '_' : value);
    }

    // Calculate correct answer
    correctAnswer = decimalPrecision > 0 ? ((startingNumber + increment * missingIndex) * multiplier).toFixed(decimalPrecision) : ((startingNumber + increment * missingIndex) * multiplier).toString();

    // Debugging Log (Optional)
    // console.log(`Pattern: Arithmetic with Multiplication, Starting Number: ${startingNumber}, Increment: ${increment}, Multiplier: ${multiplier}, Sequence: ${sequence.join(', ')}, Correct Answer: ${correctAnswer}`);

    return { sequence, correctAnswer };
};

const generateWrongAnswers = (correctAnswer, decimalPrecision, numberOfWrongAnswers, maxAttempts) => {
    const wrongAnswers = new Set();
    let attempts = 0;
    const maxTotalAttempts = maxAttempts * numberOfWrongAnswers;

    while (wrongAnswers.size < numberOfWrongAnswers && attempts < maxTotalAttempts) {
        let variation = getRandomNumber(1, 3);
        let sign = getRandomNumber(0, 1) === 0 ? -1 : 1;
        let wrongAnswer = parseFloat(correctAnswer) + sign * variation;

        // Adjust decimal precision
        wrongAnswer = decimalPrecision > 0 ? wrongAnswer.toFixed(decimalPrecision) : Math.round(wrongAnswer).toString();

        // Avoid duplicate wrong answers and the correct answer
        if (wrongAnswer !== correctAnswer && !wrongAnswers.has(wrongAnswer)) {
            wrongAnswers.add(wrongAnswer);
        }

        attempts++;
    }

    // If not enough unique wrong answers, fill the rest deterministically
    while (wrongAnswers.size < numberOfWrongAnswers) {
        let fallbackVariation = getRandomNumber(4, 6);
        let sign = getRandomNumber(0, 1) === 0 ? -1 : 1;
        let fallbackWrong = parseFloat(correctAnswer) + sign * fallbackVariation;
        fallbackWrong = decimalPrecision > 0 ? fallbackWrong.toFixed(decimalPrecision) : Math.round(fallbackWrong).toString();

        if (fallbackWrong !== correctAnswer && !wrongAnswers.has(fallbackWrong)) {
            wrongAnswers.add(fallbackWrong);
        }
    }

    return Array.from(wrongAnswers);
};

// Helper function to determine if decimals should be introduced
const introduceDecimals = (level, forAdditionSubtraction) => {
    if (forAdditionSubtraction) {
        return level > 20; // Introduce decimals for addition/subtraction only at high levels
    }
    return level > 20; // Similarly, adjust as needed for other patterns
};
