import React, { useState, useEffect } from 'react';
import { ReactComponent as Fyn } from '../images/fyn.svg';
import Popup from 'reactjs-popup';

const stockMessages = [
  "Hello there!",
  "You look great today!",
  "Ready to learn something new?",
  "Nice to see you again!",
  "How's your day going?",
  "Ready to explore?",
  "I love my splish splash fish life!",
  "Hi, I'm Fyn your friendly fish",
  "I'm so fish-tastic, I forgot how to swim! Just kidding 🐟",
  "Hi, I'm Fyn, your friendly fish. Don't worry, I won't clam up on you!",
  "Why did the fish blush? Because it saw the ocean's bottom! 🐠",
  "I'm swimming in knowledge, want to dive in?",
  "You know what's my favorite school? Fish School!",
  "I've got a joke for you! What do you call a fish with no eyes? Fsh!",
  "Feeling Fyntastic today, how about you?",
  "Let's make a splash in learning!",
  "They say there are plenty of fish in the sea, but none like me!",
  "I'm all about that bass, no treble!",
  "I'm a fish out of water when it comes to dry humor!",
  "Why did the fish get kicked out of school? Because he was always swimming below 'C' level!",
  "I'm so good at swimming, I've got my Ph.D. in Fish-ics!",
  "Why don't fish do well in school? Because they're always swimming below the 'C'!",
  "I told my friend I was feeling a bit 'gill-ty' today. Get it? 🐟",
  "My friend is not a selfish fish, He's a shellfish!",
  "Why did the fish always know how much it weighed? Because it had its own scales!",
  "I'm so fish-tastic, I've got my own 'stream'ing service!",
  "Ready to tackle today's lessons fin-first?",
  "Let's dive deep into today's study material!",
  "Swim against the current, and you'll find knowledge!",
  "Don't flounder around, let's get studying!",
  "Just keep swimming through those books!",
  "Let's make today's study session a real 'splash'!",
  "You're o-fish-ally awesome at learning!",
  "Let's reel in some knowledge today!",
  "Don't be koi, ask me anything you want to know!",
  "You're swimmingly good at this subject!",
  "What's a fish's favorite instrument? The bass guitar!",
  "Why did the fish get bad grades? Because he was below sea level!",
  "What's a fish's favorite country? Finland!",
  "Why did the fish blush? Because it saw the sea-weed!",
  "What's a fish's favorite game? Go Fish!",
  "Why did the fish stay in school? To avoid the net!",
  "Why did the fish refuse to play cards? Because he was afraid of the net!",
  "What's a fish's favorite TV show? Whale of Fortune!",
  "Click on me and I'll try to help!",
  "Why don't fish play football? Because they are afraid of the nets!"
];


export const greetings = [
    "Hello",
    "Are you okay?",
    "What's up?",
    "Hi there!",
    "Greetings and salutations!",
    "Hello, sunshine!",
    "Hey, howdy, hey!",
    "Warmest welcome!",
    "Hola, amigo!",
    "How's it going?",
    "What's up, buttercup?",
    "Top of the morning to you!",
    "Bonjour, mon ami!",
    "G'day, mate!",
    "Aloha, everyone!",
    "Salut, my friend!",
    "Hey, good-looking!",
    "Yo, what's cracking?",
    "Howdy-doody!",
    "Wassup, champ?",
    "Hi, lovely people!",
    "Greetings, Humans!",
  ];

  const FynSVG = ({ className, isDancing = false, messages = [], defaultResponses = [], onResponse, customMessages = [] }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [responses, setResponses] = useState([]);
    const [fynResponse, setFynResponse] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const finalClassName = isDancing ? `${className} dancing` : className;


    const showPopup = () => {
      const allMessages = [...stockMessages, ...customMessages]; // Combine stock with custom messages
      const randomMessage = allMessages.length > 0 ? allMessages[Math.floor(Math.random() * allMessages.length)] : "You okay, buddy?";
      setIsOpen(true);
      setMessage(randomMessage);
      setResponses(defaultResponses);
      setFynResponse(null);
    };


    useEffect(() => {
      let timeoutId; // Declare a variable to keep track of the timeout ID

      const scheduleNextPopup = () => {
        const delay = Math.floor(Math.random() * (80000 - 40000 + 1)) + 100000;
        clearTimeout(timeoutId); // Clear any existing timeout
        timeoutId = setTimeout(() => { // Store the new timeout ID
          showPopup();
          setTimeout(closePopup, 5000);
          scheduleNextPopup();
        }, delay);
      };

      scheduleNextPopup();

      return () => clearTimeout(timeoutId); // Clear the timeout when the component unmounts
    }, [customMessages]);



    const handleClick = () => {
        setIsOpen(true);
        setIsClicked(true);
        setMessage(messages[Math.floor(Math.random() * messages.length)]);
        setResponses(defaultResponses);
        setFynResponse(null); // make sure to reset the response when opening the popup
    };

    const closePopup = () => {
        setIsOpen(false);
        setIsClicked(false);

    };

    return (
        <>

            <Fyn className={finalClassName} onClick={handleClick}></Fyn>
            {/* <FynSVGImage className={className} onClick={handleClick} /> */}
            <Popup open={isOpen} closeOnDocumentClick onClose={closePopup}>
        <div className="speech-bubble">
          <p>{message}</p>
          {isClicked && responses.map((response, index) => (
            <button key={index} onClick={() => {
              // call the onResponse function and save its return value
              const fynReply = onResponse(response);
              setFynResponse(fynReply);
              // clear the original message and responses
              setMessage("");
              setResponses([]);
              // set a delay to close the popup
              setTimeout(closePopup, 2000);
            }}>{response}</button>
          ))}
          {/* show Fyn's response if it exists */}
          {fynResponse && <p>{fynResponse}</p>}
        </div>
      </Popup>
        </>
    );
};

export default FynSVG;
