import { getRandomNumber, shuffleArray } from "./utility";

export const generateTimesTableQuestion = (level) => {

    let maxMultiplier;
    // Define the maximum multiplier for each level
    if (level <= 3) {
        maxMultiplier = 2;
    } else if (level <= 6) {
        maxMultiplier = 3;
    } else if (level <= 9) {
        maxMultiplier = 4;
    } else if (level <= 12) {
        maxMultiplier = 5;
    } else if (level <= 15) {
        maxMultiplier = 6;
    } else if (level <= 18) {
        maxMultiplier = 7;
    } else if (level <= 21) {
        maxMultiplier = 8;
    } else if (level <= 24) {
        maxMultiplier = 9;
    } else if (level <= 27) {
        maxMultiplier = 10;
    } else {
        maxMultiplier = 12;  // Up to 12 times table for the last levels
    }
    // Generate two random numbers within the defined range
    let num1 = getRandomNumber(2, maxMultiplier);
    let num2 = getRandomNumber(2, 12); // The second number can be up to 12

    // Calculate the correct answer
    let correctAnswer = num1 * num2;

    // Generate a question with the missing part always being the answer
    let question = `${num1} x ${num2} = ?`;
    let answer = correctAnswer;

    // Generate more challenging wrong answers
    let [wrongAnswer1, wrongAnswer2] = generateChallengingWrongAnswers(correctAnswer);

    return {
        question,
        answer: answer.toString(),
        buttonOptions: shuffleArray([answer.toString(), wrongAnswer1.toString(), wrongAnswer2.toString()])
    };
};

function generateChallengingWrongAnswers(correctAnswer) {
    let w1, w2;
    // Create wrong answers that are close to the correct answer
    w1 = correctAnswer + (correctAnswer % 2 === 0 ? 2 : -2); // Add or subtract 2
    w2 = correctAnswer + (correctAnswer % 3 === 0 ? 3 : -3); // Add or subtract 3

    // Adjust if they accidentally become the correct answer
    w1 = (w1 === correctAnswer) ? w1 + 1 : w1;
    w2 = (w2 === correctAnswer || w2 === w1) ? w2 + 1 : w2;

    return [w1, w2];
}