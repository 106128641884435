import Phaser from "phaser";
import backgroundImage from '../assets/meadow.jpg';

class MainScene extends Phaser.Scene {
    constructor() {
        super('MainScene');
    }

    init(data) {
        this.welcomeMessage = data.message || 'Fyn';
    }

    preload () {
        this.load.image('background', backgroundImage);
        this.load.spritesheet('buttons', buttonsSprite, { frameWidth: 661, frameHeight: 202 });
    }

    create() {
        // Background setup
        let bg = this.add.image(this.cameras.main.width / 2, this.cameras.main.height / 2, 'background');
        bg.setDisplaySize(this.cameras.main.width, this.cameras.main.height);

        // Create an overlay
        this.createOverlay(0.9, 0.9, 0x000000, 0.5);

        this.add.text(this.cameras.main.centerX, 100, `Welcome to ${this.welcomeMessage}'s Mansion`, {fill: '#fff'}).setOrigin(0.5,0.5);


        const questions = this.game.registry.get('questions');
        if (questions) {
            // console.log("Questions loaded:", questions);
        } else {
            // console.log("No questions found in the registry.");
        }

        // Calculate the positions for the buttons to be equally spaced
        const numberOfButtons = 4;
        const screenWidth = this.cameras.main.width;
        const buttonWidth = 661 * 0.25; // Adjust the scale factor accordingly
        const totalButtonsWidth = numberOfButtons * buttonWidth;
        const spacing = (screenWidth - totalButtonsWidth) / (numberOfButtons + 1);
        let startingX = spacing + buttonWidth / 2; // Initial position of the first button

        for (let i = 0; i < numberOfButtons; i++) {
            // Select a random frame for each button
            let frameIndex = Phaser.Math.Between(0, 11);

            // Add the button sprite with a random frame
            let buttonSprite = this.add.sprite(startingX + (i * (buttonWidth + spacing)), this.cameras.main.centerY, 'buttons', frameIndex).setOrigin(0.5, 0.5).setScale(0.25);

            // Optionally, add an interactive behavior here
            buttonSprite.setInteractive().on('pointerdown', () => {
                // console.log(`Button ${i + 1} clicked!`);
                // You can add more functionality here like navigation or command execution
                const onButtonPress = this.game.registry.get('onButtonPress');
                if (onButtonPress) {
                    onButtonPress(i); // Call the callback function with the button index
                }
            });
        }
    }

    createOverlay(widthPercent, heightPercent, color, alpha) {
        const { width, height } = this.cameras.main;

        // Calculate overlay size based on percentage
        const overlayWidth = width * widthPercent;
        const overlayHeight = height * heightPercent;

        // Calculate top-left corner position
        const x = (width - overlayWidth) / 2;
        const y = (height - overlayHeight) / 2;

        // Create a Graphics object for the overlay
        let overlay = this.add.graphics();

        // Set the fill style (color and alpha)
        overlay.fillStyle(color, alpha);

        // Draw the rectangle
        overlay.fillRect(x, y, overlayWidth, overlayHeight);
    }
}

export default MainScene;
