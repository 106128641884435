
import React, { useEffect, useRef, useState } from 'react';
import '../css/React.css';
import Menu from './Menu';

const Profiles = () => {


  return (
    <div className="profiles-page">
      <Menu />
      <h1>Profiles will show here</h1>
    </div>
  );
};

export default Profiles;
