import { getRandomNumber, shuffleArray } from "./utility";

const speechWords = [
    'Whispered', 'Shouted', 'Muttered', 'Exclaimed', 'Yelled', 'Replied',
    'Cried', 'Snapped', 'Questioned', 'Inquired', 'Stated', 'Declared',
    'Announced', 'Mumbled', 'Groaned', 'Grumbled', 'Sighed', 'Hissed',
    'Roared', 'Bellowed', 'Chirped', 'Murmured', 'Screamed', 'Laughed',
    'Giggled', 'Whined', 'Complained', 'Moaned', 'Commanded', 'Ordered',
    'Suggested', 'Offered', 'Begged', 'Pleaded', 'Insisted', 'Observed',
    'Noted', 'Quizzed', 'Retorted', 'Argued', 'Countered', 'Added',
    'Admitted', 'Confessed', 'Conceded', 'Protested', 'Objected',
    'Interrupted', 'Interjected', 'Lamented'
  ];

  const exampleSentences = [
    'He _____, "Come here!"',
    'She _____, "I don\'t know."',
    'They _____, "That\'s amazing!"',
    'She _____, "Stop it right now."',
    'He _____, "I love you."',
    'The teacher _____, "Open your books."',
    'The dog _____, "Woof woof!"', // This one is for a little humor!
    'The child _____, "I want ice cream."',
    'The officer _____, "Show me your ID."',
    'He _____, "What time is it?"',
    'She _____, "I\'m tired."',
    'The captain _____, "All hands on deck."',
    'The coach _____, "Run faster!"',
    'The artist _____, "This is my masterpiece."',
    'The scientist _____, "Eureka!"',
    'The traveler _____, "I\'m lost."',
    'She _____, "You\'re late."',
    'He _____, "Check this out."',
    'The musician _____, "Let\'s jam!"',
    'The actor _____, "To be or not to be."'
  ];
  
  export const generateSpeechWordsQuestion = () => {
    // Step 1: Randomly select a speech word
    const randomIndex = Math.floor(Math.random() * speechWords.length);
    const selectedWord = speechWords[randomIndex];

    // Step 2: Randomly select an example sentence
    const randomSentenceIndex = Math.floor(Math.random() * exampleSentences.length);
    const question = exampleSentences[randomSentenceIndex];

    // Step 3: Randomly select two other words for incorrect options
    let incorrectOptions = [];
    while (incorrectOptions.length < 2) {
      const randomIncorrectIndex = Math.floor(Math.random() * speechWords.length);
      if (randomIncorrectIndex !== randomIndex) {
        const incorrectWord = speechWords[randomIncorrectIndex];
        if (!incorrectOptions.includes(incorrectWord)) {
          incorrectOptions.push(incorrectWord);
        }
      }
    }

    // Step 4: Shuffle the options
    const buttonOptions = shuffleArray([selectedWord, ...incorrectOptions]);

    return {
      question,
      answer: selectedWord,
      buttonOptions
    };
  };