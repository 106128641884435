import { shuffleArray } from "./utility";

const similies = [
'As busy as a bee',
'As slow as a snail',
'As brave as a lion',
'As tall as a giraffe',
'As fast as a cheetah',
'As quiet as a mouse',
'As light as a feather',
'As strong as an ox',
'As sweet as sugar',
'As cold as ice',
'As hot as the sun',
'As shiny as a star',
'As slippery as a fish',
'As happy as a clam',
'As wise as an owl',
'As big as an elephant',
'As small as an ant',
'As soft as a pillow',
'As hard as a rock',
'As bright as the moon',
'As clear as crystal',
'As loud as thunder',
'As gentle as a lamb',
'As smooth as silk',
'As sharp as a knife',
'As flat as a pancake',
'As wet as a fish',
'As dry as a bone',
'As old as the hills',
'As young as a baby',
'As colorful as a rainbow',
'As cool as a cucumber',
'As fresh as a daisy',
'As empty as a bucket',
'As full as a tick',
'As rich as a king',
'As poor as a church mouse',
'As neat as a pin',
'As messy as a pigpen',
'As ugly as a toad',
'As pretty as a picture',
'As silly as a goose',
'As serious as a judge',
'As easy as pie',
'As difficult as a puzzle',
'As heavy as a hippo',
'As light as air',
'As clean as a whistle',
'As dirty as mud',
'As noisy as a drum'
]

export const generateSimileQuestion = () => {
  // Step 1: Randomly select a simile
  const randomIndex = Math.floor(Math.random() * similies.length);
  const selectedSimile = similies[randomIndex];

  // Step 2: Split the simile into words
  const words = selectedSimile.split(' ');

  // Step 3: Remove the last word and replace it with a blank
  const lastWord = words.pop();
  const question = `${words.join(' ')} __________`;

  // Step 4: Randomly select two other last words for incorrect options
  let incorrectOptions = [];
  while (incorrectOptions.length < 2) {
    const randomIncorrectIndex = Math.floor(Math.random() * similies.length);
    if (randomIncorrectIndex !== randomIndex) {
      const incorrectSimile = similies[randomIncorrectIndex];
      const incorrectLastWord = incorrectSimile.split(' ').pop();
      if (!incorrectOptions.includes(incorrectLastWord)) {
        incorrectOptions.push(incorrectLastWord);
      }
    }
  }

  // Step 5: Shuffle the options
  const buttonOptions = shuffleArray([lastWord, ...incorrectOptions]);

  return {
    question,
    answer: lastWord,
    buttonOptions
  };
};