import { getRandomNumber, shuffleArray } from "./utility";

export const generateTimeQuestion = (level) => {
  let startHour, startMinute, question, correctAnswer, formattedStartHour, formattedStartMinute, wa1Minute, wa1Hour, wa2Hour, wa2Minute, wa3Hour, wa3Minute;
  let wrongAnswers = [];
  let patternType;

  // Determine pattern type based on level
  if (level <= 6) {
    patternType = getRandomNumber(1, 2);; // AM/PM distinction and basic 24-hour conversion
  } else if (level <= 12) {
    patternType = getRandomNumber(1, 3); // More detailed 12-hour and 24-hour conversions
  } else if (level <= 18) {
    patternType = getRandomNumber(1, 4); // Adding specific time durations
  } else {
    patternType = getRandomNumber(1, 5); // Complex time calculations
  }

  switch (patternType) {
    case 1:
      // Pattern Type 1: AM/PM distinction
      startHour = getRandomNumber(0, 23);
      formattedStartHour = startHour < 10 ? `0${startHour}` : `${startHour}`;
      let amPm = startHour < 12 ? 'AM' : 'PM';
      // Use a more direct question format
      question = `Would ${formattedStartHour}:00 be considered AM or PM?`;
      correctAnswer = amPm;
      wrongAnswers.push(amPm === 'AM' ? 'PM' : 'AM');
      break;

// Case for converting between 12-hour and 24-hour formats
case 2:
  startHour = getRandomNumber(0, 23);
  // Format the hour to include a leading zero if less than 10
  formattedStartHour = startHour < 10 ? `0${startHour}` : `${startHour}`;

  question = `Convert ${formattedStartHour}:00 to 12-hour format.`;
  correctAnswer = `${startHour % 12 === 0 ? 12 : startHour % 12}:00 ${startHour < 12 ? 'AM' : 'PM'}`;

  // Generate two wrong answers with offsets and one direct opposite
  let wrongHour1 = (startHour % 12) + 1;
  let wrongHour2 = (startHour % 12) - 1;

  // Correct for boundaries
  wrongHour1 = wrongHour1 === 0 ? 11 : (wrongHour1 === 13 ? 1 : wrongHour1);
  wrongHour2 = wrongHour2 <= 0 ? 12 : (wrongHour2 === -1 ? 11 : wrongHour2);

  // Flip AM/PM for the direct opposite answer
  let oppositeAmPm = startHour < 12 ? "PM" : "AM";
  let oppositeAnswer = `${startHour % 12 === 0 ? 12 : startHour % 12}:00 ${oppositeAmPm}`;

  // Construct other wrong answers, ensuring formatting matches expectations
  wrongAnswers.push(`${wrongHour1 < 10 ? '0' + wrongHour1 : wrongHour1}:00 ${startHour < 12 ? 'AM' : 'PM'}`);
  wrongAnswers.push(`${wrongHour2 < 10 ? '0' + wrongHour2 : wrongHour2}:00 ${startHour < 12 ? 'AM' : 'PM'}`);
  wrongAnswers.push(oppositeAnswer);

  break;

  case 3:
  // Pattern Type 3: Adding specific durations


  startHour = getRandomNumber(0, 23);
  startMinute = getRandomNumber(0, 59);
  // Ensure formatting occurs right after values are determined
  formattedStartHour = startHour < 10 ? `0${startHour}` : `${startHour}`;
  formattedStartMinute = startMinute < 10 ? `0${startMinute}` : `${startMinute}`;

  let durationMinutes = [15, 30, 45][getRandomNumber(0, 2)];
  question = `What time is it ${durationMinutes} minutes after ${formattedStartHour}:${formattedStartMinute}?`;

  // Calculate the end time
  let totalMinutesAfter = startMinute + durationMinutes;
  let endHour = (startHour + Math.floor(totalMinutesAfter / 60)) % 24;
  let endMinute = totalMinutesAfter % 60;

  // Adjust the formatting right after calculation
  let formattedEndHour = endHour < 10 ? `0${endHour}` : `${endHour}`;
  let formattedEndMinute = endMinute < 10 ? `0${endMinute}` : `${endMinute}`;
  correctAnswer = `${formattedEndHour}:${formattedEndMinute}`;


    // Generate wrong answers
    // Wrong Answer 1: Add an extra 10 minutes to the duration
    wa1Hour = (startHour + Math.floor((startMinute + durationMinutes + 10) / 60)) % 24;
    wa1Minute = (startMinute + durationMinutes + 10) % 60;
    wrongAnswers.push(`${wa1Hour < 10 ? '0' + wa1Hour : wa1Hour}:${wa1Minute < 10 ? '0' + wa1Minute : wa1Minute}`);

    // Wrong Answer 2: Subtract 10 minutes from the duration
    wa2Hour = (startHour + Math.floor((startMinute + durationMinutes - 10 + 60) / 60)) % 24; // Adding 60 to ensure the minute calculation stays positive
    wa2Minute = (startMinute + durationMinutes - 10 + 60) % 60;
    wrongAnswers.push(`${wa2Hour < 10 ? '0' + wa2Hour : wa2Hour}:${wa2Minute < 10 ? '0' + wa2Minute : wa2Minute}`);

    // To add a third wrong answer that's distinct, consider a different type of error:
    // Wrong Answer 3: Incorrectly not adjusting for the hour overflow
    let wa3Minutes = durationMinutes + 15; // Adding more time but incorrectly calculating overflow
    wa3Hour = startHour; // Pretend as if the added minutes don't change the hour
    wa3Minute = (startMinute + wa3Minutes) % 60;
    // Make sure this calculation does not accidentally produce a correct answer
    if (wa3Hour === endHour && wa3Minute === endMinute) {
        wa3Minute = (wa3Minute + 5) % 60; // Adjust to ensure it's wrong
    }
    wrongAnswers.push(`${wa3Hour < 10 ? '0' + wa3Hour : wa3Hour}:${wa3Minute < 10 ? '0' + wa3Minute : wa3Minute}`);

    break;

    case 4:
      // Pattern Type 4: Complex calculations
      startHour = getRandomNumber(0, 23);
      startMinute = getRandomNumber(0, 59);
      // Ensure these are initialized immediately after startHour/startMinute are set
      formattedStartHour = startHour < 10 ? `0${startHour}` : `${startHour}`;
      formattedStartMinute = startMinute < 10 ? `0${startMinute}` : `${startMinute}`;
      let addHours = getRandomNumber(1, 3);
      let addMinutes = getRandomNumber(1, 59);
      question = `What time is it ${addHours} hours and ${addMinutes} minutes after ${formattedStartHour}:${formattedStartMinute}?`;

      let totalEndMinutes = startMinute + addMinutes;
      let endHour4 = (startHour + addHours + Math.floor(totalEndMinutes / 60)) % 24;
      let endMinute4 = totalEndMinutes % 60;

      // Format for display
      let formattedEndHour4 = endHour4 < 10 ? `0${endHour4}` : `${endHour4}`;
      let formattedEndMinute4 = endMinute4 < 10 ? `0${endMinute4}` : `${endMinute4}`;
      correctAnswer = `${formattedEndHour4}:${formattedEndMinute4}`;

      // Generate wrong answers by introducing common mistakes
      // Wrong Answer 1: Adding an extra hour but correctly calculating minutes
      wa1Hour = (startHour + addHours + 1) % 24; // Incorrectly add an extra hour
      wrongAnswers.push(`${wa1Hour < 10 ? '0' + wa1Hour : wa1Hour}:${formattedEndMinute4}`);

      // Wrong Answer 2: Correctly adding hours but subtracting 10 minutes instead of adding
      wa2Minute = (startMinute + addMinutes - 20 + 60) % 60; // Adjust to ensure it's a wrong answer, making sure minutes are subtracted
      wa2Hour = (startHour + addHours + Math.floor((startMinute + addMinutes - 20) / 60)) % 24;
      wrongAnswers.push(`${wa2Hour < 10 ? '0' + wa2Hour : wa2Hour}:${wa2Minute < 10 ? '0' + wa2Minute : wa2Minute}`);

      // Wrong Answer 3: Subtracting hours and minutes instead of adding
      let wa3HoursSubtract = Math.max(0, addHours - 2); // Ensure it doesn't go negative
      wa3Hour = (startHour - wa3HoursSubtract + 24) % 24; // Ensure it wraps correctly around the clock
      wa3Minute = (startMinute - addMinutes + 60) % 60; // Subtract minutes, ensure positive
      wrongAnswers.push(`${wa3Hour < 10 ? '0' + wa3Hour : wa3Hour}:${wa3Minute < 10 ? '0' + wa3Minute : wa3Minute}`);

      break;

      case 5:
  // Generate a random walk duration in multiples of 5, from 5 to 150 minutes
  let walkDuration = getRandomNumber(1, 30) * 5; // 1*5=5 to 30*5=150 minutes

  // Generate a random target arrival minute in 5-minute increments from 00 to 55
  let targetArrivalMinute = getRandomNumber(0, 11) * 5;

  // Randomize the target arrival hour to start from 7 AM to 23 PM (to ensure logic)
  let targetArrivalHour = getRandomNumber(7, 23);

  // Calculate the departure time
  let minutesFromMidnightTarget = targetArrivalHour * 60 + targetArrivalMinute; // Convert target arrival time to minutes from midnight
  let departureTimeInMinutes = minutesFromMidnightTarget - walkDuration; // Subtract walk duration to find departure time in minutes from midnight

  let departureHour = Math.floor(departureTimeInMinutes / 60);
  let departureMinute = departureTimeInMinutes % 60;

  // Format for display
  let formattedDepartureHour = departureHour < 10 ? `0${departureHour}` : `${departureHour}`;
  let formattedDepartureMinute = departureMinute < 10 ? `0${departureMinute}` : `${departureMinute}`;
  question = `If it takes Bob ${walkDuration} mins to walk to the shops, what time must he leave to get there for ${targetArrivalHour < 10 ? '0' + targetArrivalHour : targetArrivalHour}:${targetArrivalMinute < 10 ? '0' + targetArrivalMinute : targetArrivalMinute}?`;
  correctAnswer = `${formattedDepartureHour}:${formattedDepartureMinute}`;

  // Generate wrong answers
  // Adjustments here are similar but need to ensure they are still plausible given the variable walk duration and arrival time

  // Wrong Answer 1: Incorrectly assumes Bob leaves an hour earlier
  wrongAnswers.push(`${(departureHour - 1 + 24) % 24 < 10 ? '0' + ((departureHour - 1 + 24) % 24) : ((departureHour - 1 + 24) % 24)}:${formattedDepartureMinute}`);

  // Wrong Answer 2: Incorrectly adjusts minutes, adding a random increment
  let addedMinutesForError = (departureMinute + getRandomNumber(1,3) * 5) % 60; // Add 5, 10, or 15 minutes to departure minute
  wrongAnswers.push(`${formattedDepartureHour}:${addedMinutesForError < 10 ? '0' + addedMinutesForError : addedMinutesForError}`);

  // Wrong Answer 3: Incorrectly adds the walk duration to the departure time
  let departureTimeAddedError = departureTimeInMinutes + walkDuration; // Add walk duration again
  let wrongHourAdded = Math.floor(departureTimeAddedError / 60) % 24;
  let wrongMinuteAdded = departureTimeAddedError % 60;
  wrongAnswers.push(`${wrongHourAdded < 10 ? '0' + wrongHourAdded : wrongHourAdded}:${wrongMinuteAdded < 10 ? '0' + wrongMinuteAdded : wrongMinuteAdded}`);

  break;


  }

  // Logic for generating wrong answers based on patternType
  // For simplicity in this example, it's not fully detailed
  // Ensure wrongAnswers are plausible but incorrect, considering the patternType

  const buttonOptions = shuffleArray([correctAnswer, ...wrongAnswers]);

  return {
    question,
    answer: correctAnswer,
    buttonOptions
  };
};
