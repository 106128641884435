import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const Profile = () => {
    const { user, isAuthenticated } = useAuth0();
    
    return (
        isAuthenticated && (
        <article>

        </article>

        )
    )
}

export default Profile