import { shuffleArray } from "./utility";

const metaphors = {
  'He is an early bird': 'likes to wake up or start early',
  'I could eat a horse': 'very hungry',
  'It all went pear shaped': 'things went wrong',
  'It\'s no skin off my nose': "it doesn’t affect me negatively",
  'She is an open book': 'easy to understand or has nothing to hide',
  'He wears his heart on his sleeve': 'shows his feelings readily',
  "Time flies when you’re having fun": 'time passes quickly when enjoying yourself',
  'He has a heart of gold': 'very kind and good',
  "Don’t put all your eggs in one basket": "don’t risk everything at once",
  'Actions speak louder than words': 'what you do shows your true intentions, more than what you say',
  'The ball is in your court': 'it is up to you to take the next step',
  'Break the ice': 'make people feel more comfortable',
  'Bite the bullet': 'face a painful or unpleasant situation bravely',
  'Cut corners': 'do something the cheapest or easiest way',
  'Hit the nail on the head': 'say exactly the right thing',
  'Let the cat out of the bag': 'reveal a secret',
  'Once in a blue moon': 'very rarely',
  'Piece of cake': 'very easy',
  'A breath of fresh air': 'something new and refreshing',
  'A dime a dozen': 'very common',
  'A picture is worth a thousand words': 'an image can tell a story better than words',
  'A tough nut to crack': "a problem that's hard to solve",
  'At the drop of a hat': 'without any hesitation',
  'Barking up the wrong tree': 'looking in the wrong place',
  'Between a rock and a hard place': 'in a difficult situation',
  'Burn the midnight oil': 'work late into the night',
  'Caught between two stools': 'when someone finds it difficult to choose between two alternatives',
  'Costs an arm and a leg': 'very expensive',
  'Time is a thief': 'takes moments away',
  'The world is a stage': 'everyone plays a part',
  'His heart is a rock': 'very unfeeling',
  'The storm is a beast': 'wild and destructive',
  'Her eyes are stars': 'shining and bright',
  'The snow is a white blanket': 'covers everything smoothly',
  'Life is a roller coaster': 'full of ups and downs',
  'He was knocked for six': 'very surprised or upset',
  'The sun is a golden ball': 'bright and yellow',
  'The moon is a nightlight': 'lights up the night',
  'Cry over spilled milk': 'complain about things from the past',
  "Don’t beat around the bush": 'speak directly and clearly',
  'Every cloud has a silver lining': 'there is something good in every bad situation',
  'Get a taste of your own medicine': 'treated the same unpleasant way you have treated others',
  'Give someone the cold shoulder': 'ignore someone intentionally',
  'Go down in flames': 'fail spectacularly',
  'Hit the books': 'study hard',
  'In the same boat': 'in the same situation',
  'Kill two birds with one stone': 'solve two problems with one action',
  'Make a long story short': 'tell something briefly',
  'Miss the boat': 'miss the chance',
  'Not a spark of decency': 'no manners or respect',
  'Out of the frying pan and into the fire': 'going from a bad to a worse situation',
  "Pull someone’s leg": 'joke or tease someone',
  'See eye to eye': 'agree on something',
  'Sit on the fence': 'not able to make a decision',
  'Speak of the devil': 'the person we were just talking about appears',
  "Steal someone’s thunder": 'take credit for what someone else did',
  'Take it with a grain of salt': 'don\’t take it too seriously',
  'The ball is in your court': 'it\’s your decision or responsibility now',
  'The best of both worlds': 'all the advantages',
  'The bigger they are, the harder they fall': 'the more significant someone is, the more substantial the downfall',
  'The last straw': 'the final problem in a series of problems',
  'Tie the knot': 'get married',
  'Time flies': 'time passes very quickly',
  'Tip of the iceberg': 'a small part of a large problem',
  'Under the weather': 'feeling sick or unwell',
  'Walk on eggshells': 'be very careful not to offend someone or do anything wrong',
  'Water under the bridge': 'problems that someone has had in the past that they do not worry about anymore',
  'When pigs fly': 'something that will never happen',
  'You can\’t judge a book by its cover': 'can\’t judge someone or something based solely on appearance',
  'A heart of stone': 'very unfeeling, cruel',
  'A loaf of bread is better than the song of many birds': 'practical benefits are more valuable than empty beauty',
  'Bite the hand that feeds you': 'act badly towards the person who is helping or has helped you',
  'Break the ice': 'to start a conversation or create a more friendly atmosphere',
  'Bury the hatchet': 'make peace; end a quarrel, settle one\’s differences',
  'Butterflies in my stomach': 'feeling nervous',
  'Couch potato': 'a lazy person',
  'Cut from the same cloth': 'similar in a noticeable way',
  'Dark horse': 'a person who is little known but could unexpectedly succeed or excel',
  'Draw the line': 'set a limit',
  'Drop in the ocean': 'a very small part of something big or whole',
  'Elephant in the room': 'a very large issue that everyone is aware of, but nobody wants to talk about',
  'Fit as a fiddle': 'in good health',
  'Hold your horses': 'wait a moment',
  'Last but not least': 'the last person mentioned is no less important than those before him/her',
  'Nest egg': 'savings set aside for future use',
  'Put all your eggs in one basket': 'rely on a single plan or idea',
  'See the light': 'realize the truth',
  'Spill the beans': 'reveal secret information unintentionally or indiscreetly',
  'The world is your oyster': 'you have the ability to achieve anything',
  'Bite off more than you can chew': 'take on a task that is too big',
  'Blow off steam': 'release pent-up emotions, usually by talking',
  'Burning the midnight oil': 'working late into the night',
  'Catch someone\’s eye': 'attract someone\’s attention',
  'Chip on your shoulder': 'being upset for something that happened in the past',
  'Cold feet': 'nervous just before a big event',
  'Cut to the chase': 'get to the point without wasting time',
  'Down to earth': 'practical, realistic',
  'Feeling blue': 'feeling sad',
  'Head in the clouds': 'having unrealistic dreams',
  'Hit the hay': 'go to bed or go to sleep',
  'In over your head': 'involved in a difficult situation that you cannot get out of',
  'Jump the gun': 'do something too early, especially without thinking carefully about it',
  'Let sleeping dogs lie': 'avoid interfering in a situation that is currently causing no problems',
  'On thin ice': 'in a risky position, in danger',
  'Out of the blue': 'unexpected',
  'Playing with fire': 'doing something dangerous or risky',
  'Throw in the towel': 'give up on something'


};


export const generateMetaphorQuestion = () => {
  // Step 1: Randomly select a metaphor and its explanation
  const metaphorKeys = Object.keys(metaphors);
  const randomIndex = Math.floor(Math.random() * metaphorKeys.length);
  const selectedMetaphor = metaphorKeys[randomIndex];
  const correctExplanation = metaphors[selectedMetaphor];

  // Step 2: Generate the question
  const question = `What does the metaphor "${selectedMetaphor}" mean?`;

  // Step 3: Randomly select two other explanations for incorrect options
  let incorrectOptions = [];
  while (incorrectOptions.length < 2) {
    const randomIncorrectIndex = Math.floor(Math.random() * metaphorKeys.length);
    if (randomIncorrectIndex !== randomIndex) {
      const incorrectExplanation = metaphors[metaphorKeys[randomIncorrectIndex]];
      if (!incorrectOptions.includes(incorrectExplanation)) {
        incorrectOptions.push(incorrectExplanation);
      }
    }
  }

  // Step 4: Shuffle the options
  const buttonOptions = shuffleArray([correctExplanation, ...incorrectOptions]);

  return {
    question,
    answer: correctExplanation,
    buttonOptions
  };
};