import background from "../assets/backgrounds/IntroPage.jpg";
import fyn from '../assets/spritesheets/fynSpriteSheet.png';

import loginButton from "../assets/buttons/LoginButton.png";
import nextButton from "../assets/buttons/NavButton.png";
import musicButton from "../assets/buttons/MusicButton.png";
import backButton from "../assets/buttons/BackButton.png";
import navButton from "../assets/buttons/NavButton.png";
import sevenPlusButton from "../assets/buttons/7PlusButton.png";
import eightPlusButton from "../assets/buttons/8PlusButton.png";
import elevenPlusButton from "../assets/buttons/11PlusButton.png";
import reportCardButton from "../assets/buttons/ReportCardButton.png";
import pixelBackground from  "../assets/backgrounds/MainPixel.jpg";
import { loadAllAssets } from './sharedAssets';

class PreloadScene extends Phaser.Scene {
    constructor() {
        super('PreloadScene');
    }

    preload() {
        // Load the small background image first
        this.load.image('pixelBackground', pixelBackground);
        this.load.once('complete', () => {
            // Display the small background image
            let bg = this.add.image(0, 0, 'pixelBackground').setOrigin(0);

            // Scale the image to fill the screen while maintaining aspect ratio
            let scaleX = this.sys.game.config.width / bg.width;
            let scaleY = this.sys.game.config.height / bg.height;
            let scale = Math.max(scaleX, scaleY);
            bg.setScale(scale).setScrollFactor(0);

            // Display loading text
            this.loadingText = this.add.text(this.sys.game.config.width / 2, this.sys.game.config.height / 2, 'Loading...', {
                fontSize: '32px',
                fill: '#f78737'
            }).setOrigin(0.5);

            // Load all other assets
            this.loadAssets();
        });
        this.load.start();
    }

    loadAssets() {
        loadAllAssets(this);
        // Once loading is complete, start the WelcomeScene
        this.load.on('complete', () => {
            this.scene.start('WelcomeScene');
        });

        this.load.start();
    }
}

export default PreloadScene;
