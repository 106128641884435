import React, { useEffect, useState, useRef } from 'react';
import '../App.css';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import useUserData from '../hooks/useUserData';

import { ReactComponent as MySVG } from '../images/fground.svg';
import { ReactComponent as CloudSVG } from '../images/cloud.svg';
import { ReactComponent as PageBoxSVG } from '../images/pagebox.svg';
import { ReactComponent as PointsBoxSVG } from '../images/pointsbox.svg';
import { ReactComponent as LevelBoxSVG } from '../images/levelbox.svg';
import { ReactComponent as QuestionBoxSVG } from '../images/questionbox.svg';
import { ReactComponent as GroundSVG } from '../images/assets/grass.svg';

import FynSVG from '../components/fyn';
import anime from 'animejs/lib/anime.es.js';
import { greetings } from '../components/fyn';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';

const ProfilePage = () => {
  const { isAuthenticated, user } = useAuth0();
  const { isStudent, isParent, isAdmin, points, userData, fetchUserData, fetchGameData, updatePoints, studentName, fetchWeeksGames } = useUserData();
  const [addedPoints, setAddedPoints] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const pageName = "Profile";
  const [weeksGames, setWeeksGames] = useState([]);
  const fynSvgRef = useRef(null);


  useEffect(() => {
    if (isAuthenticated && user) {
      fetchUserData(user.sub, user.nickname, user.email);
      fetchWeeksGames(user.sub).then(games => setWeeksGames(games));
    }
  }, []);

  useEffect(() => {
    document.title = "Fynschool Profile"
  }, []);

  useEffect(() => {
    // ... other useEffect logic

    const animateFyn = () => {
      anime({
        targets: '#fyn',
        keyframes: [
          { translateY: 0, scaleX: 1, scaleY: 1, duration: 150 },     // Starting position
          { translateY: 5, scaleX: 1.1, scaleY: 0.9, duration: 300 }, // Anticipation (longer)
          { translateY: -50, scaleX: 0.8, scaleY: 1.2, duration: 150 }, // Jump up (stretch)
          { translateY: -50, scaleX: 1, scaleY: 1, duration: 220 },   // Apex of jump
          { translateY: 10, scaleX: 1.1, scaleY: 0.9, duration: 100 }, // Landing (squash)
          // { translateY: -5, scaleX: 1, scaleY: 1, duration: 150 }, // Landing (squash)
          // { translateY: 3, scaleX: 1.1, scaleY: 0.9, duration: 150 }, // Landing (squash)
          { translateY: 0, scaleX: 1, scaleY: 1, duration: 150 }      // Recovery to normal
        ],
        easing: 'easeInOutCubic'
      });
    };


    const intervalId = setInterval(animateFyn, 15000); // Trigger animation every 2 seconds

    // Cleanup function to clear the interval
    return () => {
      clearInterval(intervalId);
    };

  }, []);


  useEffect(() => {
    // Animation logic
    const clouds = document.querySelectorAll('.cloud');
    clouds.forEach((cloud) => {
      animateCloud(cloud);
    });
  }, []);



  const animateCloud = (cloud) => {
    const startX = Math.random() * window.innerWidth; // Random starting X position
    const startY = Math.random() * (window.innerHeight * 0.1); // Random starting Y position in the top 10%
    const duration = 100000 + Math.random() * 5000; // Random duration between 30s and 35s
    const scale = 0.9 + Math.random() * 0.2; // Random scale between 0.9 and 1.1

    // Set initial opacity to 0
    cloud.style.opacity = 0;

    anime({
      targets: cloud,
      translateX: [`${startX}px`, `${window.innerWidth}px`],
      translateY: [`${startY}px`, `${startY}px`], // Keep the Y position constant
      scale: [scale, scale], // Keep the scale constant
      easing: 'linear',
      duration: duration,
      opacity: [
        { value: 1, duration: 200 }, // Fade in duration
        { value: 0, duration: 200, delay: duration - 400 }, // Fade out duration with delay
      ],
      complete: () => {
        // Reset cloud position when animation completes
        cloud.style.transform = `translateX(-200px)`;
        animateCloud(cloud); // Start animation again
      },
    });
  };

  // useEffect(() => {
  //   if (isAuthenticated && user) {
  //     const roles = user['https://fynschool.com/roles'];
  //     if (roles) {
  //       if (roles.includes('7plus')) {
  //         setRoleMessage(['Hello, 7+ student!']);
  //       } else if (roles.includes('student')) {
  //         setRoleMessage(['You are set to start your learning adventure!']);
  //       } else if (roles.includes('admin')) {
  //         setRoleMessage(['Hello, admin!']);
  //       } else {
  //         setRoleMessage([
  //           "Welcome to Fynschool, your child's new learning adventure! Uniquely designed to captivate the curiosity of children between the ages of 5 and 8. We wholeheartedly believe that these early years are the perfect time to lay a robust educational foundation and nurture productive habits that will serve our children for a lifetime.",

  //           "We understand that at this lively age, keeping your child engaged and focused can feel like a mammoth task. Our innovative study style is to transform learning into an exciting game, making knowledge acquisition not only effortless but also enjoyable. This change of perspective not only increases focus and brings children back for more, they also enhance retention with the variety of challenges we present.",

  //           "Our primary mission is to equip your child with essential core skills that are pivotal for future academic success. We focus on: ",
  //           <li><strong>Entrance exams:</strong> These skills are not only crucial for the exams but also form the building blocks for advanced studies and lifelong learning.</li>,
  //           <li>Maths Meadow: Dive into fun with Mathematics.</li>,
  //           <li>Spelling, dictation, and story writing skills: Practice makes perfect.</li>,
  //           <li>Synonym Sea: Expand your child's vocabulary.</li>,
  //           <li>Paper Pyramid: Download and print worksheet templates.</li>,

  //           "Join us on this exciting journey as we turn the challenge of education into an adventure of discovery, making learning a joyous experience that your children will cherish and look forward to, day after day. With reward systems, reminders for sections that may not have been visited for a while, tracking of progress viewable in the dashboard and emailed to parents, and practice exams coming soon, we have designed a safe space for your children to explore, learn, and acquire powerful techniques they will use for years to come.",
  //           <p>If you have any questions, feel free to email us at <a href='mailto:team@fynschool.com'>team@fynschool.com</a>.</p>,
  //           <p>"Start your child's learning adventure today!"</p>
  //         ]);
  //       }
  //     }
  //   } else {
  //     setRoleMessage([
  //       "Welcome to Fynschool, your child's new learning adventure! Uniquely designed to captivate the curiosity of children between the ages of 5 and 8. We wholeheartedly believe that these early years are the perfect time to lay a robust educational foundation and nurture productive habits that will serve our children for a lifetime.",

  //       "We understand that at this lively age, keeping your child engaged and focused can feel like a mammoth task. Our innovative study style is to transform learning into an exciting game, making knowledge acquisition not only effortless but also enjoyable. This change of perspective not only increases focus and brings children back for more, they also enhance retention with the variety of challenges we present.",

  //       "Our primary mission is to equip your child with essential core skills that are pivotal for future academic success. We focus on: ",
  //       <li><strong>Entrance exams:</strong> These skills are not only crucial for the exams but also form the building blocks for advanced studies and lifelong learning.</li>,
  //       <li>Maths Meadow: Dive into fun with Mathematics.</li>,
  //       <li>Spelling, dictation, and story writing skills: Practice makes perfect.</li>,
  //       <li>Synonym Sea: Expand your child's vocabulary.</li>,
  //       <li>Paper Pyramid: Download and print worksheet templates.</li>,

  //       "Join us on this exciting journey as we turn the challenge of education into an adventure of discovery, making learning a joyous experience that your children will cherish and look forward to, day after day. With reward systems, reminders for sections that may not have been visited for a while, tracking of progress viewable in the dashboard and emailed to parents, and practice exams coming soon, we have designed a safe space for your children to explore, learn, and acquire powerful techniques they will use for years to come.",
  //       <p>If you have any questions, feel free to email us at <a href='mailto:team@fynschool.com'>team@fynschool.com</a>.</p>,
  //       <p>"Start your child's learning adventure today!"</p>
  //     ]);
  //   }
  // }, [isAuthenticated, user]);

  useEffect(() => {
    if (fynSvgRef.current) {
      anime({
        targets: fynSvgRef.current,
        // Define your animation properties here
        translateX: 250, // Example property
        duration: 1000,
        easing: 'easeOutExpo',
      });
    }
  }, []);

  const findMostCommonGameName = (games) => {
    const count = {};

    games.forEach((game) => {
      count[game.gameName] = (count[game.gameName] || 0) + 1;
    });

    return Object.keys(count).reduce((a, b) => (count[a] > count[b] ? a : b));
  };


  return (
    <div className="App">
      <div className="App-background">
        <div className="sky">
        </div>

        <div className="App-background">
          <MySVG className="svg-background" />
        </div>

        <div className="App-mid">
          <FynSVG id="fyn" ref={fynSvgRef} className="svg-fyn" messages={["Hello", "Are you okay?", "What's up?"]} defaultResponses={["I'm fine", "Help me Fyn"]} onResponse={(response) => {
              if (response === "I'm fine") {
                  return "You got it!";
              } else if (response === "Help me Fyn") {
                  // show the overlay
                  setOverlayVisible(true);
                  return "I got you!";
              }
          }} />
          <div className='pagebox'>
            <PageBoxSVG />
            <p className='page'>{pageName}</p>
          </div>
          <div className='pointsbox'>
            <PointsBoxSVG />
            <p className='points'> {points.toString()}</p>
          </div>
        </div>
      </div>
      <div className="App-content">
        {studentName && <p className='mainText'>Hi {studentName}!</p>}
        <div className="games-info">
        <p>Number of Games Played This Week: {weeksGames.length}</p>
        <p>Most Common Game: {weeksGames.length > 0 ? findMostCommonGameName(weeksGames) : 'N/A'}</p>
      </div>
      <div className="games-grid">
        {weeksGames.length > 0 ? (
          weeksGames
            .slice() // Create a shallow copy of the array to avoid mutating the original array
            .reverse() // Reverse the order of the array
            .map((game, index) => (
              <div key={index} className="questionMaths">
                <h3>{game.name}</h3>
                <p>Game: {game.gameName}</p>
                <p>Points: {game.points}</p>
                {/* Conditional rendering of totalScore */}
                {game.totalScore !== undefined && <p>{game.totalScore}/10</p>}
                <p>Date: {new Date(game.dateTime).toLocaleDateString()}</p>
              </div>
            ))
          ) : (
            <p>No games available for this week.</p>
          )}
      </div>
       </div>

    </div>

  );
};

export default ProfilePage;
