import { getRandomNumber } from "./utility";

// Helper function to calculate the greatest common divisor (GCD) of two numbers
const gcd = (a, b) => {
  if (!b) {
    return a;
  }
  return gcd(b, a % b);
};

// Helper function to simplify the fraction before displaying
const simplifyFraction = (numerator, denominator) => {
  const gcdValue = gcd(numerator, denominator);
  return `${numerator/gcdValue}/${denominator/gcdValue}`;
};

export const generateFractionMathQuestion = (level) => {
  let maxNumerator, maxDenominator, maxWholeNumber;

  // Define the range of numbers and complexity for each level
  if (level <= 3) {
    maxNumerator = 1;
    maxDenominator = 4;
    maxWholeNumber = 10;
  } else if (level <= 6) {
    maxNumerator = 3;
    maxDenominator = 4;
    maxWholeNumber = 20;
  } else if (level <= 9) {
    maxNumerator = 7;
    maxDenominator = 7;
    maxWholeNumber = 50;
  } else if (level <= 12) {
    maxNumerator = 10;
    maxDenominator = 10;
    maxWholeNumber = 100;
  } else if (level <= 15) {
    maxNumerator = 10;
    maxDenominator = 10;
    maxWholeNumber = 200;
  } else if (level <= 18) {
    maxNumerator = 12;
    maxDenominator = 12;
    maxWholeNumber = 500;
  } else if (level <= 21) {
    maxNumerator = 15;
    maxDenominator = 15;
    maxWholeNumber = 1000;
  } else if (level <= 24) {
    maxNumerator = 20;
    maxDenominator = 20;
    maxWholeNumber = 2000;
  } else if (level <= 27) {
    maxNumerator = 25;
    maxDenominator = 25;
    maxWholeNumber = 5000;
  } else {
    maxNumerator = 30;
    maxDenominator = 30;
    maxWholeNumber = 10000;  // Up to 10000 for the last levels
  }

  let num1 = getRandomNumber(1, maxNumerator); // numerator
  let num2 = getRandomNumber(2, maxDenominator); // denominator
  let num3 = num2 * getRandomNumber(1, Math.floor(maxWholeNumber / num2)); // whole number to multiply is a multiple of the denominator

  let question = `What is the result of '${num1}/${num2} of ${num3}'?`;
  let answerNumerator = num1 * num3;
  let answerDenominator = num2;

  // Simplify the answer
  let answerGCD = gcd(answerNumerator, answerDenominator);
  answerNumerator /= answerGCD;
  answerDenominator /= answerGCD;

  let answer = (answerDenominator === 1) ? `${answerNumerator}` : simplifyFraction(answerNumerator, answerDenominator);

  // Generate wrong options, ensuring they are unique and different from the correct answer
  let wrongOption1, wrongOption2;
  do {
    wrongOption1 = `${num2 * getRandomNumber(1, 3)}`; // Random multiple of the denominator
  } while (wrongOption1 === answer);

  do {
    wrongOption2 = `${num1 * getRandomNumber(1, 5)}`; // Random multiple of the numerator
  } while (wrongOption2 === answer || wrongOption2 === wrongOption1);

  let buttonOptions = [answer, wrongOption1, wrongOption2];

  // Randomly shuffle buttonOptions
  buttonOptions = buttonOptions.sort(() => Math.random() - 0.5);

  return {
    question,
    answer,
    buttonOptions
  };
};
