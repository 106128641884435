import { getRandomNumber, shuffleArray, getRandomDecimal } from "./utility";

export const generateConversionQuestion = (level) => {
  let question, correctAnswer, wrongAnswer1, wrongAnswer2;
  let patternType = 1; // Default pattern type

  // Adjust complexity based on level
  if (level <= 4) {
    patternType = getRandomNumber(1, 4); // Basic conversions
  } else if (level <= 8) {
    patternType = getRandomNumber(1, 6); // Worded questions with simple arithmetic
  } else if (level <= 12) {
    patternType = getRandomNumber(1, 6); // Comparison questions
  } else if (level <= 16) {
    patternType = getRandomNumber(1, 6); // Fractions in conversions
  } else if (level <= 20) {
    patternType = getRandomNumber(1, 6); // Complex worded problems
  } else if (level <= 24) {
    patternType = getRandomNumber(1, 6); // Decimals in conversions
  } else if (level <= 28) {
    patternType = getRandomNumber(1, 6); // Complex comparison questions
  } else {
    patternType = getRandomNumber(1, 6); // Challenging worded problems
  }

  // Implement different types of conversion questions based on patternType
  switch (patternType) {
    case 1:
      const meters = getRandomNumber(100, 1000);
      question = `How many meters are in ${meters / 1000} kilometers?`;
      correctAnswer = meters;
      wrongAnswer1 = correctAnswer + getRandomNumber(50, 150);
      wrongAnswer2 = correctAnswer - getRandomNumber(50, 150);
      break;
    case 2: // Weight conversion (grams to kilograms)
      const grams = getRandomNumber(500, 5000);
      question = `How many grams are in ${grams / 1000} kilograms?`;
      correctAnswer = grams;
      wrongAnswer1 = correctAnswer + getRandomNumber(100, 300);
      wrongAnswer2 = correctAnswer - getRandomNumber(100, 300);
      break;
    case 3: // Volume conversion (milliliters to liters)
      const milliliters = getRandomNumber(100, 2000);
      question = `How many milliliters are in ${milliliters / 1000} liters?`;
      correctAnswer = milliliters;
      wrongAnswer1 = correctAnswer + getRandomNumber(50, 150);
      wrongAnswer2 = correctAnswer - getRandomNumber(50, 150);
      break;
    case 4: // Currency conversion (pence to pounds)
      const pence = getRandomNumber(100, 500);
      question = `How many pence are in £${pence / 100}?`;
      correctAnswer = pence;
      wrongAnswer1 = correctAnswer + getRandomNumber(5, 20);
      wrongAnswer2 = correctAnswer - getRandomNumber(5, 20);
      break;
      case 5:
        // Example: Adding two lengths in cm before converting to meters
        const length1_cm = getRandomNumber(10, 100); // length in cm
        const length2_cm = getRandomNumber(10, 100); // another length in cm
        const totalLength_cm = length1_cm + length2_cm;
        correctAnswer = (totalLength_cm / 100).toFixed(2); // conversion to meters

        question = `Two ropes measure ${length1_cm} cm and ${length2_cm} cm respectively. If you tie them together, how long is the combined rope in meters?`;

        wrongAnswer1 = ((totalLength_cm + getRandomNumber(5, 15)) / 100).toFixed(2);
        wrongAnswer2 = ((totalLength_cm - getRandomNumber(5, 15)) / 100).toFixed(2);
        break;

        case 6:
          // Example: Converting two weights to kg and then adding them
          const weight1_g = getRandomNumber(200, 5000); // weight in grams
          const weight2_g = getRandomNumber(200, 5000); // another weight in grams
          const totalWeight_kg = (weight1_g + weight2_g) / 1000; // conversion to kg and sum

          question = `A bag of flour weighs ${weight1_g} grams and a bag of sugar weighs ${weight2_g} grams. What is their total weight in kilograms?`;

          correctAnswer = totalWeight_kg.toFixed(2); // conversion to kg

          wrongAnswer1 = ((weight1_g + getRandomNumber(100, 500)) / 1000).toFixed(2);
          wrongAnswer2 = ((weight1_g - getRandomNumber(100, 500)) / 1000).toFixed(2);
          break;
    // ... add other cases for different patterns
    case 9:
      // Conversion involving fractions
      // ... implement logic for conversion questions involving fractions
      break;
    case 12:
      // Conversion involving decimals
      // ... implement logic for conversion questions involving decimals
      break;
    case 15:
      // Challenging worded problem involving a mix of operations and units
      // ... implement logic for challenging worded problems
      break;
    // ... add other cases as per the defined types
    default:
      break;
  }

  // Ensure that the wrong answers are unique and not equal to the correct answer
  // ... add logic to check and regenerate wrong answers if necessary

  return {
    question,
    answer: correctAnswer.toString(),
    buttonOptions: shuffleArray([correctAnswer.toString(), wrongAnswer1.toString(), wrongAnswer2.toString()]),
  };
};

const ConversionQuestion = ({ handleAnswerClick }) => {
  const { question, buttonOptions } = generateConversionQuestion();

  return (
    <div className="conversion-question-container">
      <p>{question}</p>
      <div>
        {buttonOptions.map((option, index) => (
          <button key={index} onClick={() => handleAnswerClick(option)}>
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ConversionQuestion;
