import { shuffleArray } from "./utility";

const collectives = [
    { group: 'lions', collective: 'pride' },
    { group: 'wolves', collective: 'pack' },
    { group: 'crows', collective: 'murder' },
    { group: 'fish', collective: 'school' },
    { group: 'sheep', collective: 'flock' },
    { group: 'bees', collective: 'swarm' },
    { group: 'soldiers', collective: 'platoon' },
    { group: 'ships', collective: 'fleet' },
    { group: 'cars', collective: 'convoy' },
    { group: 'motorcycles', collective: 'gang' },
    { group: 'cattle', collective: 'herd' },
    { group: 'elephants', collective: 'parade' },
    { group: 'geese', collective: 'gaggle' },
    { group: 'stars', collective: 'galaxy' },
    { group: 'islands', collective: 'archipelago' },
    { group: 'mountains', collective: 'range' },
    { group: 'trees', collective: 'grove' },
    { group: 'flowers', collective: 'bouquet' },
    { group: 'judges', collective: 'panel' },
    { group: 'dancers', collective: 'ensemble' },
    { group: 'musicians', collective: 'orchestra' },
    { group: 'puppies', collective: 'litter' },
    { group: 'kittens', collective: 'kindle' },
    { group: 'horses', collective: 'stable' },
    { group: 'actors', collective: 'cast' }
];


export const generateCollectiveQuestion = () => {
    // Step 1: Randomly select a group
    const randomIndex = Math.floor(Math.random() * collectives.length);
    const selectedGroup = collectives[randomIndex];

    // Step 2: Formulate the question
    const question = `What is a collection of ${selectedGroup.group} known as?`;

    // Step 3: Select three other collective nouns for incorrect options
    let incorrectOptions = [];
    while (incorrectOptions.length < 3) {
        const randomIncorrectIndex = Math.floor(Math.random() * collectives.length);
        if (randomIncorrectIndex !== randomIndex) {
            const incorrectCollective = collectives[randomIncorrectIndex].collective;
            if (!incorrectOptions.includes(incorrectCollective)) {
                incorrectOptions.push(incorrectCollective);
            }
        }
    }

    // Step 4: Shuffle the options
    const buttonOptions = shuffleArray([selectedGroup.collective, ...incorrectOptions]);

    return {
        question,
        answer: selectedGroup.collective,
        buttonOptions
    };
};
