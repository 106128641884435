import React, { useEffect, useState } from "react";
import '../App.css';
import { useAuth0 } from '@auth0/auth0-react';
import useUserData from '../hooks/useUserData';
import { ReactComponent as MySVG } from '../images/paperpyramid.svg';
import { ReactComponent as CloudSVG } from '../images/cloud.svg';
import FynSVG from '../components/fyn';
import { greetings } from '../components/fyn';
import Overlay from '../components/Overlay';
import anime from 'animejs/lib/anime.es.js';
import { addPoints } from '../components/PointsComponent';
import { startCloudAnimation, animateCloud } from '../animations/cloudAnimation';

import { ReactComponent as PageBoxSVG } from '../images/pagebox.svg';
import { ReactComponent as PointsBoxSVG } from '../images/pointsbox.svg';
import { ReactComponent as LevelBoxSVG } from '../images/levelbox.svg';
import { ReactComponent as QuestionBoxSVG } from '../images/questionbox.svg';




const Paperpyramid = () => {
  const pageName = "Paper Pyramid";
  const { isAuthenticated, user } = useAuth0();
  const { isStudent, isParent, isAdmin, points, userData, fetchUserData, updatePoints, studentName } = useUserData();

  const [isDancing, setIsDancing] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [customMessage, setCustomMessage] = useState("");

  const customMessages = [
    "Use this page to print out blank worksheets",
    "The printing works better in Chrome",
    "Welcome to Paper Pymarid!"
  ];

  useEffect(() => {
    document.title = "Fynschool Paper Palace";
    if (isAuthenticated) {
      fetchUserData(user.sub);
    }
  }, []);

  const handlePrintPdf = (pdfName, title) => {
    const pdfUrl = `${process.env.PUBLIC_URL}/pdfs/${pdfName}.pdf`;
    window.open(pdfUrl, '_blank');
  };

  return (

    <div className="App">
    <div className="App-background">
      <div className="sky">
        <MySVG className="svg-background" />
      </div>

      <Overlay
            overlayVisible={overlayVisible}
            setOverlayVisible={setOverlayVisible}

        />
    <div className="App-mid">
          <div className='pagebox'>
            <PageBoxSVG />
            <p className='page'>{pageName}</p>
          </div>
          <FynSVG
        className={`svg-fyn ${isDancing ? "dancing" : ""}`}
        messages={greetings}
        defaultResponses={["I'm fine", "Help me Fyn"]}
        onResponse={(response) => {
          if (response === "I'm fine") {
            return "You got it!";
          } else if (response === "Help me Fyn") {
            // show the overlay
            setOverlayVisible(true);
            return "I got you!";
          }
        }}
        customMessages={customMessages} // Pass the custom message here
      />

          {overlayVisible && (
              <div onClick={() => setOverlayVisible(false)} style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  zIndex: 1000, // make sure it's above everything else
              }}>


              </div>
          )}
        </div>

    </div>
    <div className="App-content button-container splash-page">
      <p>Press Ctrl+P (Cmd+P on Mac) to print after it opens.</p>
      <button aria-label="Print lined paper" onClick={() => handlePrintPdf('WritingLines', 'Lined Paper')}>Print Lined Paper</button>
      <button onClick={() => handlePrintPdf('HandwritingLines', 'Handwriting Paper')}>Print Handwriting Paper</button>
      <button onClick={() => handlePrintPdf('Division', 'Division Paper')}>Print Division Paper</button>
      <button onClick={() => handlePrintPdf('ColumnMaths', 'Column Maths Paper')}>Print Column Maths Paper</button>
      <button onClick={() => handlePrintPdf('ClockFaces', 'Clock Faces Paper')}>Print Clock Faces Paper</button>
      <button onClick={() => handlePrintPdf('Numberline', 'Numberline Paper')}>Print Numberline Paper</button>

    </div>
    </div>
  );
};

export default Paperpyramid;
