import React, { useEffect, useState } from 'react';
import { Link, Outlet } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import { useAuth0 } from "@auth0/auth0-react";
import '../Home.css';

import LogoPNG from '../images/logo.png';
import bigLogoPNG from '../images/biglogo.png';
import WebImagePNG from '../images/webimage.png';

const Curriculum = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
    const redirectUri = isLocalhost ? 'http://localhost:3000/mathsmeadow' : 'https://www.fynschool.com/mathsmeadow';


    useEffect(() => {
        document.title = "Fynschool Curriculum Page";
      }, []);

      const priceStyle = {
        textDecoration: 'line-through',
        color: '#999'
    };

    const discountStyle = {
        color: 'red',
        fontWeight: 'bold'
    };

    const popularStyle = {
        fontWeight: 'bold',
        fontSize: '1.5em'
    };

    return (
        <div>


            <div className='pageWrapper'>
                <div className='page1'>
                <div className='levels-container'>

                    <h1>UK Curriculum Levels</h1>
                    <h2>KS1</h2>
                    <p>Ages 5-7, Year group 1-2</p>
                    <h3>English Reading - Word Reading</h3>
                    <ul>
                        <li>Apply phonic knowledge and skills as the route to decode words</li>
                        <li>Respond speedily with the correct sound to graphemes (letters or groups of letters)for all 40+ phonemes, including, where applicable, alternative sounds for graphemes</li>
                        <li>Read accurately by blending sounds in unfamiliar words containing GPCs that have been taught</li>
                        <li>Read common exception words, noting unusual correspondences between spelling and sound and where these occur in the word</li>
                        <li>Read words containing taught GPCs and –s, –es, –ing, –ed, –er and –est endings</li>
                        <li>Read other words of more than one syllable that contain taught GPCs</li>
                        <li>Read words with contractions [for example, I’m, I’ll, we’ll], and understand that the apostrophe represents the omitted letter(s)</li>
                        <li>Read aloud accurately books that are consistent with their developing phonic knowledge and that do not require them to use other strategies to work out words</li>
                        <li>Re-read these books to build up their fluency and confidence in word reading.</li>

                    </ul>


            </div>
            </div>


            </div>

        </div>
    );
};

export default Curriculum;