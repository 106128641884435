import { getRandomNumber, shuffleArray } from "./utility";

const months = [
    { name: 'January', days: 31 },
    { name: 'February', days: 28 },
    { name: 'March', days: 31 },
    { name: 'April', days: 30 },
    { name: 'May', days: 31 },
    { name: 'June', days: 30 },
    { name: 'July', days: 31 },
    { name: 'August', days: 31 },
    { name: 'September', days: 30 },
    { name: 'October', days: 31 },
    { name: 'November', days: 30 },
    { name: 'December', days: 31 },
  ];

  export const generateDayInMonthQuestion = () => {
    const monthIndex = getRandomNumber(0, months.length - 1);
    const selectedMonth = months[monthIndex];

    const correctAnswer = selectedMonth.days;
    const wrongAnswers = [28, 30, 31].filter((days) => days !== correctAnswer);

    const question = `How many days are in ${selectedMonth.name}?`;

    return {
      question,
      answer: correctAnswer,
      buttonOptions: shuffleArray([correctAnswer, ...wrongAnswers]),
    };
  };
