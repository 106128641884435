import { getRandomNumber, shuffleArray } from "./utility";

export const generatePalindromeQuestion = () => {
  // Dictionary of valid palindromes and commonly mistaken words
  const palindromes = [
    "madam", "racecar", "level", "rotor", "civic",
    "radar", "refer", "deed", "pip", "pop",
    "noon", "kayak", "reviver", "redder",
    "stats", "wow", "mum", "dad", "deified",
    "rotator", "repaper", "malayalam", "gag",
    "bob", "sis", "peep", "did",
    "eve", "tot", "otto", "eye", "ana",
    "anna"
  ];
  const nonPalindromes = [
    "world", "place", "house", "drive", "space",
    "apple", "river", "stone", "light", "music",
    "chair", "table", "beach", "forest", "mount",
    "cloud", "storm", "field", "track", "board",
    "river", "plant", "clock", "grass", "smile",
    "fruit", "bread", "shoes", "water", "night",
    "train", "phone", "photo", "glass", "heart",
    "paper", "floor", "spoon", "knife", "light",
    "green", "mountain", "ocean", "window", "mirror",
    "pencil", "market", "garden", "forest", "bridge",
    "coffee", "puzzle", "winter", "summer", "spring",
    "animal", "desert", "circle", "square", "valley"
  ];

  // Select a correct answer from the palindromes
  const correctAnswer = palindromes[getRandomNumber(0, palindromes.length - 1)];

  // Select 3 wrong answers from the nonPalindromes, ensuring they are unique
  let wrongAnswers = [];
  while (wrongAnswers.length < 3) {
    const wrongAnswer = nonPalindromes[getRandomNumber(0, nonPalindromes.length - 1)];
    if (!wrongAnswers.includes(wrongAnswer)) {
      wrongAnswers.push(wrongAnswer);
    }
  }

  // Create the question string
  const question = "A palindrome can be spelled the same forwards and backwards. Which of these is a palindrome?";

  // Shuffle the answers and present them as button options
  const buttonOptions = shuffleArray([correctAnswer, ...wrongAnswers]);

  return {
    question,
    answer: correctAnswer,
    buttonOptions
  };
};
