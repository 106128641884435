import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useUserData from '../hooks/useUserData';
import '../App.css';
import { useSpeechSynthesis } from 'react-speech-kit';

import { ReactComponent as MySVG } from '../images/spellingsafari.svg';
import { ReactComponent as PageBoxSVG } from '../images/pagebox.svg';
import { ReactComponent as PointsBoxSVG } from '../images/pointsbox.svg';
import { ReactComponent as LevelBoxSVG } from '../images/levelbox.svg';
import { ReactComponent as QuestionBoxSVG } from '../images/questionbox.svg';
import SplashPage from '../components/splashpage';
import FynSVG from '../components/fyn';
import { greetings } from '../components/fyn';
import anime from 'animejs/lib/anime.es.js';
import useFetchData from '../components/fetch10Questions';
import { generateSpellingQuestion } from '../creativeQuestions/spelling';

const spellings = [
  "achieve",
  "muscle",
  "recognise",
  "desperate",
  "opportunity",
  "language",
  "temperature",
  "curiosity",
  "persuade",
  "disastrous",
  "wednesday",
  "separate",
  "february",
  "necessary",
  "definitely",
  "maintenance",
  "recommend",
  "occasion",
  "parallel",
  "restaurant",
];


const Spellingsafari = () => {
  useEffect(() => {
    document.title = "Fynschool Spelling Safari";
  }, []);
  const { isAuthenticated, user } = useAuth0();
  const { isStudent, isParent, isAdmin, points, userData, fetchUserData, fetchGameData, updatePoints, studentName, levelUp } = useUserData();
  const [addedPoints, setAddedPoints] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [isDancing, setIsDancing] = useState(false);
  const pageName = "Spelling Safari";

  const { speak, supported, cancel, pause, resume } = useSpeechSynthesis();
  const [voice, setVoice] = useState(null);
  const [speaking, setSpeaking] = useState(false);
  const [gameMode, setGameMode] = useState('');
  const dbName = 'spellingSafari'

  const [currentSpellingIndex, setCurrentSpellingIndex] = useState(0);
  const [testStarted, setTestStarted] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [selectedSpellings, setSelectedSpellings] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [userRoles, setUserRoles] = useState([]);
  const [level, setLevel] = useState(1);
  const testStartedRef = useRef(false);
  const [selectedSpellingsState, setSelectedSpellingsState] = useState({});
  const [selectedSpellingsList, setSelectedSpellingsList] = useState([]);
  const [hasFetchedMultipleChoiceData, setHasFetchedMultipleChoiceData] = useState(false);
  const [questionData, setQuestionData] = useState({});
  const [selectedQuestionType, setSelectedQuestionType] = useState('');
  const [questionCount, setQuestionCount] = useState(1);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [score, setScore] = useState(0);
  const [gameEnded, setGameEnded] = useState(false);
  const positiveMessages = ['Well done!', 'Great job!', 'Excellent work!', 'Keep it up!', 'Fantastic!'];
  const [inputNumber, setInputNumber] = useState('');
  const [lastInputNumber, setLastInputNumber] = useState('');
  const [fetchedSpellings, setFetchedSpellings] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);


  useEffect(() => {
    if (isAuthenticated) {
      fetchUserData(user.sub, user.nickname, user.email);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (user && userRoles.length > 0) {
        const gameData = await fetchGameData(user.sub, dbName);
        if (gameData && gameData.level) {
          setLevel(gameData.level);  // Update the level state variable
        }
      }
    };

    fetchData();
  }, [user, userRoles, fetchGameData]);  // Don't forget to add fetchGameData to the dependency array


  useEffect(() => {
    if (isAuthenticated && user) {
      const roles = user['https://fynschool.com/roles'];
      const message = roles ? "Welcome, ${studentName}!" : "Welcome, guest!";
      const randomMessage =
        roles ? customMessages[Math.floor(Math.random() * customMessages.length)] : "Welcome, guest!";
      setCustomMessage(randomMessage);
      setUserRoles(roles || []);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    const fetchData = async () => {
      const questionData = await generateSpellingQuestion(level, dbName);
      if (questionData !== null) {
        setQuestionData(questionData);
        setSelectedQuestionType('multipleChoice');
      }
    };

    if (gameMode === 'multipleChoice') {
      fetchData();
    }
  }, [gameMode, level, dbName]);

  useEffect(() => {
    if (questionCount >= 11) {
      endGame();
    }
  }, [questionCount]);



  const customMessages = [
    "Hello, dear guest!",
    "Nice to see you again!",
    "How's your day going?",
    "Ready to explore?",
    "I love my life as a fish!",
    "Hi, I'm Fyn your friendly fish",
    "Welcome to Spelling Safari!"
  ];

  const handleCorrectAnswer = () => {
    setPoints(prevPoints => prevPoints + 10);
  }

  const [customMessage, setCustomMessage] = useState("");



  const wrapWithBackButton = (gameContent) => {
    return (
      <>
        {gameContent}
        <button onClick={goBackToSplash}>Back</button>
      </>
    );
  };



    useEffect(() => {
      if ('speechSynthesis' in window) {
        const synth = window.speechSynthesis;

        const updateVoices = () => {
          const voices = synth.getVoices();
          const desiredVoice = voices.find(
            v => v.name === "Google UK English Female" && v.lang === "en-GB"
          );

          setVoice(desiredVoice);
        };

        if (synth.onvoiceschanged !== undefined) {
          synth.onvoiceschanged = updateVoices;
        }

        updateVoices();
      }
    }, []);



    const handleStartDictation = async () => {
      if (!testStartedRef.current) {
        setTestStarted(true);
        testStartedRef.current = true;
        setTotalPoints(points + correctAnswers);
        setSelectedSpellingsList([]);

        if (window.speechSynthesis && !speaking) {
          setSpeaking(true);

          // "Are you ready?" message
          const readyUtterance = new SpeechSynthesisUtterance("Are you ready?");
          readyUtterance.rate = 1.0;
          readyUtterance.voice = voice;
          window.speechSynthesis.speak(readyUtterance);
          await delay(5000);

          // Randomly select 10 spellings
          const selectedSpellings = [];
          while (selectedSpellings.length < 10) {
            const randomIndex = Math.floor(Math.random() * spellings.length);
            const randomSpelling = spellings[randomIndex];
            if (!selectedSpellings.includes(randomSpelling)) {
              selectedSpellings.push(randomSpelling);
              setSelectedSpellingsState(prevState => ({
                ...prevState,
                [randomSpelling]: false
              }));

              // Add the spelling to the selectedSpellingsList state
              setSelectedSpellingsList(prevList => [...prevList, randomSpelling]);
            }
          }

          // Spellings
          const spellLoop = async (i) => {
            if (i >= 10 || !testStartedRef.current) {
              return;
            }
            const spelling = selectedSpellings[i];
            const numberUtterance = new SpeechSynthesisUtterance(`Number ${i + 1}`);
            numberUtterance.rate = 1.0;
            numberUtterance.voice = voice;

            const spellingUtterance = new SpeechSynthesisUtterance(spelling);
            spellingUtterance.rate = 1.0;
            spellingUtterance.voice = voice;

            window.speechSynthesis.speak(numberUtterance);
            await delay(5000);
            window.speechSynthesis.speak(spellingUtterance);
            await delay(5000);
            window.speechSynthesis.speak(spellingUtterance);
            await delay(5000);

            await spellLoop(i + 1);
          }

          await spellLoop(0);

          if (!testStartedRef.current) {
            return;
          }

          // "Well done" message
          const wellDoneUtterance = new SpeechSynthesisUtterance("Well done! Those are your 10 spellings.");
          wellDoneUtterance.rate = 1.0;
          wellDoneUtterance.voice = voice;
          window.speechSynthesis.speak(wellDoneUtterance);
          setTestFinished(true);

          setSpeaking(false);
        }
      } else {
        // Code for stopping the test
        if (window.speechSynthesis) {
          window.speechSynthesis.cancel();
        }

        setTestStarted(false);
        testStartedRef.current = false;
      }
    };



    const delay = (duration) => {
      return new Promise((resolve) => setTimeout(resolve, duration));
    };

    const speakAndWait = (utterance) => {
      return new Promise((resolve) => {
        utterance.onend = () => {
          resolve();
        };
        window.speechSynthesis.speak(utterance);
      });
    };


    const handleSubmit = async () => {
      // Create a data object with the user's name and the number of correct answers
      const data = {
        name: user?.name,
        correctAnswers: correctAnswers,
      };

      try {
        // Send a PUT request to update the points
        await updatePoints(user?.name, correctAnswers);

        // Send a POST request to the backend to update the points
        const response = await fetch('/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          // Points updated successfully
          // console.log('Points updated!');
        } else {
          // Handle the error
          console.log('Error updating points:', response.statusText);
        }
      } catch (error) {
        console.log('Error updating points:', error);
      }
    };

    const handleCheckboxChange = (spelling) => {
      setSelectedSpellingsState({
        ...selectedSpellingsState,
        [spelling]: !selectedSpellingsState[spelling],
      });

      // After state update, calculate the correct answers.
      setTimeout(calculateCorrectAnswers, 0);
    };

    const calculateCorrectAnswers = () => {
      let count = 0;
      for (let spelling in selectedSpellingsState) {
        if (selectedSpellingsState[spelling]) {
          count += 1;
        }
      }
      setCorrectAnswers(count * 2);
    };

    const renderAudioSpellingTest = () => {
      return (
        <>
          <p>10 spellings, answers will be given afterwards</p>
          <div className="button-container">
            <button onClick={handleStartDictation}>
              {testStarted ? "Stop Test" : "Start Spelling Test"}
            </button>
          </div>
          {testFinished && currentSpellingIndex === selectedSpellings.length && (
            <div>
            <p>Test completed!</p>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '10px' }}>
              {selectedSpellingsList.map((spelling, index) => (
                <React.Fragment key={index}>
                  <div>
                    <input
                      type="checkbox"
                      checked={selectedSpellingsState[spelling]}
                      onChange={() => handleCheckboxChange(spelling)}
                    />
                  </div>
                  <div>{spelling}</div>
                </React.Fragment>
              ))}
            </div>

            <form onSubmit={handleSubmit}>
              <label htmlFor="correctAnswers">Number of correct answers:</label>
              <input
                type="number"
                id="correctAnswers"
                value={correctAnswers}
                readOnly
              />
              <div className="button-container">
              <button type="submit">Submit</button></div>
            </form>
            <p></p>
          </div>
        )}
        </>
      );
    };

    useEffect(() => {
      if (gameMode) {
        generateQuestion();
        setStartTime(Date.now());
      }
    }, [gameMode]);

    const goBackToSplash = () => {
      setGameMode('');
      resetGame();
    };

    const generateQuestion = () => {
      let questionTypes = ['multipleChoice'];


      let selectedQuestionType;

      if (gameMode === 'random') {
          selectedQuestionType = questionTypes[Math.floor(Math.random() * questionTypes.length)];
      } else {
          selectedQuestionType = gameMode;
      }

      if (selectedQuestionType === 'multipleChoice') {
        const questionData = generateSpellingQuestion(level, dbName);
        setQuestionData(questionData);
        setSelectedQuestionType('multipleChoice');
      } else if (selectedQuestionType === 'timesTables') {
        const questionData = generateTimesTableQuestion();
        setQuestionData(questionData);
        setSelectedQuestionType('timesTables');
      }

    };

    const handleAddPoints = (pointsToAdd) => {
      setIsDancing(true); // Start dancing
      setTimeout(() => setIsDancing(false), 1000);

      if (userRoles.length > 0) {
      updatePoints(user.sub, pointsToAdd, selectedQuestionType); // Pass the selectedQuestionType as the gameName
      }
    };

    const correctAnswer = () => {
      const audio = new Audio("/sounds/correct.mp3");
      audio.volume = 0.2;
      audio.play();

      setIsDancing(true); // Start dancing
      setTimeout(() => setIsDancing(false), 1000);
      setScore(prevScore => prevScore + 1);
    };


  const handleAnswerClick = async (selectedAnswer) => {
    // Update score but not points
    if (selectedQuestionType === 'multipleChoice') {
      if (selectedAnswer === questionData.answer) {
        correctAnswer();
      } else {
        const audio = new Audio("/sounds/wrong.mp3");
        audio.volume = 0.2;
        audio.play();
      }
      const newQuestionData = await generateSpellingQuestion(level, dbName);
      if (newQuestionData !== null) {
        setQuestionData(newQuestionData);
      }
    } else if (selectedQuestionType === 'timesTables') {
      if (selectedAnswer === questionData.answer) {
        correctAnswer()
      }
      generateQuestion(); // Generate a new question
    }
    setQuestionCount(prevQuestionCount => prevQuestionCount + 1);

  };

    const endGame = () => {
      if (gameEnded) return;  // Add this line
      setGameEnded(true);

      setEndTime(Date.now());

      // Calculate and add points here
      let pointsToAdd = 0;
      if (selectedQuestionType === 'multipleChoice') {
        pointsToAdd = score * 3;
      } else if (selectedQuestionType === 'timesTables') {
        pointsToAdd = score * 2;
      }
      handleAddPoints(pointsToAdd);

      if (score === 10) {
        if (userRoles.length > 0) {
          levelUp(user.sub, dbName);
        }
        const audio = new Audio("/sounds/endgame.mp3");
        audio.volume = 0.2;
        audio.play();
      } else {
        const audio = new Audio("/sounds/gameover.mp3");
        audio.volume = 0.2;
        audio.play();
      }

      setInputNumber('');

    };

    const resetGame = () => {
      setGameMode('');
      setScore(0);
      setQuestionCount(1);
      setGameEnded(false);
      setStartTime(null);
      setEndTime(null);
      setInputNumber('');
      setSelectedQuestionType('');
    };

    useEffect(() => {
      if (selectedQuestionType === 'multipleChoice' && questionData.answer) {
        speak({ text: `${questionData.answer}`, voice });
      }
    }, [questionData.answer, selectedQuestionType]);

    const renderButtons = () => {
      if (selectedQuestionType === 'multipleChoice') {
        return (questionData.buttonOptions || []).map((option, index) => (
          <button key={index} onClick={() => handleAnswerClick(option)}>
            {option}
          </button>
        ));
      } else if (selectedQuestionType === 'timesTables') {
        return questionData.buttonOptions && (
          questionData.buttonOptions.map((option, index) => (
            <button key={index} onClick={() => handleAnswerClick(option)}>
              {option}
            </button>
          ))
        );
      }
  }

    const renderQuestionAndButtons = () => {
      let questionContent = null;

      if (selectedQuestionType === 'columnMath') {
        questionContent = <pre className="column-question questionMaths">{questionData.question}</pre>;
      } else if (selectedQuestionType === 'numberLine') {
        questionContent = (
          <div className="number-line-question questionMaths">
            <NumberLineQuestion questionData={questionData} handleAnswerClick={handleAnswerClick} />
          </div>
        );
      } else {
        questionContent = <p className='questionMaths'>{questionCount}. {questionData.question}</p>;
      }

      let additionalContent = null;
      if (selectedQuestionType === 'columnMath' || selectedQuestionType === 'numberInWords') {
        additionalContent = <p className='questionMaths'>{inputNumber ? inputNumber : lastInputNumber ? lastInputNumber : '\u00A0'}</p>;
      }

      return (
        <div className="question">
          {questionContent}
          {renderButtons()}
          {additionalContent}
          <div className="back-button-container">
            <button onClick={goBackToSplash} className='fyns-button'>Back</button>
          </div>
        </div>
      );
    };


    return (
      <div className="App">
      <div className="App-background">
        <div className="sky">
        </div>
        <div className="App-background">
          <MySVG className="svg-background" />
        </div>
        <div className="App-mid">
        <FynSVG
        className={`svg-fyn ${isDancing ? "dancing" : ""}`}
        messages={greetings}
        defaultResponses={["I'm fine", "Help me Fyn"]}
        onResponse={(response) => {
          if (response === "I'm fine") {
            return "You got it!";
          } else if (response === "Help me Fyn") {
            // show the overlay
            setOverlayVisible(true);
            return "I got you!";
          }
        }}
        customMessages={customMessages} // Pass the custom message here
      />

          {overlayVisible && (
              <div onClick={() => setOverlayVisible(false)} style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  zIndex: 1000, // make sure it's above everything else
              }}>


              </div>
          )}
          <div className='pagebox'>
            <PageBoxSVG />
            <p className='page'>{pageName}</p>
          </div>
          <div className='pointsbox'>
            <PointsBoxSVG />
            <p className='points'> {points.toString()}</p>
          </div>
          <div className='levelbox'>
            <LevelBoxSVG />
            <p className='level'>Level: {level}</p>  {/* Use the level state variable here */}
          </div>
        </div>
      </div>
      <div className="App-content">
      <div className="button-container">
      {gameMode ? (
            gameEnded ? (
              <div className="game-summary questionMaths">
                <p>{positiveMessages[Math.floor(Math.random() * positiveMessages.length)]}</p>
                <p>{score} out of 10</p>
                <p>Time: {Math.floor((endTime - startTime) / 1000)} seconds</p>
                <button onClick={resetGame}>Play Again...</button>
              </div>
            ) : (
              renderQuestionAndButtons()
            )
            ) : (
              <div className="splash-page">
            <button onClick={() => setGameMode('multipleChoice')}>Start Spelling</button>
            </div>
          )
        }
      </div>
       </div>

    </div>
    );
  };

export default Spellingsafari;
