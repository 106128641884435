import React, { useState, useEffect } from 'react';
import '../css/React.css';
import Menu from './Menu';

function Welcome() {
  const userId = 'user_12345';

  useEffect(() => {
    // Store the userId in localStorage
    localStorage.setItem('userId', userId);
  }, [userId]);

  const [version, setVersion] = useState('');

  useEffect(() => {
    fetch('/Version.txt')
      .then((response) => response.text())
      .then((data) => setVersion(data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="welcome-page">
      <Menu />
      <iframe
        id="godot-iframe"
        src="/godot/intro/index.html"
        style={{ width: '800px', height: '800px' }}
        title="Godot Game"
      ></iframe>
      <p className="version">{version}</p>
    </div>
  );
}


export default Welcome;
