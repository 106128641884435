import { getRandomNumber, shuffleArray } from "./utility";

const adjectives = [
    'Beautiful', 'Quick', 'Bright', 'Dark', 'Tall', 'Short',
    'Happy', 'Sad', 'Angry', 'Calm', 'Smart', 'Silly',
    'Strong', 'Weak', 'Large', 'Tiny', 'Loud', 'Quiet',
    'Smooth', 'Rough', 'Soft', 'Hard', 'Cold', 'Warm',
    'Young', 'Old', 'Fast', 'Slow', 'Polite', 'Rude'
];

const exampleAdjectiveSentences = [
    'The _____ dog chased its tail.',
    'She wore a _____ dress to the party.',
    'The sun is so _____ today.',
    'He gave a _____ speech.',
    'The _____ mountain was covered in snow.',
    'The _____ river flowed through the forest.',
    'The artist used _____ colors in the painting.',
    'The _____ old man told us a story.',
    'She had a _____ smile on her face.',
    'The _____ cat sat on the windowsill.',
    'The _____ book was impossible to put down.',
    'The cake tasted _____.',
    'The _____ car zoomed down the motorway.',
    'The _____ garden was full of blooming flowers.',
    'He had a _____ look in his eyes.',
    'The _____ waves crashed against the shore.',
    "She had a _____ voice that captured everyone's attention.",
    'The _____ building towered over the others.',
    'The _____ child giggled all afternoon.',
    'The sky looked _____ just before sunset.'
];

export const generateAdjectiveQuestion = () => {
    // Step 1: Randomly select an adjective
    const randomIndex = getRandomNumber(0, adjectives.length - 1);
    const selectedAdjective = adjectives[randomIndex];

    // Step 2: Randomly select an example sentence
    const randomSentenceIndex = getRandomNumber(0, exampleAdjectiveSentences.length - 1);
    const question = exampleAdjectiveSentences[randomSentenceIndex];

    // Step 3: Randomly select two other adjectives for incorrect options
    let incorrectOptions = [];
    while (incorrectOptions.length < 2) {
      const randomIncorrectIndex = getRandomNumber(0, adjectives.length - 1);
      if (randomIncorrectIndex !== randomIndex) {
        const incorrectAdjective = adjectives[randomIncorrectIndex];
        if (!incorrectOptions.includes(incorrectAdjective)) {
          incorrectOptions.push(incorrectAdjective);
        }
      }
    }

    // Step 4: Shuffle the options
    const buttonOptions = shuffleArray([selectedAdjective, ...incorrectOptions]);

    return {
      question,
      answer: selectedAdjective,
      buttonOptions
    };
};
