import React, { useEffect, useState, useContext } from 'react';
import '../App.css';
import { useAuth0 } from '@auth0/auth0-react';
import useUserData from '../hooks/useUserData';
import { ReactComponent as MySVG } from '../images/assets/sea.svg';
import { ReactComponent as CloudSVG } from '../images/cloud.svg';
import FynSVG from '../components/fyn';
import { greetings } from '../components/fyn';
import Overlay from '../components/Overlay';
import anime from 'animejs/lib/anime.es.js';
import { addPoints } from '../components/PointsComponent';
import { startCloudAnimation, animateCloud } from '../animations/cloudAnimation';

import { ReactComponent as PageBoxSVG } from '../images/pagebox.svg';
import { ReactComponent as PointsBoxSVG } from '../images/pointsbox.svg';
import { ReactComponent as LevelBoxSVG } from '../images/levelbox.svg';
import { ReactComponent as QuestionBoxSVG } from '../images/questionbox.svg';


const synonyms = [
  // Existing lists
  ["happy", "glad", "delighted", "pleased", "joyful", "cheerful", "elated", "content"],
  ["big", "massive", "colossal", "gigantic", "enormous", "huge", "large", "immense"],
  ["wonderful", "marvellous", "lovely", "remarkable", "extraordinary", "fantastic", "fabulous", "stunning"],
  ["fussy", "finicky", "choosy", "picky", "particular", "demanding", "critical"],
  ["mean", "unkind", "unpleasant", "nasty", "spiteful", "malicious", "cruel", "harsh"],
  ["bad", "awful", "terrible", "dreadful", "poor", "horrible"],
  ["small", "tiny", "miniature", "petite", "little", "minute"],
  ["smelly", "stinky", "foul", "reek", "odorous", "pungent"],
  ["conceal", "hide", "mask", "disguise", "shroud", "veil"],
  ["useful", "helpful", "beneficial", "valuable", "handy", "practical"],
  ["run", "dash", "sprint", "rush", "race", "speed"],
  ["jump", "leap", "hop", "bounce", "spring"],
  ["laugh", "giggle", "chuckle", "snicker", "guffaw"],
  ["eat", "devour", "consume", "gobble", "nibble", "munch"],
  ["quick", "swift", "fast", "rapid", "speedy", "brisk"],
  ["slow", "gradual", "leisurely", "languid", "unhurried"],
  ["bright", "shiny", "radiant", "glowing", "lustrous", "sparkling"],
  ["dark", "gloomy", "shadowy", "dusky", "dim", "murky"],
  ["strong", "powerful", "mighty", "sturdy", "tough", "robust"],
  ["weak", "feeble", "frail", "delicate", "fragile"],
  ["funny", "humorous", "amusing", "hilarious", "entertaining", "comical"],
  ["sad", "unhappy", "gloomy", "miserable", "downcast", "blue"],
  ["pretty", "beautiful", "lovely", "handsome", "attractive", "gorgeous"],
  ["ugly", "unattractive", "plain", "hideous", "unappealing", "homely"],
  ["angry", "mad", "irate", "furious", "enraged", "livid", "wrathful", "incensed", "outraged", "fuming", "seething"],
  ["plunged", "submerged", "descended", "dank", "dove", "nosedived", "tumbled","dived"],
  ["raised", "lifted", "elevated", "hoisted", "boosted", "uplifted", "ascended", "hiked"],
  ["tacky", "gooey", "adhesive", "viscous", "gluey", "gummy", "sticky", "clammy"],
  ["grabbed", "seized", "gripped", "clutched", "snatched", "snared", "captured"],
  ["tasty", "delicious", "delectable", "yummy", "flavorful", "savory"],
  ["old", "ancient", "elderly", "aged", "venerable", "antique", "archaic"]
]

const Synonymsea = () => {
  const { isAuthenticated, user } = useAuth0();
  const { isStudent, isParent, isAdmin, points, userData, fetchUserData, updatePoints } = useUserData();
  const [addedPoints, setAddedPoints] = useState(0);
  const [randomArrayIndex, setRandomArrayIndex] = useState(null);
  const [questionWord, setQuestionWord] = useState("");
  const [answerWord, setAnswerWord] = useState("");
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [buttonOptions, setButtonOptions] = useState([]);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [selectedQuestionType, setSelectedQuestionType] = useState('');
  const [questionData, setQuestionData] = useState({});
  const [isDancing, setIsDancing] = useState(false);
  const [customMessage, setCustomMessage] = useState("");
  const customMessages = [
    "Hello, dear guest!",
    "Nice to see you again!",
    "How's your day going?",
    "Ready to explore?",
    "I love my life as a fish!",
    "Hi, I'm Fyn your friendly fish",
    "Welcome to Synonym Sea!"
  ];

  const pageName = "Synonym Sea";

  // const animateWaves = () => {
  //   anime({
  //     targets: ['#1_wave', '#2_wave', '#3_wave', '#4_wave', '#5_wave'],
  //     keyframes: [
  //       { translateX: -10, translateY: 5, rotate: -2, duration: 1000 }, // Move and rotate slightly to one side
  //       { translateX: 10, translateY: -5, rotate: 2, duration: 2000 }, // Move and rotate slightly to the other side
  //       { translateX: 0, translateY: 0, rotate: 0, duration: 1000 }, // Return to the starting position
  //     ],
  //     easing: 'easeInOutSine',
  //     loop: true,
  //   });
  // };

  // useEffect(() => {
  //   animateWaves();
  // }, []);

  useEffect(() => {
    // ... other useEffect logic

    const animateWaves = () => {
      anime({
        targets: ['#1_wave', '#2_wave', '#3_wave', '#4_wave', '#5_wave'],
        keyframes: [
          { translateX: -10, translateY: 5, rotate: -2, duration: 1000 }, // Move and rotate slightly to one side
          { translateX: 10, translateY: -5, rotate: 2, duration: 2000 }, // Move and rotate slightly to the other side
          { translateX: 0, translateY: 0, rotate: 0, duration: 1000 }, // Return to the starting position
         ],
        easing: 'easeInOutSine',
        loop: true,
      });
    };


    const intervalId = setInterval(animateWaves, 15000); // Trigger animation every 2 seconds

    // Cleanup function to clear the interval
    return () => {
      clearInterval(intervalId);
    };

  }, []);


  const [resultMessage, setResultMessage] = useState(""); // State for the result message
  const positiveMessages = ["Great job!", "Well done!", "Keep it up!", "You're amazing!", "Nice work!"];

  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    if (isAuthenticated && user) {
      const roles = user['https://fynschool.com/roles'];
      // Set the state with the roles
      setUserRoles(roles || []);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserData(user.sub);
      startCloudAnimation();
    }
  }, []);

  useEffect(() => {
    document.title = "Fynschool Syn Sea";
  }, []);

  useEffect(() => {
    if (randomArrayIndex !== null) {
      const selectedArray = synonyms[randomArrayIndex];
      const remainingArrays = synonyms.filter((_, index) => index !== randomArrayIndex);
      const remainingWords = remainingArrays.reduce((acc, array) => acc.concat(array), []);
      const wrongAnswerOptions = getRandomElementsFromArray(remainingWords, 2);
      const buttonOrder = [answerWord, ...wrongAnswerOptions].sort(() => Math.random() - 0.5);
      setButtonOptions(buttonOrder);
    }
  }, [randomArrayIndex, answerWord]);

  const getRandomElementsFromArray = (array, count) => {
    const shuffledArray = array.sort(() => Math.random() - 0.5);
    return shuffledArray.slice(0, count);
  };

  const handleAddPoints = (pointsToAdd) => {
    addPoints(pointsToAdd, setAddedPoints, updatePoints, user); // Call the utility function
  };

  const correctAnswer = () => {
    const audio = new Audio("/sounds/correct.mp3");
    audio.volume = 0.2;
    audio.play();

    setIsDancing(true); // Start dancing
    setTimeout(() => setIsDancing(false), 1000);
  };


  const handleWordClick = (word) => {
    if (word === answerWord) {
      correctAnswer()

      if (userRoles.length > 0) {
        handleAddPoints(2); // Correct answer, add 10 points
      }
      const randomMessage = positiveMessages[Math.floor(Math.random() * positiveMessages.length)];
      setResultMessage(`Correct!! ${randomMessage}`);
      setTimeout(() => {
        setResultMessage(""); // Remove the message after 0.5 seconds
      }, 500);
      setTimeout(() => {
        handleStartGame(); // Refresh question and answers
      }, 500);
    } else {
      handleStartGame(); // Refresh question and answers
    }
  };


  const handleStartGame = () => {
    const randomIndex = Math.floor(Math.random() * synonyms.length);
    const selectedArray = synonyms[randomIndex];
    const questionIndex = Math.floor(Math.random() * selectedArray.length);
    const answerIndex = (questionIndex + 1) % selectedArray.length;
    setRandomArrayIndex(randomIndex);
    setQuestionWord(selectedArray[questionIndex]);
    setAnswerWord(selectedArray[answerIndex]);
  };

  return (
    <div className="App">
      <div className="App-background">
        <div className="sky">
          <MySVG className="sea-background" />
        </div>


      <div className='App-mid'>
      <FynSVG
        className={`svg-fyn ${isDancing ? "dancing" : ""}`}
        messages={greetings}
        defaultResponses={["I'm fine", "Help me Fyn"]}
        onResponse={(response) => {
          if (response === "I'm fine") {
            return "You got it!";
          } else if (response === "Help me Fyn") {
            // show the overlay
            setOverlayVisible(true);
            return "I got you!";
          }
        }}
        customMessages={customMessages} // Pass the custom message here
      />

          {overlayVisible && (
              <div onClick={() => setOverlayVisible(false)} style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  zIndex: 1000, // make sure it's above everything else
              }}>


              </div>
          )}

          <div className='pagebox'>
            <PageBoxSVG />
            <p className='page'>{pageName}</p>
          </div>
          <div className='pointsbox'>
            <PointsBoxSVG />
            <p className='points'> {points.toString()}</p>
          </div>
        </div>


      <div className="App-content">
      <div className="game-summary questionMaths">
          <div className="button-container">
          {randomArrayIndex === null && <button onClick={handleStartGame}>Start Game</button>}
          {questionWord && <p>{questionWord}</p>}
            {buttonOptions.map((option, index) => (
              <button key={index} onClick={() => handleWordClick(option)}>
                {option}
              </button>
            ))}
            {resultMessage && <p>{resultMessage}</p>}
        </div>
      </div>
      </div>
    </div>
    </div>
  );
};

export default Synonymsea;
