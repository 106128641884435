import BaseScene from "./BaseScene"; // Adjust the path as necessary
import ppBackground from "../assets/backgrounds/PaperPyramid.jpg";
import backButton from "../assets/buttons/BackButton.png";

class PaperPyramidScene extends BaseScene {
    constructor() {
        super('PaperPyramidScene');
    }


    init() {
        this.registry.set('debug', false);
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        if (isPortrait) {
            this.registry.set('isPortrait', true);
        }
    }

    preload() {
        this.load.image('ppBackgroundImage', ppBackground); // Note the key 'backgroundImage' here
        this.load.image('backButton', backButton);
    }

    create() {
        const ppBg = this.setBackground('ppBackgroundImage'); // Use the key you've defined in preload
        const userData = this.game.registry.get('userData');
        const backButtonXPosition = this.sys.game.config.width * 0.9;
        const backButtonYPosition = this.sys.game.config.height * 0.1;
        this.createButton(backButtonXPosition, backButtonYPosition, 'backButton', null, () => this.scene.start('MenuScene'));
    }
}

export default PaperPyramidScene;
