import anime from 'animejs/lib/anime.es.js';

export const startCloudAnimation = () => {
  const clouds = document.querySelectorAll('.cloud');
  clouds.forEach((cloud, index) => {
    setTimeout(() => {
      animateCloud(cloud);
    }, index * 2000); // Delay the animation start based on the cloud's index
  });
};

export const animateCloud = (cloud) => {
  const startX = Math.random() * window.innerWidth; // Random starting X position
  // const startY = Math.random() * (window.innerHeight * 0.1); // Random starting Y position in the top 10%
  const startRange = window.innerHeight * 0.07; // 13% of the window's inner height
  const endRange = window.innerHeight * 0.3; // 20% of the window's inner height

  const startY = Math.random() * (endRange - startRange) + startRange;

  const duration = 100000 + Math.random() * 5000; // Random duration between 30s and 35s
  const scale = 0.2 + Math.random() * 0.8; // Random scale between 0.9 and 1.1

  // Set initial opacity to 0
  cloud.style.opacity = 0;

  anime({
    targets: cloud,
    translateX: [`${startX}px`, `${window.innerWidth}px`],
    translateY: [`${startY}px`, `${startY}px`], // Keep the Y position constant
    scale: [scale, scale], // Keep the scale constant
    easing: 'linear',
    duration: duration,
    opacity: [
      { value: 1, duration: 200 }, // Fade in duration
      { value: 0, duration: 200, delay: duration - 400 }, // Fade out duration with delay
    ],
    complete: () => {
      // Reset cloud position when animation completes
      cloud.style.transform = `translateX(-200px)`;
      animateCloud(cloud); // Start animation again
    },
  });
};
