import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useUserData from '../hooks/useUserData';
import '../App.css';
import { useSpeechSynthesis } from 'react-speech-kit';
import { ReactComponent as GroundSVG } from '../images/assets/grass.svg';
import { ReactComponent as PageBoxSVG } from '../images/pagebox.svg';



const dictationTexts = [
    "The cat sat on the warm, sunny windowsill.",
    "Ducks swim in the pond, quacking loudly.",
    "The moon shines brightly in the night sky.",
    "A red ball bounces down the hill.",
    "We eat ice cream on a hot day.",
    "The dog barks when he sees the postman.",
    "Leaves fall from the trees in autumn.",
    "She wears a hat to keep the sun off her face.",
    "The train chugs along the tracks.",
    "Birds chirp early in the morning.",
    "In the heart of the bustling city, towering skyscrapers reached towards the heavens, their mirrored glass reflecting the vibrant energy below. Streets filled with a symphony of honking cars and hurried footsteps, while neon lights painted the night with a kaleidoscope of colors. Amidst the urban chaos, a sense of awe and possibility permeated the air, as dreams took flight amidst the city's ceaseless rhythm.",
    "I wandered through the lively streets, surrounded by the intoxicating scent of street food and the echoes of conversations in different languages. The diversity of cultures and faces was a testament to the rich tapestry of humanity. Every corner held a hidden gem—a café adorned with the aroma of freshly brewed coffee, a boutique showcasing artistic creations, or a park offering respite amidst the concrete jungle. It was a city that embraced contradictions, where modernity and tradition coexisted in harmonious chaos.",
    "The dense forest enveloped me with its emerald embrace, an oasis of tranquility untouched by the clamor of civilization. Sunlight filtered through the lush canopy, casting dappled shadows on the forest floor. The symphony of nature unfolded around me, with birdsong echoing through the air and leaves rustling in the gentle breeze. Each step along the winding path revealed a new wonder, from vibrant wildflowers dancing in the undergrowth to ancient trees reaching towards the sky.",
    "I marvelled at the wildlife,  that called the forest home—squirrels scampering playfully, deer gracefully grazing, and the occasional glimpse of a fox darting between trees. The scent of moss and earth filled my senses, grounding me in the present moment. It was a sanctuary where time seemed to stand still, where one could lose themselves in the beauty of the natural world and reconnect with the essence of life itself.",
    "As twilight painted the sky in hues of gold and orange, the quaint village came to life. Rows of charming cottages stood adorned with blooming flower baskets, and cobblestone streets whispered stories of centuries gone by. The aroma of freshly baked bread wafted from the local bakery, inviting passersby to indulge in its warm embrace. The village square buzzed with laughter and conversation, as neighbors gathered to share tales and laughter under the watchful gaze of the village fountain.",
    "I meandered through narrow alleyways, discovering hidden treasures at every turn. A bookstore beckoned with shelves brimming with knowledge and imagination, while an art studio displayed vibrant paintings that spoke to the soul. The village seemed frozen in time, preserving traditions and a slower pace of life. It was a place where community thrived, where bonds were forged, and where the simple joys of everyday existence were cherished.",
    "In the garden, birds chirped cheerfully. Their melodies filled the air, bringing joy to all who heard.",
    "The old, rickety house stood alone. Its windows were boarded up, hiding secrets of the past.",
    "On a sunny day, the beach was a paradise. Children built sandcastles while the waves gently lapped the shore.",
    "At the farm, cows mooed loudly. They gathered around as the farmer brought hay for their breakfast.",
    "Amid the quiet night, stars twinkled brightly. Their shimmering light guided the lost traveler home.",
    "During the storm, thunder boomed and lightning crackled. The rain poured down, creating puddles everywhere.",
    "In the bustling kitchen, pots clanged and the oven hummed. Delicious aromas teased the hungry guests.",
    "At the lively fair, children laughed and screamed in delight. The Ferris wheel spun, offering a view of the entire town.",
    "In the dense jungle, leaves rustled as animals scurried about. The towering trees seemed to touch the sky.",
    "During winter, snowflakes fell gently, covering the land in white. Children sledded down hills, laughing all the way.",
    "In the quiet library, pages rustled softly. The world of books welcomed all who entered.",
    "At the break of dawn, the village slowly came to life. Roosters crowed, announcing the start of a new day.",
    "In the colorful garden, bees buzzed from flower to flower. Their busy work helped the garden thrive.",
    "During the match, the crowd cheered wildly. The excitement in the stadium was palpable.",
    "In the cozy cottage, the fireplace crackled warmly. Outside, the wind howled through the barren trees.",
    "At the bustling market, vendors shouted out their wares. The smell of fresh produce filled the air.",
    "During the concert, the band played energetically. The audience clapped and sang along, enjoying every moment.",
    "In the peaceful meadow, the tall grass swayed gently. Butterflies fluttered, adding color to the green landscape.",
    "At the ancient ruins, history whispered through the crumbling walls. Visitors wandered, lost in thought of bygone eras.",
    "During the picnic, ants marched towards the crumbs. The sun shone brightly, making the day perfect."
]

const Dictationdesert = () => {
  useEffect(() => {
    document.title = "Fynschool Dictation Desert";
  }, []);
    const { isAuthenticated, user } = useAuth0();
    const { isStudent, isParent, isAdmin, points, userData, fetchUserData, updatePoints, studentName} = useUserData();

    const { speak, supported, cancel, pause, resume } = useSpeechSynthesis();
    const [voice, setVoice] = useState(null);
    const [speaking, setSpeaking] = useState(false);
    const pageName = "Dictation Desert";

    useEffect(() => {
      if (isAuthenticated && user) {
        fetchUserData(user.sub);
      }
    }, [isAuthenticated, user]);


    useEffect(() => {
        if ('speechSynthesis' in window) {
          const synth = window.speechSynthesis;

          synth.onvoiceschanged = () => {
            const voices = synth.getVoices();
            const desiredVoice = voices.find(
              v => v.name === "Google UK English Female" && v.lang === "en-GB"
            );

            setVoice(desiredVoice);
          };
        }
      }, []);


      const handleStartDictation = () => {
        const randomIndex = Math.floor(Math.random() * dictationTexts.length);
        const selectedText = dictationTexts[randomIndex];

        if (window.speechSynthesis && !speaking) {
          const segments = selectedText.split(/[.,;?!]/);
          setSpeaking(true);
          speakSegments(segments);
        }
      };

      const speakSegments = async (segments) => {
        for (const segment of segments) {
          for (let i = 0; i < 3; i++) {
            const utterance = new SpeechSynthesisUtterance(segment.trim());
            utterance.rate = 0.75;
            utterance.pitch = 1.0;
            utterance.voice = voice;

            window.speechSynthesis.speak(utterance);
            await delay(8000); // Delay for approximately 3 seconds
          }
        }

        setSpeaking(false);
      };

      const delay = (duration) => {
        return new Promise((resolve) => setTimeout(resolve, duration));
      };

      const handleStopDictation = () => {
        if (speaking) {
          cancel(); // Stop speech synthesis
        }
      };

      const handlePauseDictation = () => {
        if (speaking) {
          pause(); // Pause speech synthesis
        }
      };

      const handleResumeDictation = () => {
        if (speaking) {
          resume(); // Resume speech synthesis
        }
      };


      return (
      <div className="App">
        <div className="App-background">
          <GroundSVG className="grass" />
        </div>
        <div className='App-mid'>
          <div className='pagebox'>
            <PageBoxSVG />
            <p className='page'>{pageName}</p>
          </div>
        </div>
        <div className="App-header">
          <div className="button-container">
            <button onClick={handleStartDictation}>Start Dictation</button>
            <button onClick={handleStopDictation}>Stop Dictation</button>
            {/* {speaking && <button onClick={handlePauseDictation}>Pause Dictation</button>}
            {!speaking && <button onClick={handleResumeDictation}>Resume Dictation</button>} */}
          </div>

          </div>
      </div>
      );
    };

export default Dictationdesert;
