// generateCodeWordQuestion.js

import { getRandomNumber, shuffleArray } from "./utility";

export const generateCodeWordQuestion = (level) => {
    // Example words and some decoys
    const words = [
        "apple", "banana", "cherry", "date", "fig",
        "grape", "kiwi", "lemon", "mango", "olive",
        "peach", "pear", "plum", "quince", "berry",
        "melon", "lime", "cocoa", "berry", "leek",
        "corn", "peas", "bean", "kale", "herb",
        "mint", "thyme", "basil", "chive", "dill",
        "onion", "carrot", "radish", "turnip", "squash",
        "salad", "cress", "papaya", "lychee", "guava",
        "tamar", "spice", "aroma", "sugar", "honey",
        "doll", "truck", "train", "ball", "kite",
        "drum", "bear", "ship", "robot", "puzzle",
        "block", "ring", "yoyo", "game", "slide",
        "bike", "racer", "plane", "sword", "castle",
        "crown", "horse", "piano", "flute", "guitar",
        "drone", "skate", "board", "cards", "dart",
        "viola", "harp", "oboe", "claro", "cello",
        "organ", "bongo", "conga", "tabla", "sitar",
        "lyre", "zither", "banjo", "lute", "horn",
        "pen", "pencil", "ruler", "eraser", "clip",
        "glue", "tape", "sharp", "chalk", "marker",
        "brush", "stamp", "ink", "note", "book"
    ];

    // Define pattern types based on level
    // 1: Word to Code
    // 2: Code to Word
    let availablePatternTypes;

    if (level <= 10) {
        availablePatternTypes = [1]; // Only Word to Code
    } else if (level <= 20) {
        availablePatternTypes = [1, 2]; // Word to Code and Code to Word
    } else {
        availablePatternTypes = [1, 2]; // Continue with both patterns
        // You can introduce more patterns here if needed
    }

    // Select patternType randomly from available types
    const patternType = getRandomNumber(0, availablePatternTypes.length - 1);
    const selectedPattern = availablePatternTypes[patternType];

    const selectedWord = words[getRandomNumber(0, words.length - 1)];

    // Generate the correct code for the selected word
    const correctCode = selectedWord.split('').map(letter => letter.charCodeAt(0) - 'a'.charCodeAt(0) + 1).join(' ');

    // Pre-filter words by the selected word's length to avoid repeated filtering and exclude the selectedWord
    const sameLengthWords = words.filter(word => word.length === selectedWord.length && word !== selectedWord);

    // Function to generate decoy words
    const generateDecoyWords = (decoyCount = 3) => {
        if (sameLengthWords.length === 0) {
            return []; // No decoys available
        }
        const shuffled = shuffleArray(sameLengthWords);
        return shuffled.slice(0, Math.min(decoyCount, sameLengthWords.length));
    };

    // Function to generate decoy codes by adding an incremental offset to each number in the correct code
    const generateDecoyCodes = (correctCode, numDecoys = 3) => {
        const decoys = new Set();
        const codeNumbers = correctCode.split(' ').map(num => parseInt(num));

        // Generate decoy codes by adding an incremental offset to each number in the correct code
        for (let i = 1; i <= numDecoys; i++) {
            let decoy = codeNumbers.map(num => num + i).join(' ');
            decoys.add(decoy);
        }

        return Array.from(decoys);
    };

    let questionCode, answer, buttonOptions = [];

    switch (selectedPattern) {
        case 1:
            // Pattern Type 1: Word to Code
            // Example: "What is the word for the code '1 16 16 12 5'?"
            const decoyWords = generateDecoyWords(3);
            buttonOptions = [selectedWord, ...decoyWords];
            buttonOptions = shuffleArray(buttonOptions); // Shuffle to randomize the options

            questionCode = `What is the word for the code "${correctCode}"?`;
            answer = selectedWord;
            break;
        case 2:
            // Pattern Type 2: Code to Word
            // Example: "What is the code for 'apple'?"
            const decoyCodes = generateDecoyCodes(correctCode, 3);
            // Ensure that decoyCodes are unique and do not duplicate the correct code
            const uniqueDecoyCodes = decoyCodes.filter(code => code !== correctCode);
            buttonOptions = [correctCode, ...uniqueDecoyCodes];
            buttonOptions = shuffleArray(buttonOptions); // Shuffle to randomize the options

            questionCode = `What is the code for "${selectedWord}"?`;
            answer = correctCode;
            break;
        default:
            // Fallback to Pattern Type 1 if something goes wrong
            const fallbackDecoyWords = generateDecoyWords(3);
            buttonOptions = [selectedWord, ...fallbackDecoyWords];
            buttonOptions = shuffleArray(buttonOptions);

            questionCode = `What is the word for the code "${correctCode}"?`;
            answer = selectedWord;
            break;
    }

    // Safeguard: Ensure that questionCode and answer are defined
    if (!questionCode || !answer) {
        throw new Error("Failed to generate the question. Please try again.");
    }

        // Combine questionIntro and questionCode into a single 'question' field
        const questionIntro = "Given the encoding pattern, a=1, b=2, c=3, ..., z=26.";
        const question = `${questionIntro}\n${questionCode}`;

    return {
        question,
        answer,
        buttonOptions
    };
};
