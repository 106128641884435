import React from 'react';
import anime from 'animejs';

class BusStop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roofNumber: '',
      roofRemainder: '',
      passenger: '',
      calculation: ''
    };
  }

  componentDidMount() {
    const { dividend, divisor } = this.props;
    let delay = 1000;

    // Function to animate text
    const animateText = (target, value, fullOpacity = false) => {
      this.setState({ [target]: value });
      const opacity = fullOpacity ? 1 : [0, 1];
      return anime({
        targets: `#${target}`,
        opacity,
        duration: 500,
        easing: 'linear',
        direction: 'alternate',
        loop: 2
      }).finished;
    }

    // Animate roof and left-hand line
    anime({
      targets: '#roof, #leftLine',
      opacity: 1,
      duration: 1000,
      easing: 'linear'
    }).finished.then(() => {
      // Animate and flash 'dividend' and number under the roof
      return anime({
        targets: '#dividend, #dividendNumber',
        opacity: [0, 1],
        duration: 500,
        easing: 'linear',
        direction: 'alternate',
        loop: 2
      }).finished;
    }).then(() => {
      // Keep the 'dividendNumber' visible
      anime({
        targets: '#dividendNumber',
        opacity: 1,
        duration: 1000,
        easing: 'linear'
      });

      // Animate and flash 'divisor' and number on the left
      return anime({
        targets: '#divisor, #divisorNumber',
        opacity: [0, 1],
        duration: 500,
        easing: 'linear',
        direction: 'alternate',
        loop: 2
      }).finished;
    }).then(() => {
      // Keep the 'divisorNumber' visible
      anime({
        targets: '#divisorNumber',
        opacity: 1,
        duration: 1000,
        easing: 'linear'
      });

    });
  }

  render() {
    const { roofNumber, roofRemainder, passenger, calculation } = this.state;
    const { dividend, divisor } = this.props;

    return (
      <svg width="500" height="200" className="overlay-content">
        <line id="roof" x1="50" y1="50" x2="120" y2="50" stroke="black" strokeWidth="2" opacity="0" />
        <line id="leftLine" x1="50" y1="50" x2="50" y2="100" stroke="black" strokeWidth="2" opacity="0" />

        <text id="dividend" x="60" y="120" fontFamily="gaegu" fontSize="35" fill="black" opacity="0">Bus</text>
        <text id="dividendNumber" x="60" y="80" fontFamily="gaegu" fontSize="35" fill="blue" opacity="0">{dividend}</text>

        <text id="divisor" x="0" y="120" fontFamily="gaegu" fontSize="35" fill="black" opacity="0">Bus Stop</text>
        <text id="divisorNumber" x="0" y="80" fontFamily="gaegu" fontSize="35" fill="red" opacity="0">{divisor}</text>

        <text id="roofNumber" x="90" y="35" fontFamily="gaegu" fontSize="35" fill="green" opacity="0">{roofNumber}</text>
        <text id="roofRemainder" x="140" y="35" fontFamily="gaegu" fontSize="35" fill="green" opacity="0">{roofRemainder}</text>
        <text id="passenger" x="60" y="160" fontFamily="gaegu" fontSize="35" fill="purple" opacity="0">{passenger}</text>
        <text id="calculation" x="150" y="120" fontFamily="gaegu" fontSize="35" fill="black" opacity="0">{calculation}</text>
      </svg>
    );
  }
}

export default BusStop;
