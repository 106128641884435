import { getRandomNumber } from "./utility";
import { shuffleArray } from "./utility";

export const generateOddOrEvenQuestion = () => {
    const number = getRandomNumber(1, 100);
    const isEven = number % 2 === 0;
    const buttonOptions = shuffleArray(['odd', 'even']);
    const answer = isEven ? 'even' : 'odd';

    return {
      question: `Is ${number} odd or even?`,
      isEven,
      answer,
      buttonOptions,
    };
  };