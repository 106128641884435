import { shuffleArray } from "./utility";

const idioms = [
    { idiom: 'Break the ice', meaning: 'to initiate social interactions or conversation' },
    { idiom: 'Spill the beans', meaning: 'to reveal secret information accidentally or maliciously' },
    { idiom: 'Let the cat out of the bag', meaning: 'to accidentally reveal a secret' },
    { idiom: 'Hit the nail on the head', meaning: 'to describe exactly what is causing a situation or problem' },
    { idiom: 'Bite the bullet', meaning: 'to force oneself to perform a painful, difficult task or to endure an unpleasant situation' },
    { idiom: 'Barking up the wrong tree', meaning: 'to have a mistaken belief, or be misguided' },
    { idiom: 'The best of both worlds', meaning: 'a situation wherein someone can enjoy two different opportunities' },
    { idiom: 'A blessing in disguise', meaning: 'a good thing that seemed bad at first' },
    { idiom: 'Call it a day', meaning: 'stop working on something' },
    { idiom: 'Cut corners', meaning: 'to do something poorly or cheaply' },
    { idiom: 'Getting a taste of your own medicine', meaning: 'being treated the way you have been treating others' },
    { idiom: 'Give someone the cold shoulder', meaning: 'to ignore someone' },
    { idiom: 'Go on a wild goose chase', meaning: 'to do something pointless' },
    { idiom: 'Hit the sack', meaning: 'to go to bed' },
    { idiom: 'Let someone off the hook', meaning: 'to not hold someone responsible for something' },
    { idiom: 'Miss the boat', meaning: 'to miss an opportunity' },
    { idiom: 'Once in a blue moon', meaning: 'very rarely' },
    { idiom: 'Pull someone’s leg', meaning: 'to joke, to kid or trick someone' },
    { idiom: 'Sit on the fence', meaning: 'to remain neutral and not take sides' },
    { idiom: 'Speak of the devil', meaning: 'when the person you have just been talking about arrives' },
    { idiom: 'The ball is in your court', meaning: 'it is up to you to make the next decision or step' },
    { idiom: 'The best thing since sliced bread', meaning: 'a great invention or innovation' },
    { idiom: 'Throw in the towel', meaning: 'to give up' },
    { idiom: 'Under the weather', meaning: 'feeling ill or sick' },
    { idiom: 'You can’t judge a book by its cover', meaning: 'not to judge something by its outward appearance' }
];


export const generateIdiomQuestion = () => {
    // Step 1: Randomly select an idiom
    const randomIndex = Math.floor(Math.random() * idioms.length);
    const selectedIdiom = idioms[randomIndex];

    // Step 2: Formulate the question
    const question = `What is the meaning of "${selectedIdiom.idiom}"?`;

    // Step 3: Select two other meanings for incorrect options
    let incorrectOptions = [];
    while (incorrectOptions.length < 2) {
        const randomIncorrectIndex = Math.floor(Math.random() * idioms.length);
        if (randomIncorrectIndex !== randomIndex) {
            const incorrectMeaning = idioms[randomIncorrectIndex].meaning;
            if (!incorrectOptions.includes(incorrectMeaning)) {
                incorrectOptions.push(incorrectMeaning);
            }
        }
    }

    // Step 4: Shuffle the options
    const buttonOptions = shuffleArray([selectedIdiom.meaning, ...incorrectOptions]);

    return {
        question,
        answer: selectedIdiom.meaning,
        buttonOptions
    };
};
