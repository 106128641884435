import React, { useEffect, useState } from 'react';
import '../App.css';
import { useAuth0 } from '@auth0/auth0-react';
import useUserData from '../hooks/useUserData';
import { ReactComponent as MySVG } from '../images/assets/sea.svg';
import { ReactComponent as CloudSVG } from '../images/cloud.svg';
import Bground from '../images/assets/island.jpg'
import FynSVG from '../components/fyn';
import { greetings } from '../components/fyn';
import Overlay from '../components/Overlay';
import anime from 'animejs/lib/anime.es.js';
import { addPoints } from '../components/PointsComponent';
import { startCloudAnimation, animateCloud } from '../animations/cloudAnimation';

import { ReactComponent as PageBoxSVG } from '../images/pagebox.svg';
import { ReactComponent as PointsBoxSVG } from '../images/pointsbox.svg';
import { ReactComponent as LevelBoxSVG } from '../images/levelbox.svg';
import { ReactComponent as QuestionBoxSVG } from '../images/questionbox.svg';

const interviewQuestions = [
    {
      question: "Do you think trees can talk to each other?",
      yesFollowUp: "How do you think trees communicate with each other?",
      noFollowUp: "What if I told you that trees can send signals to each other through their roots?",
    },
    {
      question: "Would you like to live on the moon?",
      yesFollowUp: "What would you take with you to live on the moon?",
      noFollowUp: "Why wouldn't you want to live on the moon? What makes Earth a better home?",
    },
    {
      question: "Can a robot be your friend?",
      yesFollowUp: "What would you do with a robot friend?",
      noFollowUp: "Why do you think robots cannot be friends? What makes a good friend?",
    },
    {
      question: "Do you think exercising can make you as strong as a superhero?",
      yesFollowUp: "Which superhero would you want to be as strong as, and why?",
      noFollowUp: "What do you think makes superheroes different from us?",
    },
    {
      question: "Do you believe animals have feelings like us?",
      yesFollowUp: "Can you think of a time you saw an animal showing feelings? How did it make you feel?",
      noFollowUp: "Why do you think animals don't have feelings? Can they be happy or sad?",
    },
    {
      question: "Are black holes dangerous?",
      yesFollowUp: "What makes black holes dangerous, do you think?",
      noFollowUp: "Could there be anything good about black holes? What might that be?",
    },
    {
      question: "Should every student have a computer at school?",
      yesFollowUp: "How would having a computer help you learn better?",
      noFollowUp: "What are some ways you can learn without a computer? Why might that be better?",
    },
    {
      question: "Is playing sports better than playing video games?",
      yesFollowUp: "What benefits do you get from playing sports that video games can't give you?",
      noFollowUp: "Can video games teach you things that sports cannot? What are they?",
    },
    {
      question: "Are bees more important than spiders?",
      yesFollowUp: "Why do you think bees are more important? What do they do for us?",
      noFollowUp: "Do you think spiders play just as important a role as bees? What do spiders do that's beneficial?",
    },
    {
      question: "Do you think aliens exist?",
      yesFollowUp: "What do you think aliens look like? Where do they live?",
      noFollowUp: "Why do you think we haven't found aliens yet? Could there be reasons they haven't found us?",
    },
    {
      question: "Is saving water really important?",
      yesFollowUp: "What are some ways we can save water at home?",
      noFollowUp: "Why do you think saving water might not be necessary? What's abundant about water?",
    },
    {
      question: "Do you think we'll travel in flying cars in the future?",
      yesFollowUp: "What would be the best thing about flying cars?",
      noFollowUp: "What kind of transportation do you imagine we'll use in the future?",
    },
    {
      question: "Would it be good if dinosaurs were still alive today?",
      yesFollowUp: " What kind of world do you think we'd live in with dinosaurs?",
      noFollowUp: "Why wouldn't it be a good idea to have dinosaurs around today?",
    },
    {
      question: "Are books always better than movies?",
      yesFollowUp: "Can you think of a book that was much better than its movie? Why was it better?",
      noFollowUp: "What movie do you think was better than the book? What made it better?",
    },
    {
      question: "If you could invent something that doesn't exist, what would it be?",
      yesFollowUp: "How would your invention help people?",
      noFollowUp: "What's something you wish existed to make your life more fun or easier?",
    },
    {
      question: "Can we live without trees?",
      yesFollowUp: "What makes you think we could survive without trees?",
      noFollowUp: "Why are trees so important for our survival?",
    },
    {
      question: "Would having a robot at home make life better?",
      yesFollowUp: "What tasks would you want a robot to do for you?",
      noFollowUp: "Why might having a robot at home not be a good idea?",
    },
    {
      question: " Is the sun the most important star in the universe?",
      yesFollowUp: "What makes the sun more important than other stars?",
      noFollowUp: "Can you think of any star that might be more important? Why?",
    },
    {
      question: "If you could bring one book character to life, who would it be?",
      yesFollowUp: "Why did you choose that character? What would you do together?",
      noFollowUp: "What qualities would you look for in a character to bring to life?",
    },
    {
      question: " If you could change the color of one thing in nature, what would it be and why?",
      yesFollowUp: "How would changing its color affect how people see it?",
      noFollowUp: "Is there something you think is perfect just the way it is? Why is it perfect in its current color?",
    },

  ];


const Interviewisland = () => {
  const { isAuthenticated, user } = useAuth0();
  const { isStudent, isParent, isAdmin, points, userData, fetchUserData, updatePoints } = useUserData();
  const [addedPoints, setAddedPoints] = useState(0);
  const [randomArrayIndex, setRandomArrayIndex] = useState(null);
  const [questionWord, setQuestionWord] = useState("");
  const [answerWord, setAnswerWord] = useState("");
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [buttonOptions, setButtonOptions] = useState([]);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [selectedQuestionType, setSelectedQuestionType] = useState('');
  const [questionData, setQuestionData] = useState({});
  const [isDancing, setIsDancing] = useState(false);
  const [customMessage, setCustomMessage] = useState("");
  const customMessages = [
    "Hello, dear guest!",
    "Nice to see you again!",
    "How's your day going?",
    "Ready to explore?",
    "I love my life as a fish!",
    "Hi, I'm Fyn your friendly fish",
    "Welcome to Interview Island!"
  ];

  const pageName = "Interview Island";

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [userResponse, setUserResponse] = useState(null); // 'yes' or 'no'

  const startInterview = () => {
    const randomIndex = Math.floor(Math.random() * interviewQuestions.length);
    setCurrentQuestionIndex(randomIndex);
    setShowFollowUp(false); // Reset follow-up question visibility
  };

  // Handle user response and display follow-up question
  const handleResponse = (response) => {
    setUserResponse(response);
    setShowFollowUp(true);
  };

  // const animateWaves = () => {
  //   anime({
  //     targets: ['#1_wave', '#2_wave', '#3_wave', '#4_wave', '#5_wave'],
  //     keyframes: [
  //       { translateX: -10, translateY: 5, rotate: -2, duration: 1000 }, // Move and rotate slightly to one side
  //       { translateX: 10, translateY: -5, rotate: 2, duration: 2000 }, // Move and rotate slightly to the other side
  //       { translateX: 0, translateY: 0, rotate: 0, duration: 1000 }, // Return to the starting position
  //     ],
  //     easing: 'easeInOutSine',
  //     loop: true,
  //   });
  // };

  // useEffect(() => {
  //   animateWaves();
  // }, []);

  useEffect(() => {
    // ... other useEffect logic

    const animateWaves = () => {
      anime({
        targets: ['#1_wave', '#2_wave', '#3_wave', '#4_wave', '#5_wave'],
        keyframes: [
          { translateX: -10, translateY: 5, rotate: -2, duration: 1000 }, // Move and rotate slightly to one side
          { translateX: 10, translateY: -5, rotate: 2, duration: 2000 }, // Move and rotate slightly to the other side
          { translateX: 0, translateY: 0, rotate: 0, duration: 1000 }, // Return to the starting position
         ],
        easing: 'easeInOutSine',
        loop: true,
      });
    };


    const intervalId = setInterval(animateWaves, 15000); // Trigger animation every 2 seconds

    // Cleanup function to clear the interval
    return () => {
      clearInterval(intervalId);
    };

  }, []);


  const [resultMessage, setResultMessage] = useState(""); // State for the result message
  const positiveMessages = ["Great job!", "Well done!", "Keep it up!", "You're amazing!", "Nice work!"];

  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    if (isAuthenticated && user) {
      const roles = user['https://fynschool.com/roles'];
      // Set the state with the roles
      setUserRoles(roles || []);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserData(user.sub);
      startCloudAnimation();
    }
  }, []);

  useEffect(() => {
    document.title = "Fynschool Interview Island";
  }, []);

  useEffect(() => {
    if (randomArrayIndex !== null) {
      const selectedArray = synonyms[randomArrayIndex];
      const remainingArrays = synonyms.filter((_, index) => index !== randomArrayIndex);
      const remainingWords = remainingArrays.reduce((acc, array) => acc.concat(array), []);
      const wrongAnswerOptions = getRandomElementsFromArray(remainingWords, 2);
      const buttonOrder = [answerWord, ...wrongAnswerOptions].sort(() => Math.random() - 0.5);
      setButtonOptions(buttonOrder);
    }
  }, [randomArrayIndex, answerWord]);

  const getRandomElementsFromArray = (array, count) => {
    const shuffledArray = array.sort(() => Math.random() - 0.5);
    return shuffledArray.slice(0, count);
  };

  const handleAddPoints = (pointsToAdd) => {
    addPoints(pointsToAdd, setAddedPoints, updatePoints, user); // Call the utility function
  };

  const correctAnswer = () => {
    const audio = new Audio("/sounds/correct.mp3");
    audio.volume = 0.2;
    audio.play();

    setIsDancing(true); // Start dancing
    setTimeout(() => setIsDancing(false), 1000);
  };


  const handleWordClick = (word) => {
    if (word === answerWord) {
      correctAnswer()

      if (userRoles.length > 0) {
        handleAddPoints(2); // Correct answer, add 10 points
      }
      const randomMessage = positiveMessages[Math.floor(Math.random() * positiveMessages.length)];
      setResultMessage(`Correct!! ${randomMessage}`);
      setTimeout(() => {
        setResultMessage(""); // Remove the message after 0.5 seconds
      }, 500);
      setTimeout(() => {
        handleStartGame(); // Refresh question and answers
      }, 500);
    } else {
      handleStartGame(); // Refresh question and answers
    }
  };


  const handleStartGame = () => {
    const randomIndex = Math.floor(Math.random() * synonyms.length);
    const selectedArray = synonyms[randomIndex];
    const questionIndex = Math.floor(Math.random() * selectedArray.length);
    const answerIndex = (questionIndex + 1) % selectedArray.length;
    setRandomArrayIndex(randomIndex);
    setQuestionWord(selectedArray[questionIndex]);
    setAnswerWord(selectedArray[answerIndex]);
  };

  return (
    <>
    <div className='App'>
      <div className="App-background">
        <img src={Bground} alt="Maths Meadow Left" className="rainforest" />
        <div className="sun"></div>
      </div>

      <div className='App-mid'>
      <FynSVG
        className={`svg-fyn ${isDancing ? "dancing" : ""}`}
        messages={greetings}
        defaultResponses={["I'm fine", "Help me Fyn"]}
        onResponse={(response) => {
          if (response === "I'm fine") {
            return "You got it!";
          } else if (response === "Help me Fyn") {
            // show the overlay
            setOverlayVisible(true);
            return "I got you!";
          }
        }}
        customMessages={customMessages} // Pass the custom message here
      />

          {overlayVisible && (
              <div onClick={() => setOverlayVisible(false)} style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  zIndex: 1000, // make sure it's above everything else
              }}>


              </div>
          )}

          <div className='pagebox'>
            <PageBoxSVG />
            <p className='page'>{pageName}</p>
          </div>
          <div className='pointsbox'>
            <PointsBoxSVG />
            <p className='points'> {points.toString()}</p>
          </div>
        </div>
        </div>


      <div className="App-header">
      <div className="game-summary questionMaths">
        <div className="button-container">
          {currentQuestionIndex === null ? (
            <button onClick={startInterview}>Start Interview</button>
          ) : !showFollowUp ? (
            <>
              <p>{interviewQuestions[currentQuestionIndex].question}</p>
              <button onClick={() => handleResponse('yes')}>Yes</button>
              <button onClick={() => handleResponse('no')}>No</button>
              
            </>
          ) : (
            <p>{userResponse === 'yes' ? interviewQuestions[currentQuestionIndex].yesFollowUp : interviewQuestions[currentQuestionIndex].noFollowUp}</p>
          )}
        </div>
      </div>
    </div>
  </>
);
};
export default Interviewisland;
