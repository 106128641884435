import BaseScene from "./BaseScene"; // Adjust the path as necessary
import loadingBackground from "../assets/backgrounds/LoadingScreen.jpg";
import readyButton from "../assets/buttons/ReadyButton.png";


class LoadingScene extends BaseScene {
    constructor() {
        super('LoadingScene');
        this.questions = [];
        this.questionsLoaded = false;
        this.topicData = { reactComponent: 'incorrectAnswer' };
    }

    init(data) {
        // this.registry.set('debug', true);
        this.registry.set('debug', false);
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        if (isPortrait) {
            this.registry.set('isPortrait', true);
        }
        // console.log('LoadingScene init started');
        this.incorrectAnswerMode = this.game.registry.get('incorrectAnswerMode') || false;

        if (this.incorrectAnswerMode) {
            // console.log('Incorrect mode started');
            const userData = this.game.registry.get('userData');
            this.collectIncorrectAnswers(userData);

        } else {
            this.topicData = data.topic || this.topicData; // Preserve topicData if already set

            // console.log('scenes: ', data.topic.possibleQuestionScenes);

            var randomIndex = Math.floor(Math.random() * data.topic.possibleQuestionScenes.length);
            this.sceneToLoad = data.topic.possibleQuestionScenes[randomIndex];

            // console.log('Selected scene: ', this.sceneToLoad);

            window.addEventListener('questionsGenerated', this.handleQuestionsGenerated.bind(this), { once: true });
            this.proceedToNextQuestion = data.proceedToNextQuestion;
        }


    }

    preload() {
        // console.log('LoadingScene preload started');
        this.load.image('loadingBackgroundImage', loadingBackground); // Note the key 'backgroundImage' here
        this.load.image('readyButton', readyButton);


        // console.log('Dispatching questionsGenerated event', this.level);
        if (this.incorrectAnswerMode) {
            // Do not dispatch 'generateQuestions' event in incorrect answer mode
            this.collectIncorrectAnswers(this.game.registry.get('userData'));
        } else {
            // Dispatch the event for normal mode
            // console.log('Level in phaser from registry', this.registry.get('level'));
            window.dispatchEvent(new CustomEvent('generateQuestions', {
                detail: {
                    reactComponent: this.topicData.reactComponent,
                    level: this.registry.get('level')

                    // Add any additional data you might need for question generation
                }
            }));
        }

    }

    create() {
        const debug = this.registry.get('debug');
        const loadingBg = this.setBackground('loadingBackgroundImage'); // Use the key you've defined in preload
        this.backgroundOverlay()
        const userData = this.game.registry.get('userData');
        this.game.registry.set('parentScene', this.topicData.parentScene);

        const musicButtonXPosition = this.sys.game.config.width * 0.8;
        const musicButtonYPosition = this.sys.game.config.height * 0.05;
        this.musicButton = this.createButton(
            musicButtonXPosition, // X position
            musicButtonYPosition, // Y position
            'musicButton', // Texture key for the button
            null, // Hover texture (null if not used)
            () => this.toggleMusicAndTint(), // Action on click
            0.5, // Scale multiplier
            false // Availability
        );

        // Example: Adding a back button to go back to the parent scene
        const backButtonXPosition = this.sys.game.config.width * 0.9;
        const backButtonYPosition = this.sys.game.config.height * 0.1;
        this.createButton(backButtonXPosition, backButtonYPosition, 'backButton', null, () => {
            this.scene.start(this.topicData.parentScene); // Use parentScene to go back
        });

        if (this.proceedToNextQuestion) {
            this.loadNextQuestion();
        }
    }




    update() {
        const debug = this.registry.get('debug');
        if (this.questionsLoaded) {
            this.showReadyButton();
            this.questionsLoaded = false;
        }

        // Check if it's time to load the next question
        if (this.registry.get('loadNextQuestion')) {
            this.loadNextQuestion();
            this.registry.set('loadNextQuestion', false); // Reset the flag
        }
    }


    handleQuestionsGenerated(event) {
        const debug = this.registry.get('debug');
        // console.log('Questions loaded from React:', event.detail.questions);
        const loadedQuestions = event.detail.questions;

        // Ensure this.topicData and this.topicData.key are defined
        if (!this.topicData || this.topicData.key === undefined) {
            console.error('Topic data or key is undefined.');
            return;
        }

        // Assuming this.topicData.possibleQuestionScenes contains the list of possible scenes.
        // Make sure to adjust this line if possibleQuestionScenes comes from a different source.
        const possibleScenes = this.topicData.possibleQuestionScenes;

        const enhancedQuestions = loadedQuestions.map(question => ({
            ...question,
            scene: possibleScenes[Math.floor(Math.random() * possibleScenes.length)], // Assign a random scene
            key: this.topicData.key
        }));
        if (debug) {
            console.log('Enhanced questions:', enhancedQuestions);
        }

        //
        // Save the enhanced questions with the assigned scene back to the registry
        this.registry.set('questions', enhancedQuestions);
        this.registry.set('currentQuestionIndex', 0); // Initialize current question index
        this.questionsLoaded = true;
    }


    loadNextQuestion() {
        let currentQuestionIndex = this.registry.get('currentQuestionIndex');
        const questions = this.registry.get('questions');

        if (currentQuestionIndex < questions.length) {
            // console.log('current index', currentQuestionIndex)
            const questionData = questions[currentQuestionIndex];
            // const sceneToLoad = questionData.scene; // Use the dynamically assigned scene
            // console.log('scene passed: ', this.sceneToLoad)

            // Increment the question index for the next call
            this.registry.set('currentQuestionIndex', currentQuestionIndex + 1);

            // Transition to the selected question scene, passing the question data
            // Ensure you pass any necessary data the next scene needs to properly present the question
            this.scene.start(this.sceneToLoad, {
                questionData,
                topic: this.topicData // Pass topicData along
            });
        } else {
            // No more questions left, transition to a summary or end scene
            // console.log('parent scene', this.parentScene)
            this.scene.start('GameSummaryScene', { parentScene: this.parentScene });
        }
    }

    collectIncorrectAnswers(userData) {
        if (!userData || typeof userData !== 'object') {
            console.error('Invalid userData');
            return;
        }
        this.questionsLoaded = false;

        const questionSet = new Set();

        Object.keys(userData).forEach(key => {
            const section = userData[key];
            if (section && Array.isArray(section.incorrectAnswers)) {
                section.incorrectAnswers.forEach(incorrectAnswer => {
                    if (incorrectAnswer.questionScene) {
                        const transformedIncorrectAnswer = {
                            ...incorrectAnswer,
                            question: incorrectAnswer.questionText,
                            answer: incorrectAnswer.correctAnswer,
                            scene: incorrectAnswer.questionScene // Use the scene from the incorrect answer data
                        };
                        delete transformedIncorrectAnswer.questionText;

                        // Create a unique identifier for each question
                        const questionIdentifier = JSON.stringify(transformedIncorrectAnswer);

                        // Add to the set to ensure no duplicates
                        if (!questionSet.has(questionIdentifier)) {
                            questionSet.add(questionIdentifier);
                            this.questions.push(transformedIncorrectAnswer);
                        }
                    } else {
                        // console.warn(`No questionScene found for incorrectAnswer in section: ${key}`);
                    }
                });
            } else {
                // console.warn(`No incorrectAnswers array found in section: ${key}`);
            }
        });

        // Shuffle the questions using Fisher-Yates shuffle algorithm
        for (let i = this.questions.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [this.questions[i], this.questions[j]] = [this.questions[j], this.questions[i]];
        }

        // console.log('Collected incorrect questions:', this.questions);
        this.registry.set('questions', this.questions);
        this.registry.set('currentQuestionIndex', 0); // Initialize current question index
        this.questionsLoaded = true;
    }




    showReadyButton() {
        const centerX = this.cameras.main.centerX;
        const centerY = this.cameras.main.centerY;
        // Now, create the "Ready" button here as you did directly in `create`
        this.createButton(centerX, centerY, 'readyButton', null, () => {
            this.scene.start(this.sceneToLoad, { questions: this.questions });
        },2);
    }

    // Call this method to go back to the parent scene
    goBackToParentScene() {
        this.scene.start(this.topicData.key);
    }
}

export default LoadingScene;
