import { getRandomNumber, shuffleArray } from "./utility";

export const generateSymbolQuestion = (level) => {
    let numRange, operators, maxMultiplier, maxDivisor;

    // Define the range of numbers and available operators for each level
    if (level <= 6) {
        numRange = 10;
        operators = ['+', '-'];
    } else if (level <= 18) {
        numRange = 20;
        operators = ['+', '-', 'x'];
        maxMultiplier = 10;
    } else {
        numRange = 50;
        operators = ['+', '-', 'x', '÷'];
        maxMultiplier = 20;
        maxDivisor = 15;
    }

    // Generate numbers and operator
    let num1 = getRandomNumber(1, numRange);
    let num2 = getRandomNumber(1, numRange);
    let correctAnswer;
    let correctOperator;

    // Ensure operator selection is based on zero-based indexing
    const operatorIndex = getRandomNumber(0, operators.length - 1);
    const selectedOperator = operators[operatorIndex];

    switch (selectedOperator) {
        case '+':
            correctOperator = '+';
            correctAnswer = num1 + num2;
            break;
        case '-':
            correctOperator = '-';
            // Ensure num1 is greater than num2 to avoid negative results
            num1 = getRandomNumber(num2 + 1, numRange);
            correctAnswer = num1 - num2;
            break;
        case '÷':
            correctOperator = '÷';
            // Ensure division results in an integer
            num2 = getRandomNumber(1, maxDivisor);
            correctAnswer = getRandomNumber(1, Math.floor(numRange / num2));
            num1 = correctAnswer * num2;
            break;
        case 'x':
            correctOperator = 'x';
            num1 = getRandomNumber(1, maxMultiplier);
            num2 = getRandomNumber(1, maxMultiplier);
            correctAnswer = num1 * num2;
            break;
        default:
            // Fallback to addition if an unknown operator is somehow selected
            correctOperator = '+';
            correctAnswer = num1 + num2;
            break;
    }

    const question = `${num1} ? ${num2} = ${correctAnswer}`;

    return {
        question,
        answer: correctOperator,
        buttonOptions: shuffleArray(operators)
    };
};
