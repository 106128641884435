import { getRandomNumber, shuffleArray } from "./utility";

export const generateSimpleQuestion = (level) => {
    let num1, num2;
    let operator, correctAnswer;
    let questionPosition;
    let numRange, operators, maxMultiplier, maxDivisor;

    // Simplified level rules
    if (level <= 3) {
        numRange = 5;
        operators = ['+', '-'];
    } else if (level <= 6) {
        numRange = 10;
        operators = ['+', '-'];
    } else if (level <= 9) {
        numRange = 10;
        operators = ['+', '-', 'x'];
        maxMultiplier = 5;
    } else if (level <= 12) {
        numRange = 15;
        operators = ['+', '-', 'x'];
        maxMultiplier = 5;
    } else if (level <= 15) {
        numRange = 20;
        operators = ['+', '-', 'x'];
        maxMultiplier = 10;
    } else if (level <= 18) {
        numRange = 20;
        operators = ['+', '-', 'x', '÷'];
        maxMultiplier = 10;
        maxDivisor = 5;
    } else if (level <= 21) {
        numRange = 25;
        operators = ['+', '-', 'x', '÷'];
        maxMultiplier = 10;
        maxDivisor = 5;
    } else if (level <= 24) {
        numRange = 30;
        operators = ['+', '-', 'x', '÷'];
        maxMultiplier = 12;
        maxDivisor = 6;
    } else {
        numRange = 50;
        operators = ['+', '-', 'x', '÷'];
        maxMultiplier = 12;
        maxDivisor = 12;
    }

    // Randomly choose an operator from the available set
    operator = operators[Math.floor(Math.random() * operators.length)];
    num1 = getRandomNumber(1, numRange);
    num2 = getRandomNumber(1, numRange);

    switch (operator) {
        case '+':
            correctAnswer = num1 + num2;
            break;
        case '-':
            if (num1 < num2) {
                [num1, num2] = [num2, num1];
            }
            correctAnswer = num1 - num2;
            break;
        case 'x':
            num1 = getRandomNumber(1, maxMultiplier);
            num2 = getRandomNumber(1, maxMultiplier);
            correctAnswer = num1 * num2;
            break;
        case '÷':
            num2 = getRandomNumber(1, maxDivisor);
            num1 = num2 * getRandomNumber(1, maxDivisor);
            correctAnswer = num1 / num2;
            break;
    }

    let [wrongAnswer1, wrongAnswer2] = generateWrongAnswers(correctAnswer, operator, num1, num2);

    // Randomly determine the position of the question mark
    questionPosition = getRandomNumber(1, 3);

    let question;
    let answer;
    switch (questionPosition) {
        case 1:
            question = `? ${operator} ${num2} = ${correctAnswer}`;
            answer = num1;
            break;
        case 2:
            question = `${num1} ${operator} ? = ${correctAnswer}`;
            answer = num2;
            break;
        case 3:
            question = `${num1} ${operator} ${num2} = ?`;
            answer = correctAnswer;
            break;
    }

    return {
        question,
        answer: answer.toString(),
        buttonOptions: shuffleArray([answer.toString(), wrongAnswer1.toString(), wrongAnswer2.toString()])
    };
};

function generateWrongAnswers(correctAnswer, operator, num1, num2) {
    let w1, w2;

    if (operator === 'x') {
        w1 = num1 + num2;
        w2 = (num1 + 1) * num2;
        if (w1 === correctAnswer) {
            w1 += 1;
        }
        if (w2 === correctAnswer || w2 === w1) {
            w2 -= 1;
        }
    } else {
        w1 = correctAnswer + getRandomNumber(1, 3);
        w2 = correctAnswer - getRandomNumber(1, 3);
        while (w1 === correctAnswer || w1 === w2) {
            w1 = correctAnswer + getRandomNumber(1, 3);
        }
        while (w2 === correctAnswer || w2 === w1) {
            w2 = correctAnswer - getRandomNumber(1, 3);
        }
    }

    return [w1, w2];
}
