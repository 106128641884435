import { getRandomNumber, shuffleArray } from "./utility";

const wordsWithMeanings = [
  { word: 'Abode', meaning: 'A home or residence', level: 1 },
  { word: 'Abstract', meaning: 'Existing in thought but not physically', level: 1 },
  { word: 'Abundant', meaning: 'A large amount of something', level: 1 },
  { word: 'Abyss', meaning: 'A deep or bottomless chasm', level: 1 },
  { word: 'Acclaim', meaning: 'Celebrate or praise in public', level: 1 },
  { word: 'Accolade', meaning: 'An award or privilege granted as a special honor', level: 1 },
  { word: 'Accompany', meaning: 'To go with someone or something', level: 1 },
  { word: 'Achieve', meaning: 'To reach a goal', level: 1 },
  { word: 'Acquiesce', meaning: 'Agree without protest, may not want to', level: 1 },
  { word: 'Address', meaning: 'Direct communication to someone', level: 1 },
  { word: 'Adhere', meaning: 'Stick to a plan or belief', level: 1 },
  { word: 'Adjacent', meaning: 'Next to or very near by', level: 1 },
  { word: 'Admission', meaning: 'Stating that something is true', level: 1 },
  { word: 'Adopt', meaning: 'Take or follow something as your own', level: 1 },
  { word: 'Adventure', meaning: 'Fun journey', level: 1 },
  { word: 'Adventurous', meaning: 'Willing to take risks', level: 1 },
  { word: 'Adverse', meaning: 'A negative effect on an event', level: 1 },
  { word: 'Aerial', meaning: 'Existing or happening in the air', level: 1 },
  { word: 'Aggressive', meaning: 'Showing anger or acting angrily', level: 1 },
  { word: 'Agitate', meaning: 'Make someone feel uneasy', level: 1 },
  { word: 'Aid', meaning: 'Help', level: 1 },
  { word: 'Albeit', meaning: 'Although', level: 1 },
  { word: 'Allure', meaning: 'The quality of being attractive', level: 1 },
  { word: 'Alchemy', meaning: 'Medieval practice of attempting to change common metals into gold', level: 1 },
  { word: 'Amateur', meaning: 'Participating as a hobby rather than professionally', level: 1 },
  { word: 'Ambitious', meaning: 'Having a strong desire for success', level: 1 },
  { word: 'Ambrosial', meaning: 'Smelling or tasting delicious', level: 1 },
  { word: 'Amicable', meaning: 'Showing goodwill; friendly', level: 1 },
  { word: 'Ample', meaning: 'More than enough; plentiful', level: 1 },
  { word: 'Amplify', meaning: 'To make larger or more powerful', level: 1 },
  { word: 'Antiquated', meaning: 'Old and out of date', level: 1 },
  { word: 'Apathy', meaning: 'Lack of interest or emotion towards something', level: 1 },
  { word: 'Aplomb', meaning: 'Self-confidence or assurance', level: 1 },
  { word: 'Apparition', meaning: 'A ghost or ghostlike image of a person', level: 1 },
  { word: 'Appreciate', meaning: 'Understand the value of something', level: 1 },
  { word: 'Apprehensive', meaning: 'Feeling nervous or cautious about the future', level: 1 },
  { word: 'Arduous', meaning: 'Hard and tiring', level: 1 },
  { word: 'Argument', meaning: 'A disagreement with heated words', level: 1 },
  { word: 'Arid', meaning: 'Dry land with no rain', level: 1 },
  { word: 'Artful', meaning: 'Skillful or clever in achieving a goal', level: 1 },
  { word: 'Articulate', meaning: 'Able to speak clearly and coherently', level: 1 },
  { word: 'Artisan', meaning: 'A skilled craftsperson', level: 1 },
  { word: 'Ashamed', meaning: 'Embarrassed', level: 1 },
  { word: 'Assemble', meaning: 'Collect people or things together', level: 1 },
  { word: 'Astonish', meaning: 'Surprise greatly', level: 1 },
  { word: 'Asunder', meaning: 'Divide into multiple pieces', level: 1 },
  { word: 'Astute', meaning: 'Ability to notice and understand things clearly', level: 1 },
  { word: 'Attempt', meaning: 'Try something challenging', level: 1 },
  { word: 'Augur', meaning: 'Predict or foretell the future', level: 1 },
  { word: 'Austerity', meaning: 'Strict economy, reducing spending', level: 1 },
  { word: 'Aversion', meaning: 'A strong desire to avoid something', level: 1 },
  { word: 'Avoid', meaning: 'Not go near or stay away', level: 1 },
  { word: 'Avuncular', meaning: 'Kind to someone younger or newer', level: 1 },
  { word: 'Barbaric', meaning: 'Cruel and not sophisticated', level: 1 },
  { word: 'Banal', meaning: 'Very normal or boring', level: 1 },
  { word: 'Because', meaning: 'For this reason', level: 1 },
  { word: 'Beck', meaning: 'A gesture to summon or direct someone', level: 1 },
  { word: 'Becoming', meaning: 'Suitable and attractive', level: 1 },
  { word: 'Bedfellow', meaning: 'Unlikely companion or partner', level: 1 },
  { word: 'Belligerent', meaning: 'Hostile attitude, could lead to fighting', level: 1 },
  { word: 'Bemoan', meaning: 'Express sorrow or discontent', level: 1 },
  { word: 'Beneath', meaning: 'In a lower place; under; below', level: 1 },
  { word: 'Benefit', meaning: 'An advantage', level: 1 },
  { word: 'Benevolent', meaning: 'Kind and generous', level: 1 },
  { word: 'Benign', meaning: 'Harmless and gentle', level: 1 },
  { word: 'Betray', meaning: 'Not being loyal', level: 1 },
  { word: 'Bewilder', meaning: 'To confuse or puzzle completely', level: 1 },
  { word: 'Bewitch', meaning: 'To cast a spell on; to charm; to fascinate', level: 1 },
  { word: 'Biased', meaning: 'Decisions based on preference', level: 1 },
  { word: 'Bizarre', meaning: 'Very strange or unusual', level: 1 },
  { word: 'Bliss', meaning: 'Complete happiness', level: 1 },
  { word: 'Blossom', meaning: 'Flower; to grow, change, or develop', level: 1 },
  { word: 'Blunder', meaning: 'A clumsy mistake', level: 1 },
  { word: 'Boisterous', meaning: 'Noisy, energetic, and cheerful', level: 1 },
  { word: 'Bolster', meaning: 'Offer support and strengthen', level: 1 },
  { word: 'Boundless', meaning: 'Without limits; immense', level: 1 },
  { word: 'Bounteous', meaning: 'Generously given; abundant', level: 1 },
  { word: 'Bountiful', meaning: 'Giving freely, generous; plentiful', level: 1 },
  { word: 'Bravado', meaning: 'Show of courage to impress others', level: 1 },
  { word: 'Brawn', meaning: 'Physical strength', level: 1 },
  { word: 'Brave', meaning: 'Not scared', level: 1 },
  { word: 'Brevity', meaning: 'Shortness of time or duration; briefness', level: 1 },
  { word: 'Brilliant', meaning: 'Exceptionally smart or talented', level: 1 },
  { word: 'Brusque', meaning: 'Sharp speech, seems impolite', level: 1 },
  { word: 'Buoyant', meaning: 'Capable of floating; cheerful', level: 1 },
  { word: 'Burden', meaning: 'A load, especially a heavy one', level: 1 },
  { word: 'Cache', meaning: 'Place to keep items safe', level: 1 },
  { word: 'Cacophony', meaning: 'A harsh mixture of sounds', level: 1 },
  { word: 'Calamity', meaning: 'A disastrous problem', level: 1 },
  { word: 'Candid', meaning: 'An honest expression', level: 1 },
  { word: 'Candidate', meaning: 'A person that applied or nominated', level: 1 },
  { word: 'Captivate', meaning: 'To attract and hold the interest of', level: 1 },
  { word: 'Capitulate', meaning: 'To surrender', level: 1 },
  { word: 'Captivating', meaning: 'Charmingly attractive', level: 1 },
  { word: 'Career', meaning: 'Job progress over their lifetime', level: 1 },
  { word: 'Castle', meaning: 'Big stone house', level: 1 },
  { word: 'Cautious', meaning: 'Avoiding risks or mistakes', level: 1 },
  { word: 'Celestial', meaning: 'Relating to the sky or heavens', level: 1 },
  { word: 'Charismatic', meaning: 'A charming person', level: 1 },
  { word: 'Charming', meaning: 'Delightful, pleasing', level: 1 },
  { word: 'Charred', meaning: 'Burnt to charcoal', level: 1 },
  { word: 'Cherish', meaning: 'To hold dear and feel love for', level: 1 },
  { word: 'Clever', meaning: 'Very smart', level: 1 },
  { word: 'Coerce', meaning: 'Force someone to do something', level: 1 },
  { word: 'Coherent', meaning: 'Clear and logical', level: 1 },
  { word: 'Colloquial', meaning: 'Casual speech, local to an area', level: 1 },
  { word: 'Combination', meaning: 'Joining two or more things', level: 1 },
  { word: 'Comical', meaning: 'Something funny', level: 1 },
  { word: 'Commemorate', meaning: 'Remember and show respect for', level: 1 },
  { word: 'Compassionate', meaning: 'Feeling sympathy for others', level: 1 },
  { word: 'Compel', meaning: 'Force someone into action', level: 1 },
  { word: 'Complex', meaning: 'Consisting of many parts; complicated', level: 1 },
  { word: 'Compliance', meaning: 'The act of conforming to a request or command', level: 1 },
  { word: 'Comrade', meaning: 'A friend with common hobbies', level: 1 },
  { word: 'Conceited', meaning: 'Too proud of your abilities', level: 1 },
  { word: 'Conclusion', meaning: 'The end or finale', level: 1 },
  { word: 'Conclusive', meaning: 'Proving something without doubt', level: 1 },
  { word: 'Concur', meaning: 'Having the same opinion', level: 1 },
  { word: 'Condemn', meaning: 'Not approving', level: 1 },
  { word: 'Condone', meaning: 'Overlook bad or illegal behaviour', level: 1 },
  { word: 'Conducive', meaning: 'Making a situation likely or possible', level: 1 },
  { word: 'Confiscated', meaning: 'Taking something away, within rules', level: 1 },
  { word: 'Conjecture', meaning: 'An opinion formed without complete information', level: 1 },
  { word: 'Conjure', meaning: 'To bring to mind; to summon', level: 1 },
  { word: 'Consent', meaning: 'Permission from all parties', level: 1 },
  { word: 'Consequently', meaning: 'Outcome after result of something else', level: 1 },
  { word: 'Consideration', meaning: 'Thinking before doing or judging', level: 1 },
  { word: 'Conspire', meaning: 'Plot secretly for wrong or illegal action', level: 1 },
  { word: 'Consume', meaning: 'Use up', level: 1 },
  { word: 'Contagious', meaning: 'Spread easily between people', level: 1 },
  { word: 'Contemporary', meaning: 'Exists in the current period of time', level: 1 },
  { word: 'Contrast', meaning: 'Items that differ greatly', level: 1 },
  { word: 'Contrite', meaning: 'Feeling apologetic after reflection', level: 1 },
  { word: 'Conundrum', meaning: 'A difficult puzzle', level: 1 },
  { word: 'Convict', meaning: 'Person guilty of a crime', level: 1 },
  { word: 'Cordial', meaning: 'Being polite in public', level: 1 },
  { word: 'Covet', meaning: 'Strongly desire something', level: 1 },
  { word: 'Courage', meaning: 'Bravery in facing danger', level: 1 },
  { word: 'Coy', meaning: 'Shy and reserved', level: 1 },
  { word: 'Crafty', meaning: 'Skilled at tricking others', level: 1 },
  { word: 'Crass', meaning: 'Offensive with no sensitivity', level: 1 },
  { word: 'Credit', meaning: 'Appreciating the efforts of someone', level: 1 },
  { word: 'Crisp', meaning: 'Firm and brittle, in a pleasing way', level: 1 },
  { word: 'Cryptic', meaning: 'Having a hidden or ambiguous meaning', level: 1 },
  { word: 'Curious', meaning: 'Eager to know or learn something', level: 1 },
  { word: 'Cunning', meaning: 'Sly, clever at being deceitful', level: 1 },
  { word: 'Curb', meaning: 'Stop something from happening', level: 1 },
  { word: 'Curious', meaning: 'Eager to learn something new', level: 1 },
  { word: 'Curt', meaning: 'Speaking briefly but rude', level: 1 },
  { word: 'Dainty', meaning: 'Delicately small and pretty', level: 1 },
  { word: 'Daring', meaning: 'Adventurous or bold', level: 1 },
  { word: 'Dauntless', meaning: 'Showing fearlessness and determination', level: 1 },
  { word: 'Daunting', meaning: 'Intimidating or discouraging', level: 1 },
  { word: 'Dazzling', meaning: 'Shining intensely or attractive', level: 1 },
  { word: 'Debate', meaning: 'Formal discussion of opposing views', level: 1 },
  { word: 'Deceptive', meaning: 'Misleading or dishonest', level: 1 },
  { word: 'Decline', meaning: 'A downward trend', level: 1 },
  { word: 'Decipher', meaning: 'To understand something puzzling', level: 1 },
  { word: 'Definite', meaning: 'Very clearly defined', level: 1 },
  { word: 'Defy', meaning: 'Go against an order', level: 1 },
  { word: 'Deject', meaning: 'Not having much hope', level: 1 },
  { word: 'Delightful', meaning: 'Pleasing or enjoyable', level: 1 },
  { word: 'Deluge', meaning: 'An overwhelming amount, especially of water', level: 1 },
  { word: 'Demolish', meaning: 'Completely destroy', level: 1 },
  { word: 'Demure', meaning: 'Act in a shy manner', level: 1 },
  { word: 'Depict', meaning: 'Show or describe', level: 1 },
  { word: 'Desist', meaning: 'Stop an activity', level: 1 },
  { word: 'Destitute', meaning: 'Very poor, lacking food and shelter', level: 1 },
  { word: 'Destiny', meaning: 'Something that is to happen in the future', level: 1 },
  { word: 'Develop', meaning: 'Gradually build or grow', level: 1 },
  { word: 'Devise', meaning: 'Come up with a plan', level: 1 },
  { word: 'Devour', meaning: 'Eat hungrily or quickly', level: 1 },
  { word: 'Diction', meaning: 'Choice and use of words in speech or writing', level: 1 },
  { word: 'Digest', meaning: 'To break something down', level: 1 },
  { word: 'Digress', meaning: 'Stray from the main topic', level: 1 },
  { word: 'Diligent', meaning: 'Care and persistent in work or effort', level: 1 },
  { word: 'Dilute', meaning: 'Make thinner or weaker', level: 1 },
  { word: 'Dim', meaning: 'Not bright', level: 1 },
  { word: 'Diminish', meaning: 'To reduce, become less', level: 1 },
  { word: 'Discredit', meaning: 'Harm the reputation of', level: 1 },
  { word: 'Disguise', meaning: 'Change appearance to prevent recognition', level: 1 },
  { word: 'Dishevelled', meaning: 'Untidy or messy', level: 1 },
  { word: 'Disinterested', meaning: 'Unbiased; no interest', level: 1 },
  { word: 'Dismal', meaning: 'Depressing; dreary', level: 1 },
  { word: 'Disperse', meaning: 'Scatter or spread widely', level: 1 },
  { word: 'Diverge', meaning: 'Move in different directions', level: 1 },
  { word: 'Divine', meaning: 'Heavenly or Godlike', level: 1 },
  { word: 'Docile', meaning: 'Easily managed or controlled', level: 1 },
  { word: 'Dogmatic', meaning: 'Stating own opinions as facts', level: 1 },
  { word: 'Domestic', meaning: 'Relating to home', level: 1 },
  { word: 'Dominion', meaning: 'To control', level: 1 },
  { word: 'Dormant', meaning: 'Inactive', level: 1 },
  { word: 'Dose', meaning: 'A quantity or portion', level: 1 },
  { word: 'Doubtful', meaning: 'Not certain if true', level: 1 },
  { word: 'Dour', meaning: 'Stern and gloomy', level: 1 },
  { word: 'Draconian', meaning: 'Very harsh or severe', level: 1 },
  { word: 'Dread', meaning: 'Fear about something', level: 1 },
  { word: 'Dutiful', meaning: 'Obediently doing as you should', level: 1 },
  { word: 'Dwell', meaning: 'To live in a place', level: 1 },
  { word: 'Dynamic', meaning: 'Energetic and active', level: 1 },
  { word: 'Eager', meaning: 'Looking forward to with great interest', level: 1 },
  { word: 'Eccentric', meaning: 'Unconventional and slightly strange', level: 1 },
  { word: 'Egoistical', meaning: 'Self-centered and arrogant', level: 1 },
  { word: 'Elementary', meaning: 'Basic elements of a topic', level: 1 },
  { word: 'Elegant', meaning: 'Graceful and stylish in appearance or manner', level: 1 },
  { word: 'Elude', meaning: 'To avoid or escape by speed or cleverness', level: 1 },
  { word: 'Eloquent', meaning: 'Speaking clearly and fluently', level: 1 },
  { word: 'Elucidate', meaning: 'Explain clearly', level: 1 },
  { word: 'Embargo', meaning: 'Ban or prevent from happening', level: 1 },
  { word: 'Embrace', meaning: 'Hold in arms tightly', level: 1 },
  { word: 'Emerge', meaning: 'To come into view', level: 1 },
  { word: 'Empathy', meaning: "Ability to understand and share another's feelings", level: 1 },
  { word: 'Empire', meaning: 'Large organisation run by one person', level: 1 },
  { word: 'Emulate', meaning: 'Copy the behaviour of', level: 1 },
  { word: 'Enchant', meaning: 'To attract and hold the attention of; charm', level: 1 },
  { word: 'Endorse', meaning: 'Publicly approve or give support', level: 1 },
  { word: 'Endure', meaning: 'To carry on, despite hardships', level: 1 },
  { word: 'Enigmatic', meaning: 'Mysterious, puzzling', level: 1 },
  { word: 'Enterprise', meaning: 'A project or business', level: 1 },
  { word: 'Enthuse', meaning: 'Show excitement', level: 1 },
  { word: 'Entire', meaning: 'The full amount', level: 1 },
  { word: 'Epiphany', meaning: 'A sudden realisation', level: 1 },
  { word: 'Equivocate', meaning: 'Use ambiguous language to conceal the truth', level: 1 },
  { word: 'Era', meaning: 'Period of time, of one distinct character', level: 1 },
  { word: 'Eradicate', meaning: 'To destroy completely', level: 1 },
  { word: 'Erect', meaning: 'Build or construct', level: 1 },
  { word: 'Erratic', meaning: 'Unpredictable', level: 1 },
  { word: 'Especially', meaning: 'Particularly; more than usually', level: 1 },
  { word: 'Eternal', meaning: 'Lasting forever', level: 1 },
  { word: 'Etiquette', meaning: 'Following social rules', level: 1 },
  { word: 'Evoke', meaning: 'To bring to mind or recollection', level: 1 },
  { word: 'Exaggerate', meaning: 'Make better or worse than it is', level: 1 },
  { word: 'Exclaim', meaning: 'Express horror or surprise', level: 1 },
  { word: 'Exhilarate', meaning: 'To enliven, cheer, give spirit or liveliness to', level: 1 },
  { word: 'Exquisite', meaning: 'Extremely beautiful or delicate', level: 1 },
  { word: 'Fable', meaning: 'A short tale to teach a moral lesson', level: 1 },
  { word: 'Facade', meaning: 'The front of a building; a deceptive outward appearance', level: 1 },
  { word: 'Facilitate', meaning: 'Make it easier to complete action', level: 1 },
  { word: 'Fact', meaning: 'Proven to be true', level: 1 },
  { word: 'Famine', meaning: 'Extreme lack of food', level: 1 },
  { word: 'Fascinate', meaning: 'Draw irresistibly the attention and interest of', level: 1 },
  { word: 'Fanciful', meaning: 'Not based on reason; unrealistic', level: 1 },
  { word: 'Fatal', meaning: 'Causing death', level: 1 },
  { word: 'Fathom', meaning: 'Understand thoroughly', level: 1 },
  { word: 'Faux', meaning: 'A fake item, cheaper', level: 1 },
  { word: 'Feeble', meaning: 'Physically weak', level: 1 },
  { word: 'Feign', meaning: 'Pretend of fake an illness', level: 1 },
  { word: 'Fervent', meaning: 'Having or showing great warmth or intensity of spirit', level: 1 },
  { word: 'Fiasco', meaning: 'Something that fails completely', level: 1 },
  { word: 'Fierce', meaning: 'Very strong or violent', level: 1 },
  { word: 'Figment', meaning: 'Imaginary invention', level: 1 },
  { word: 'Figurative', meaning: 'Symbolic but not literal', level: 1 },
  { word: 'Formidable', meaning: 'Causing fear or dread', level: 1 },
  { word: 'Forlorn', meaning: 'Desolate or dreary; unhappy or miserable', level: 1 },
  { word: 'Forsake', meaning: 'To quit or to leave entirely', level: 1 },
  { word: 'Fortitude', meaning: 'Mental and emotional strength in difficulty', level: 1 },
  { word: 'Flamboyant', meaning: 'Attracting attention through exuberance', level: 1 },
  { word: 'Flattering', meaning: 'Giving lots of praise', level: 1 },
  { word: 'Flawed', meaning: 'An imperfection, a blemish or fault', level: 1 },
  { word: 'Flawless', meaning: 'Without any imperfections', level: 1 },
  { word: 'Flee', meaning: 'Run away or escape', level: 1 },
  { word: 'Fleet', meaning: 'Group of vehicles owned by an organisation', level: 1 },
  { word: 'Fleeting', meaning: 'Lasting for a very short time', level: 1 },
  { word: 'Flimsy', meaning: 'Weak and fragile', level: 1 },
  { word: 'Flippant', meaning: 'Not showing a serious or respectful attitude', level: 1 },
  { word: 'Flora', meaning: 'Plant life', level: 1 },
  { word: 'Flourish', meaning: 'To grow healthily thrive', level: 1 },
  { word: 'Foe', meaning: 'A competitor or opponent', level: 1 },
  { word: 'Forebear', meaning: 'Ancestor who lived in the past', level: 1 },
  { word: 'Forlorn', meaning: 'Lonely or away from loved ones', level: 1 },
  { word: 'Foundation', meaning: 'Basis on something is built upon', level: 1 },
  { word: 'Fragile', meaning: 'Easily broken or damaged', level: 1 },
  { word: 'Fragment', meaning: 'Small broken piece', level: 1 },
  { word: 'Frail', meaning: 'Delicate and easily broken', level: 1 },
  { word: 'Frantic', meaning: 'Desperate or wildly excited', level: 1 },
  { word: 'Fraud', meaning: 'Deceiving a person for gain', level: 1 },
  { word: 'Fraudulent', meaning: 'Deceptive and dishonest', level: 1 },
  { word: 'Frequent', meaning: 'Happening regularly at short intervals', level: 1 },
  { word: 'Frightful', meaning: 'Causes intense fear', level: 1 },
  { word: 'Frivolous', meaning: 'Not serious in attitude, or behavior', level: 1 },
  { word: 'Front', meaning: 'Foremost part of building or person', level: 1 },
  { word: 'Frugal', meaning: 'Careful with money', level: 1 },
  { word: 'Fulfill', meaning: 'To carry out, or bring to realization', level: 1 },
  { word: 'Fund', meaning: 'Money saved for something', level: 1 },
  { word: 'Fundamental', meaning: 'Something that is neccessary', level: 1 },
  { word: 'Galvanise', meaning: 'Stimulate into action', level: 1 },
  { word: 'Garment', meaning: 'Piece of clothing', level: 1 },
  { word: 'Gauge', meaning: 'Way of measuring an amount', level: 1 },
  { word: 'Gaze', meaning: 'Look at for a long time', level: 1 },
  { word: 'Generous', meaning: 'Giving and kind', level: 1 },
  { word: 'Gentle', meaning: 'Soft and kind', level: 1 },
  { word: 'Gigantic', meaning: 'Very big', level: 1 },
  { word: 'Gilt', meaning: 'Covered in gold', level: 1 },
  { word: 'Gleeful', meaning: 'Full of joy', level: 1 },
  { word: 'Glib', meaning: 'Smooth but insincere', level: 1 },
  { word: 'Glide', meaning: 'Slide smoothly', level: 1 },
  { word: 'Glimmer', meaning: 'A small shining light', level: 1 },
  { word: 'Glisten', meaning: 'Shine or sparkle', level: 1 },
  { word: 'Gloomy', meaning: 'Dark or sad', level: 1 },
  { word: 'Glorious', meaning: 'Wonderful and great', level: 1 },
  { word: 'Glutton', meaning: 'Excessively greedy', level: 1 },
  { word: 'Gobble', meaning: 'Eat quickly', level: 1 },
  { word: 'Graceful', meaning: 'Moving in a smooth and pretty way', level: 1 },
  { word: 'Granduer', meaning: 'Impressive in size and magnificent', level: 1 },
  { word: 'Grate', meaning: 'Breaking by rubbing together', level: 1 },
  { word: 'Greet', meaning: 'Say hello to', level: 1 },
  { word: 'Gregarious', meaning: 'Fond of company; sociable', level: 1 },
  { word: 'Grotesque', meaning: 'Comically strange and ugly', level: 1 },
  { word: 'Grumble', meaning: 'Complain quietly or sullenly', level: 1 },
  { word: 'Gung-ho', meaning: 'Extremely enthusiastic', level: 1 },
  { word: 'Gusto', meaning: 'Enjoying in a lively way', level: 1 },
  { word: 'Happy', meaning: 'Feeling pleasure or joy', level: 1 },
  { word: 'Habitat', meaning: 'A place an animal calls home', level: 1 },
  { word: 'Halt', meaning: 'To stop moving', level: 1 },
  { word: 'Hamper', meaning: 'To restrict movement', level: 1 },
  { word: 'Handsome', meaning: 'A physically attractive male', level: 1 },
  { word: 'Harass', meaning: 'Keep putting pressure on someone', level: 1 },
  { word: 'Harbor', meaning: 'A safe place for ships', level: 1 },
  { word: 'Harvest', meaning: 'The time when crops are gathered', level: 1 },
  { word: 'Hatch', meaning: 'To come out of an egg', level: 1 },
  { word: 'Haunt', meaning: 'A place often visited', level: 1 },
  { word: 'Heed', meaning: 'To pay attention to', level: 1 },
  { word: 'Herald', meaning: 'To announce or signal', level: 1 },
  { word: 'Hero', meaning: 'Saves the day', level: 1 },
  { word: 'Helter-Skelter', meaning: 'Unorganised or rushed', level: 1 },
  { word: 'Heroic', meaning: 'Brave and determined', level: 1 },
  { word: 'Hesitate', meaning: 'To stop briefly before you do something', level: 1 },
  { word: 'Hierachical', meaning: 'Arranged in order of importance', level: 1 },
  { word: 'Hierachy', meaning: 'System to organise by rank', level: 1 },
  { word: 'Hinder', meaning: 'To slow down or stop progress', level: 1 },
  { word: 'Hindsight', meaning: 'Understanding something that has happened', level: 1 },
  { word: 'Hoax', meaning: 'A prank', level: 1 },
  { word: 'Holiday', meaning: 'A break from the usual routine', level: 1 },
  { word: 'Holler', meaning: 'Shout or call loudly', level: 1 },
  { word: 'Honor', meaning: 'To show respect for', level: 1 },
  { word: 'Hope', meaning: 'Wishing for something to happen', level: 1 },
  { word: 'Huge', meaning: 'Very big', level: 1 },
  { word: 'Humane', meaning: 'Having compassion and kindness', level: 1 },
  { word: 'Humble', meaning: 'Not thinking you are better than others', level: 1 },
  { word: 'Hurry', meaning: 'To move fast', level: 1 },
  { word: 'Hypothetical', meaning: 'Based on assumption, not evidence', level: 1 },
  { word: 'Idle', meaning: 'Inactive, not used', level: 1 },
  { word: 'Idol', meaning: 'Someone admired and respected', level: 1 },
  { word: 'Identity', meaning: 'Facts to describe a person', level: 1 },
  { word: 'Idyllic', meaning: 'Beautiful and peaceful', level: 1 },
  { word: 'Illuminate', meaning: 'Light up; clarify', level: 1 },
  { word: 'Imagine', meaning: 'Make pictures in your mind', level: 1 },
  { word: 'Immaculate', meaning: 'Perfect, flawless', level: 1 },
  { word: 'Immerse', meaning: 'Submerge in liquid; deeply involve', level: 1 },
  { word: 'Impassive', meaning: 'Not showing or feeling emotions', level: 1 },
  { word: 'Impart', meaning: 'Give information or knowledge', level: 1 },
  { word: 'Impede', meaning: 'Hinder or obstruct', level: 1 },
  { word: 'Imperative', meaning: 'Absolutely necessary or required', level: 1 },
  { word: 'Inadequate', meaning: 'Not sufficient or suitable', level: 1 },
  { word: 'Incision', meaning: 'A cut by a sharp tool', level: 1 },
  { word: 'Incline', meaning: 'Slope or lean', level: 1 },
  { word: 'Inconsequential', meaning: 'Of little significance', level: 1 },
  { word: 'Incredible', meaning: 'Hard to believe', level: 1 },
  { word: 'Incumbent', meaning: 'Person with an official post', level: 1 },
  { word: 'Inequity', meaning: 'Something unfair or biased', level: 1 },
  { word: 'Inert', meaning: 'Lacking the ability to move', level: 1 },
  { word: 'Infantile', meaning: 'Characteristic of a baby; childish', level: 1 },
  { word: 'Inferior', meaning: 'Lower status or quality', level: 1 },
  { word: 'Inhabit', meaning: 'Live in a place', level: 1 },
  { word: 'Inhale', meaning: 'Breathe in', level: 1 },
  { word: 'Inherit', meaning: 'Get from someone when they are gone', level: 1 },
  { word: 'Innocuous', meaning: 'Unlikely to upset anyone', level: 1 },
  { word: 'Inquire', meaning: 'Ask to learn more', level: 1 },
  { word: 'Inquisitive', meaning: 'Eager to know or learn', level: 1 },
  { word: 'Insane', meaning: 'Not sound state of mind', level: 1 },
  { word: 'Insinuate', meaning: 'Hint or suggest indirectly', level: 1 },
  { word: 'Insist', meaning: 'Say firmly', level: 1 },
  { word: 'Inspire', meaning: 'Make someone want to do something', level: 1 },
  { word: 'Intact', meaning: 'Not broken', level: 1 },
  { word: 'Intangible', meaning: 'Not able to describe or abstract', level: 1 },
  { word: 'Interfere', meaning: 'Get involved inappropriately', level: 1 },
  { word: 'Interrupt', meaning: 'To cause a break in an activity', level: 1 },
  { word: 'Intrepid', meaning: 'Very brave', level: 1 },
  { word: 'Inundate', meaning: 'To overwhlem with a large amount', level: 1 },
  { word: 'Invent', meaning: 'Make something new', level: 1 },
  { word: 'Invisible', meaning: 'Cannot be seen', level: 1 },
  { word: 'Invite', meaning: 'Ask someone to come', level: 1 },
  { word: 'Ironic', meaning: 'Funny because opposite of what is expected', level: 1 },
  { word: 'Isolation', meaning: 'Cut off from others and alone', level: 1 },
  { word: 'Jargon', meaning: 'Special words used by a group of people', level: 1 },
  { word: 'Jade', meaning: 'A green stone used for ornaments', level: 1 },
  { word: 'Jazz', meaning: 'A type of music with a strong rhythm', level: 1 },
  { word: 'Jealous', meaning: 'Wanting what someone else has', level: 1 },
  { word: 'Jeopardy', meaning: 'Danger of harm or loss', level: 1 },
  { word: 'Joyful', meaning: 'Very happy', level: 1 },
  { word: 'Journey', meaning: 'A long trip', level: 1 },
  { word: 'Jovial', meaning: 'Cheerful and friendly', level: 1 },
  { word: 'Judge', meaning: 'Decide if something is right or wrong', level: 1 },
  { word: 'Juicy', meaning: 'Full of juice; tasty and refreshing', level: 1 },
  { word: 'Jumble', meaning: 'A mixed-up mess', level: 1 },
  { word: 'Jolt', meaning: 'A sudden sharp shake', level: 1 },
  { word: 'Jest', meaning: 'A thing said or done for amusement; a joke', level: 1 },
  { word: 'Jubilant', meaning: 'Feeling or showing great happiness', level: 1 },
  { word: 'Juriprudence', meaning: 'Studying the law', level: 1 },
  { word: 'Juxtapose', meaning: 'Place side by side for contrast', level: 1 },
  { word: 'Keel', meaning: 'To collapse', level: 1 },
  { word: 'Keen', meaning: 'Really wanting to do something', level: 1 },
  { word: 'Keepsake', meaning: 'Something kept to remember', level: 1 },
  { word: 'Kettle', meaning: 'A pot for boiling water', level: 1 },
  { word: 'Kite', meaning: 'A toy that flies in the air', level: 1 },
  { word: 'Kin', meaning: 'Family', level: 1 },
  { word: 'Kind', meaning: 'Considerate and helpful', level: 1 },
  { word: 'Kingdom', meaning: 'A big area ruled by a king or queen', level: 1 },
  { word: 'Knowledge', meaning: 'What you know', level: 1 },
  { word: 'Knack', meaning: 'A special skill or ability', level: 1 },
  { word: 'Kneel', meaning: 'To go down on your knees', level: 1 },
  { word: 'Knight', meaning: 'A soldier in the old times', level: 1 },
  { word: 'Knit', meaning: 'Make clothes with wool', level: 1 },
  { word: 'Knock', meaning: 'Hit a door to ask to come in', level: 1 },
  { word: 'Krill', meaning: 'Small sea animals that bigger ones eat', level: 1 },
  { word: 'Kudos', meaning: 'Praise for doing well', level: 1 },
  { word: 'Labour', meaning: 'Hard and physical work', level: 1 },
  { word: 'Labyrinth', meaning: 'A confusing maze', level: 1 },
  { word: 'Lackadaisical', meaning: 'Not enthusiastic', level: 1 },
  { word: 'Lair', meaning: 'Secluded place when a wild beast lives', level: 1 },
  { word: 'Lament', meaning: 'Showing sorrow or grief', level: 1 },
  { word: 'Languorous', meaning: 'Lacking energy', level: 1 },
  { word: 'Latter', meaning: 'Closer to the end than start', level: 1 },
  { word: 'Lavish', meaning: 'Very fancy and costly', level: 1 },
  { word: 'Laugh', meaning: 'Make happy sounds when amused', level: 1 },
  { word: 'Lazy', meaning: 'Don’t like work', level: 1 },
  { word: 'Legend', meaning: 'A story from old times', level: 1 },
  { word: 'Lend', meaning: 'Give for a short time', level: 1 },
  { word: 'Lethargic', meaning: 'Sluggish and apathetic', level: 1 },
  { word: 'Leverage', meaning: 'Use something to get a better result', level: 1 },
  { word: 'Liable', meaning: 'Having legal responsibilty', level: 1 },
  { word: 'Libel', meaning: 'False writing on someone', level: 1 },
  { word: 'Liberate', meaning: 'Being free', level: 1 },
  { word: 'Liberty', meaning: 'Acting how you want', level: 1 },
  { word: 'Lieu', meaning: 'In place of something else', level: 1 },
  { word: 'Limb', meaning: 'Leg or arm', level: 1 },
  { word: 'Linger', meaning: 'Hang around for a while', level: 1 },
  { word: 'Litigation', meaning: 'Settling an argument by taking to court', level: 1 },
  { word: 'Lively', meaning: 'Full of life and energy', level: 1 },
  { word: 'Leap', meaning: 'Jump high or far', level: 1 },
  { word: 'Lexis', meaning: 'Words to make a language', level: 1 },
  { word: 'Lodge', meaning: 'Small temporary place you stay', level: 1 },
  { word: 'Lofty', meaning: 'High up and tall', level: 1 },
  { word: 'Lonely', meaning: 'Feeling alone', level: 1 },
  { word: 'Louche', meaning: 'Not very moral or reliable', level: 1 },
  { word: 'Loyal', meaning: 'Supporting in all circumstances', level: 1 },
  { word: 'Lullaby', meaning: 'A soft song to make you sleep', level: 1 },
  { word: 'Luminous', meaning: 'Emitting or reflecting light', level: 1 },
  { word: 'Luminance', meaning: 'The quality of being bright', level: 1 },
  { word: 'Lunar', meaning: 'Relating to the moon', level: 1 },
  { word: 'Lush', meaning: 'Very green and healthy', level: 1 },
  { word: 'Luxury', meaning: 'Very nice and expensive', level: 1 },
  { word: 'Magic', meaning: 'Spells and wands', level: 1 },
  { word: 'Magnanimous', meaning: 'Kind towards an enemy', level: 1 },
  { word: 'Magnetic', meaning: 'Strongly attracts something else', level: 1 },
  { word: 'Majestic', meaning: 'Grand and impressive', level: 1 },
  { word: 'Magnificent', meaning: 'Grand or impressive', level: 1 },
  { word: 'Malefactor', meaning: 'A person breaking the law', level: 1 },
  { word: 'Malevolent', meaning: 'Cruel or evil', level: 1 },
  { word: 'Malinger', meaning: 'Avoiding work, pretending to be ill', level: 1 },
  { word: 'Marvel', meaning: 'Wonder or amazement', level: 1 },
  { word: 'Marvellous', meaning: 'Really great', level: 1 },
  { word: 'Meadow', meaning: 'A field with grass and flowers', level: 1 },
  { word: 'Meagre', meaning: 'A small amount', level: 1 },
  { word: 'Meander', meaning: 'Wander aimlessly', level: 1 },
  { word: 'Meant', meaning: 'Expressing an intention a person had', level: 1 },
  { word: 'Measly', meaning: 'A small amount', level: 1 },
  { word: 'Meek', meaning: 'Gentle and soft natured, will follow others', level: 1 },
  { word: 'Mellows', meaning: 'Become soft or gentle', level: 1 },
  { word: 'Melodious', meaning: 'Pleasant-sounding', level: 1 },
  { word: 'Memento', meaning: 'Something kept as a reminder', level: 1 },
  { word: 'Mercy', meaning: 'Showing compassion', level: 1 },
  { word: 'Mere', meaning: 'Showing how small or simple it is', level: 1 },
  { word: 'Merry', meaning: 'Happy and cheerful', level: 1 },
  { word: 'Meticulous', meaning: 'Showing great attention to detail', level: 1 },
  { word: 'Migrate', meaning: 'Move from one place to another', level: 1 },
  { word: 'Mirth', meaning: 'Happiness and laughter', level: 1 },
  { word: 'Mirthful', meaning: 'Full of joy', level: 1 },
  { word: 'Mingle', meaning: 'Mix with others', level: 1 },
  { word: 'Mischievous', meaning: 'Playful in a naughty way', level: 1 },
  { word: 'Mock', meaning: 'Tease and laugh at someone', level: 1 },
  { word: 'Monochrome', meaning: 'One colour or shades of one colour', level: 1 },
  { word: 'Monster', meaning: 'Scary creature', level: 1 },
  { word: 'Moral', meaning: 'Your standards of right or wrong', level: 1 },
  { word: 'Mortal', meaning: 'Will not live forever', level: 1 },
  { word: 'Mystery', meaning: 'Something difficult or impossible to understand', level: 1 },
  { word: 'Mysterious', meaning: 'Hard to understand or explain', level: 1 },
  { word: 'Murmur', meaning: 'Speak softly', level: 1 },
  { word: 'Muse', meaning: 'Inspiration for creating art', level: 1 },
  { word: 'Muster', meaning: 'Gather or collect', level: 1 },
  { word: 'Myriad', meaning: 'A high quantity', level: 1 },
  { word: 'Nap', meaning: 'A short sleep', level: 1 },
  { word: 'Narrate', meaning: 'Tell a story', level: 1 },
  { word: 'Navigate', meaning: 'Find the way to go', level: 1 },
  { word: 'Nectar', meaning: 'Sweet liquid from flowers', level: 1 },
  { word: 'Neat', meaning: 'Ordered and pleasing to the eye', level: 1 },
  { word: 'Necessary', meaning: 'An essential requirement', level: 1 },
  { word: 'Nemesis', meaning: 'Unconquerable opponent', level: 1 },
  { word: 'Nest', meaning: 'A home for birds', level: 1 },
  { word: 'Neutral', meaning: 'Not taking sides', level: 1 },
  { word: 'Nifty', meaning: 'Very good, clever, or useful', level: 1 },
  { word: 'Nimble', meaning: 'Quick and light in movement or action', level: 1 },
  { word: 'Nirvana', meaning: 'A place of peace', level: 1 },
  { word: 'Noble', meaning: 'Having high moral qualities', level: 1 },
  { word: 'Noisy', meaning: 'Very loud', level: 1 },
  { word: 'Nomad', meaning: 'Person who moves from place to place', level: 1 },
  { word: 'Nominal', meaning: 'A small cost', level: 1 },
  { word: 'Nourish', meaning: 'To feed and help grow', level: 1 },
  { word: 'Novice', meaning: 'New to an activiy', level: 1 },
  { word: 'Nudge', meaning: 'A gentle push', level: 1 },
  { word: 'Nuzzle', meaning: 'Rub gently with the nose', level: 1 },
  { word: 'Oasis', meaning: 'Green area in a desert', level: 1 },
  { word: 'Oath', meaning: 'A promise to tell the truth', level: 1 },
  { word: 'Obedient', meaning: 'Doing what you’re told', level: 1 },
  { word: 'Objection', meaning: 'Disagreeing on an opinion', level: 1 },
  { word: 'Oblige', meaning: 'Do a favor', level: 1 },
  { word: 'Oblique', meaning: 'On a slant or angle', level: 1 },
  { word: 'Oblivious', meaning: 'Unaware or not concerned about what is happening around one', level: 1 },
  { word: 'Observe', meaning: 'Look and see', level: 1 },
  { word: 'Obstacle', meaning: 'Something in the way', level: 1 },
  { word: 'Obtuse', meaning: 'Not alert with understanding', level: 1 },
  { word: 'Obviate', meaning: 'Prevent or eliminate a challenge', level: 1 },
  { word: 'Ogle', meaning: 'Look at in a strong way', level: 1 },
  { word: 'Optimistic', meaning: 'Hopeful and positive', level: 1 },
  { word: 'Oppress', meaning: 'Making someone feel uncomfortable', level: 1 },
  { word: 'Opulent', meaning: 'Very rich and luxurious', level: 1 },
  { word: 'Omit', meaning: 'Leave out', level: 1 },
  { word: 'Orbit', meaning: 'Path of a planet or moon', level: 1 },
  { word: 'Ostentatious', meaning: 'Using wealth to impress', level: 1 },
  { word: 'Outrageous', meaning: 'Shocking, very bold', level: 1 },
  { word: 'Overcome', meaning: 'Win against a problem', level: 1 },
  { word: 'Overflow', meaning: 'Too much of something', level: 1 },
  { word: 'Overjoyed', meaning: 'Very happy', level: 1 },
  { word: 'Overt', meaning: 'Open and obvious', level: 1 },
  { word: 'Overture', meaning: 'Music played at the beginning', level: 1 },
  { word: 'Palpable', meaning: 'Easily perceived or felt', level: 1 },
  { word: 'Palpitate', meaning: 'Rapidly beat or tremble', level: 1 },
  { word: 'Pandemonium', meaning: 'Noisy situation with no order', level: 1 },
  { word: 'Pander', meaning: 'Act sensitively and give what they want', level: 1 },
  { word: 'Pamper', meaning: 'Treat with care and attention', level: 1 },
  { word: 'Paradox', meaning: 'Seems true not not possible', level: 1 },
  { word: 'Parallel', meaning: 'Two things alongside staying the same distance', level: 1 },
  { word: 'Paraphrase', meaning: 'Reword text but shorten', level: 1 },
  { word: 'Parody', meaning: 'Imitating in a funny way', level: 1 },
  { word: 'Parsimonious', meaning: 'Strict with money', level: 1 },
  { word: 'Peculiar', meaning: 'Strange or odd', level: 1 },
  { word: 'Peek', meaning: 'Quickly look at something', level: 1 },
  { word: 'Pensive', meaning: 'Thinking carefully about something', level: 1 },
  { word: 'Perambulate', meaning: 'To walk very casually', level: 1 },
  { word: 'Perjury', meaning: 'To lie under oath in court', level: 1 },
  { word: 'Perish', meaning: 'To stop existing', level: 1 },
  { word: 'Perplexed', meaning: 'Confused and puzzled', level: 1 },
  { word: 'Persist', meaning: 'Keep trying', level: 1 },
  { word: 'Persona', meaning: 'Public image or character', level: 1 },
  { word: 'Pestilence', meaning: 'Serious epidemic that kills and spreads', level: 1 },
  { word: 'Petal', meaning: 'The colored parts of a flower', level: 1 },
  { word: 'Petty', meaning: 'Not very important', level: 1 },
  { word: 'Petulant', meaning: 'Acting childish', level: 1 },
  { word: 'Picturesque', meaning: 'Beautiful, looks like art', level: 1 },
  { word: 'Pierce', meaning: 'Make a hole with a sharp point', level: 1 },
  { word: 'Pioneer', meaning: 'First to do something', level: 1 },
  { word: 'Pivotal', meaning: 'Critical point which changes the outcome', level: 1 },
  { word: 'Placid', meaning: 'Calm, not easily upset', level: 1 },
  { word: 'Platitude', meaning: 'Happens too much to be exciting', level: 1 },
  { word: 'Playful', meaning: 'Full of fun and play', level: 1 },
  { word: 'Pleasing', meaning: 'Something agreeable', level: 1 },
  { word: 'Pledge', meaning: 'A promise to do something', level: 1 },
  { word: 'Pleasant', meaning: 'Nice and enjoyable', level: 1 },
  { word: 'Plight', meaning: 'A bad situation', level: 1 },
  { word: 'Plunge', meaning: 'Jump or dive into water', level: 1 },
  { word: 'Pompous', meaning: 'Showing self importance over others', level: 1 },
  { word: 'Ponder', meaning: 'Think deeply', level: 1 },
  { word: 'Porous', meaning: 'Has small holes liquid can pass through', level: 1 },
  { word: 'Posit', meaning: 'Present information as a fact', level: 1 },
  { word: 'Pragmatic', meaning: 'A practical and realistic approach', level: 1 },
  { word: 'Prattle', meaning: 'Talk at length foolishly', level: 1 },
  { word: 'Precious', meaning: 'Very valuable', level: 1 },
  { word: 'Precocious', meaning: 'Exceptionally early development', level: 1 },
  { word: 'Predator', meaning: 'Animal that kils other for survival', level: 1 },
  { word: 'Prejudice', meaning: 'Already having an opinion or bias', level: 1 },
  { word: 'Presumptuous', meaning: 'Overly bold or forward', level: 1 },
  { word: 'Pretence', meaning: 'A false appearance to trick people', level: 1 },
  { word: 'Procure', meaning: 'To get something', level: 1 },
  { word: 'Profess', meaning: 'Declare something as true', level: 1 },
  { word: 'Profit', meaning: 'Money gained after costs', level: 1 },
  { word: 'Proportion', meaning: 'Part of a whole', level: 1 },
  { word: 'Proud', meaning: 'Feeling good about something done', level: 1 },
  { word: 'Proverb', meaning: 'Short story of advice', level: 1 },
  { word: 'Provoke', meaning: 'Try to make angry', level: 1 },
  { word: 'Prudent', meaning: 'Wise and careful', level: 1 },
  { word: 'Public', meaning: 'Not private', level: 1 },
  { word: 'Puncture', meaning: 'Small hole made by sharp point', level: 1 },
  { word: 'Puzzle', meaning: 'A problem that tests skill or cleverness', level: 1 },
  { word: 'Quick', meaning: 'Fast', level: 1 },
  { word: 'Quiet', meaning: 'Not loud', level: 1 },
  { word: 'Quaint', meaning: 'Charmingly old-fashioned', level: 1 },
  { word: 'Quest', meaning: 'A long search for something', level: 1 },
  { word: 'Quizzical', meaning: 'Showing that you are confused', level: 1 },
  { word: 'Quench', meaning: 'To satisfy thirst', level: 1 },
  { word: 'Quintessential', meaning: 'Perfect example of something', level: 1 },
  { word: 'Quiver', meaning: 'Shake with a slight but rapid motion', level: 1 },
  { word: 'Queue', meaning: 'A line of people waiting for something', level: 1 },
  { word: 'Quill', meaning: 'A pen made from a bird’s feather', level: 1 },
  { word: 'Quarrel', meaning: 'An angry argument', level: 1 },
  { word: 'Quota', meaning: 'A part or share of something', level: 1 },
  { word: 'Quench', meaning: 'To stop feeling thirsty', level: 1 },
  { word: 'Quirk', meaning: 'A strange habit', level: 1 },
  { word: 'Quash', meaning: 'To stop or crush', level: 1 },
  { word: 'Quilt', meaning: 'A warm bed cover', level: 1 },
  { word: 'Radiate', meaning: 'Send out light or heat', level: 1 },
  { word: 'Ramble', meaning: 'Walk for pleasure', level: 1 },
  { word: 'Ramification', meaning: 'The result of an event', level: 1 },
  { word: 'Ransack', meaning: 'Stealing and leaving a mess', level: 1 },
  { word: 'Rapid', meaning: 'Fast', level: 1 },
  { word: 'Rare', meaning: 'Extremely uncommon', level: 1 },
  { word: 'Ratify', meaning: 'Formally approve or confirm', level: 1 },
  { word: 'Ravenous', meaning: 'Extremely hungry', level: 1 },
  { word: 'Ravine', meaning: 'A deep narrow valley', level: 1 },
  { word: 'Recite', meaning: 'Speaking from memory', level: 1 },
  { word: 'Reel', meaning: 'Cylinder for storing string', level: 1 },
  { word: 'Refuse', meaning: 'Denying a request', level: 1 },
  { word: 'Regurgitate', meaning: 'Repeating information given to you', level: 1 },
  { word: 'Rejoice', meaning: 'Feel great joy', level: 1 },
  { word: 'Relish', meaning: 'Enjoy the experience', level: 1 },
  { word: 'Remnant', meaning: 'Small leftover part', level: 1 },
  { word: 'Rendezvous', meaning: 'Meeting at agreed time and place', level: 1 },
  { word: 'Repel', meaning: 'Reject and back away', level: 1 },
  { word: 'Repent', meaning: 'Sorry and apologetic', level: 1 },
  { word: 'Reproach', meaning: 'Express disapproval or criticism', level: 1 },
  { word: 'Rescue', meaning: 'Save from danger', level: 1 },
  { word: 'Reveal', meaning: 'Show something hidden', level: 1 },
  { word: 'Revel', meaning: 'Enjoy oneself', level: 1 },
  { word: 'Rigmarole', meaning: 'Complicated procedure', level: 1 },
  { word: 'Ripple', meaning: 'Small wave on water', level: 1 },
  { word: 'Rhyme', meaning: 'Words endings sound similar', level: 1 },
  { word: 'Roam', meaning: 'Wander aimlessly', level: 1 },
  { word: 'Rogue', meaning: 'A dishonest or unprincipled person', level: 1 },
  { word: 'Rowdy', meaning: 'Noisy and disorderly', level: 1 },
  { word: 'Rubric', meaning: 'Guiding instructions', level: 1 },
  { word: 'Rural', meaning: 'Countryside or farming areas', level: 1 },
  { word: 'Rumble', meaning: 'A long, deep sound', level: 1 },
  { word: 'Rustic', meaning: 'Simple, country style', level: 1 },
  { word: 'Relish', meaning: 'Enjoy greatly', level: 1 },
  { word: 'Reverie', meaning: 'Daydream', level: 1 },
  { word: 'Sacred', meaning: 'Respected holy object', level: 1 },
  { word: 'Sarcasm', meaning: 'Using irony to mock or convey contempt', level: 1 },
  { word: 'Sarcastic', meaning: 'Mocking, ironic, or contemptuous', level: 1 },
  { word: 'Savor', meaning: 'Enjoy a taste or smell', level: 1 },
  { word: 'Scenic', meaning: 'Natural beauty of environments', level: 1 },
  { word: 'Score', meaning: 'Points in a game', level: 1 },
  { word: 'Sculpt', meaning: 'Make shapes from solid materials', level: 1 },
  { word: 'Scuttle', meaning: 'Run hurriedly', level: 1 },
  { word: 'Seize', meaning: 'Take opportunity by force', level: 1 },
  { word: 'Self-effacing', meaning: 'Not claiming attention for oneself', level: 1 },
  { word: 'Semantics', meaning: 'Meaning of words and phrases', level: 1 },
  { word: 'Seraph', meaning: 'Angel held in high regard', level: 1 },
  { word: 'Serene', meaning: 'Calm and peaceful', level: 1 },
  { word: 'Sermon', meaning: 'Religious speech', level: 1 },
  { word: 'Shambolic', meaning: 'Disasterous with no order', level: 1 },
  { word: 'Shenanigans', meaning: 'Michevious behavour', level: 1 },
  { word: 'Shimmer', meaning: 'Shine with a flickering light', level: 1 },
  { word: 'Shrine', meaning: 'Holy or sacred place', level: 1 },
  { word: 'Shrink', meaning: 'Get smaller in size', level: 1 },
  { word: 'Significant', meaning: 'Important enough to notice', level: 1 },
  { word: 'Simile', meaning: "Using 'like' and 'as' to compare", level: 1 },
  { word: 'Sincere', meaning: 'Honest and genuine', level: 1 },
  { word: 'Sincere', meaning: 'Honest and genuine', level: 1 },
  { word: 'Slacken', meaning: 'To loosen', level: 1 },
  { word: 'Slander', meaning: 'Speak negatively and damage reputation', level: 1 },
  { word: 'Slang', meaning: 'Informal words from an area', level: 1 },
  { word: 'Sleek', meaning: 'Smooth and stylish', level: 1 },
  { word: 'Sly', meaning: 'Deceptive or cunning', level: 1 },
  { word: 'Soar', meaning: 'Fly high', level: 1 },
  { word: 'Solitude', meaning: 'Lonely and isolated', level: 1 },
  { word: 'Sombre', meaning: 'Deep sadness', level: 1 },
  { word: 'Sonnet', meaning: '14 line poetic form', level: 1 },
  { word: 'Sow', meaning: 'Plant seeds', level: 1 },
  { word: 'Sparkle', meaning: 'Shine brightly', level: 1 },
  { word: 'Special', meaning: 'Better than normal', level: 1 },
  { word: 'Spiteful', meaning: 'Being nasty, to hurt or upset someone', level: 1 },
  { word: 'Sprightly', meaning: 'Full of energy', level: 1 },
  { word: 'Sprout', meaning: 'Begin to grow', level: 1 },
  { word: 'Squabble', meaning: 'A small argument', level: 1 },
  { word: 'Squalor', meaning: 'Dirty unpleasant living conditions', level: 1 },
  { word: 'Stagnant', meaning: 'Pool of water not flowing', level: 1 },
  { word: 'Stance', meaning: 'Position or view on something', level: 1 },
  { word: 'Stanza', meaning: 'Group of lines in poem', level: 1 },
  { word: 'Static', meaning: 'Not moving', level: 1 },
  { word: 'Status quo', meaning: 'Current way things are', level: 1 },
  { word: 'Stoical', meaning: 'Never showing feelings even in pain', level: 1 },
  { word: 'Stoop', meaning: 'Bend over or slouched head', level: 1 },
  { word: 'Stout', meaning: 'Thick and very strong', level: 1 },
  { word: 'Stretched', meaning: "Pulled to it's limit", level: 1 },
  { word: 'Stubborn', meaning: 'Unwilling to change their way', level: 1 },
  { word: 'Stroll', meaning: 'Walk slowly for pleasure', level: 1 },
  { word: 'Sturdy', meaning: 'Strong and solid', level: 1 },
  { word: 'Subterranean', meaning: 'Underground', level: 1 },
  { word: 'Succinct', meaning: 'Straight to the point', level: 1 },
  { word: 'Succumb', meaning: 'To give in', level: 1 },
  { word: 'Sufficient', meaning: 'Good enough', level: 1 },
  { word: 'Summit', meaning: 'The top or highest point', level: 1 },
  { word: 'Supercilious', meaning: 'Arrogant and feeling above others', level: 1 },
  { word: 'Superfluous', meaning: 'A lot more than required', level: 1 },
  { word: 'Superior', meaning: 'Better quality or status', level: 1 },
  { word: 'Surmount', meaning: 'Overcome a challenge', level: 1 },
  { word: 'Suspect', meaning: 'Someone believed to have committed a crime', level: 1 },
  { word: 'Susurration', meaning: 'Whispering or rustling sound', level: 1 },
  { word: 'Sycophant', meaning: 'Flatterer or yes-man to gain something', level: 1 },
  { word: 'Swift', meaning: 'Moving quickly', level: 1 },
  { word: 'Symbol', meaning: 'Mark or sign to represent something else', level: 1 },
  { word: 'Symmmetry', meaning: 'Balanced and proportionate, a mirror image', level: 1 },
  { word: 'Tactile', meaning: 'Related to the sense of touch', level: 1 },
  { word: 'Taint', meaning: 'Contaminate or spoil', level: 1 },
  { word: 'Tangle', meaning: 'Twisted together', level: 1 },
  { word: 'Tantalise', meaning: 'Tease with the promise of something unobtainable', level: 1 },
  { word: 'Tardy', meaning: 'Late', level: 1 },
  { word: 'Taut', meaning: 'Pulled tight', level: 1 },
  { word: 'Temptation', meaning: 'Desire for something you should not have', level: 1 },
  { word: 'Tentative', meaning: 'Hesitant or unsure', level: 1 },
  { word: 'Tender', meaning: 'Soft or gentle', level: 1 },
  { word: 'Timid', meaning: 'Shy and lacking in confidenc', level: 1 },
  { word: 'Tinker', meaning: 'Try to fix or make things', level: 1 },
  { word: 'Thrive', meaning: 'Grow vigorously', level: 1 },
  { word: 'Thrust', meaning: 'Force forward', level: 1 },
  { word: 'Tiptoe', meaning: 'Walk quietly on your toes', level: 1 },
  { word: 'Tomorrow', meaning: 'Follows current day', level: 1 },
  { word: 'Tranquil', meaning: 'Calm and peaceful', level: 1 },
  { word: 'Transcendent', meaning: 'Exceeds what was thought possible', level: 1 },
  { word: 'Triumphant', meaning: 'Feeling or showing victory', level: 1 },
  { word: 'Trivia', meaning: 'Small bits of information, seems not important', level: 1 },
  { word: 'Treasure', meaning: 'Valuable things', level: 1 },
  { word: 'Trudge', meaning: 'Walk slowly with heavy steps', level: 1 },
  { word: 'Tumble', meaning: 'Fall down suddenly', level: 1 },
  { word: 'Turbulent', meaning: 'Full of confusion or wild disorder', level: 1 },
  { word: 'Twilight', meaning: 'Just before it gets dark at night', level: 1 },
  { word: 'Twinkle', meaning: 'Shine with a flickering light', level: 1 },
  { word: 'Twirl', meaning: 'Spin around quickly', level: 1 },
  { word: 'Tyrant', meaning: 'Ruler leads in own cruel way', level: 1 },
  { word: 'Ubiquitous', meaning: 'Found everywhere', level: 1 },
  { word: 'Uncaring', meaning: 'Lack of concern', level: 1 },
  { word: 'Uniform', meaning: 'Regular and remains the same', level: 1 },
  { word: 'Union', meaning: 'Joining together to make one', level: 1 },
  { word: 'Unique', meaning: 'One of a kind', level: 1 },
  { word: 'Unite', meaning: 'Come together', level: 1 },
  { word: 'Universal', meaning: 'Applies to everyone', level: 1 },
  { word: 'Uncover', meaning: 'Reveal or show', level: 1 },
  { word: 'Undulate', meaning: 'Move in waves', level: 1 },
  { word: 'Unorthodox', meaning: 'Different to tradition ways', level: 1 },
  { word: 'Unrequited', meaning: 'Not returned or reciprocated', level: 1 },
  { word: 'Unfold', meaning: 'Open or spread out', level: 1 },
  { word: 'Unscathed', meaning: 'Not hurt or harmed', level: 1 },
  { word: 'Untoward', meaning: 'Unexpected and unfortunate', level: 1 },
  { word: 'Unveil', meaning: 'Reveal for the first time', level: 1 },
  { word: 'Uplift', meaning: 'Raise up; make happy', level: 1 },
  { word: 'Uproar', meaning: 'A lot of noise or excitement', level: 1 },
  { word: 'Urgent', meaning: 'Very important and needs quick action', level: 1 },
  { word: 'Useful', meaning: 'Helpful for a purpose', level: 1 },
  { word: 'Usher', meaning: 'Show the way', level: 1 },
  { word: 'Utter', meaning: 'Speak words', level: 1 },
  { word: 'Uptight', meaning: 'Worried or tense', level: 1 },
  { word: 'Valiant', meaning: 'Brave and noble', level: 1 },
  { word: 'Vanish', meaning: 'Disappear suddenly', level: 1 },
  { word: 'Vapour', meaning: 'Small drops like a cloud', level: 1 },
  { word: 'Varnish', meaning: 'Liquid that provides a shiny surface when dry', level: 1 },
  { word: 'Vast', meaning: 'Enormous', level: 1 },
  { word: 'Vaticinate', meaning: 'Predict or prophesy', level: 1 },
  { word: 'Vibrant', meaning: 'Full of energy or colour', level: 1 },
  { word: 'Veil', meaning: 'Thin material to cover something', level: 1 },
  { word: 'Ventilation', meaning: 'Circulation of fresh air', level: 1 },
  { word: 'Venture', meaning: 'A daring journey or action', level: 1 },
  { word: 'Velocity', meaning: 'Speed in a given direction', level: 1 },
  { word: 'Venerate', meaning: 'Regard with great respect', level: 1 },
  { word: 'Vessel', meaning: 'Large boat or ship', level: 1 },
  { word: 'Vex', meaning: 'Annoy or worry', level: 1 },
  { word: 'Vicarious', meaning: 'Experienced through another', level: 1 },
  { word: 'Vile', meaning: 'Very unpleasant', level: 1 },
  { word: 'Villain', meaning: 'Bad guy', level: 1 },
  { word: 'Vista', meaning: 'A pleasing view', level: 1 },
  { word: 'Vivid', meaning: 'Bright and clear', level: 1 },
  { word: 'Vixen', meaning: 'Female fox', level: 1 },
  { word: 'Vocal', meaning: 'Related to the voice', level: 1 },
  { word: 'Voluminous', meaning: 'Very large and bulky', level: 1 },
  { word: 'Voracious', meaning: 'Having a very eager approach to an activity', level: 1 },
  { word: 'Voyage', meaning: 'A long journey, especially by sea', level: 1 },
  { word: 'Virtue', meaning: 'A good quality', level: 1 },
  { word: 'Voracious', meaning: 'Having a huge appetite', level: 1 },
  { word: 'Vortex', meaning: 'A whirling mass of something', level: 1 },
  { word: 'Waddle', meaning: 'Walk with short steps and a swaying motion', level: 1 },
  { word: 'Wander', meaning: 'Walk around without a purpose', level: 1 },
  { word: 'Weary', meaning: 'Tired', level: 1 },
  { word: 'Weird', meaning: 'Unusual or strange', level: 1 },
  { word: 'Whimsical', meaning: 'Playfully quaint or fanciful', level: 1 },
  { word: 'Whinging', meaning: 'Constant complaining about something small', level: 1 },
  { word: 'Whirl', meaning: 'Spin quickly around', level: 1 },
  { word: 'Whisper', meaning: 'Speak very softly', level: 1 },
  { word: 'Wholesome', meaning: 'Good that can improve your life', level: 1 },
  { word: 'Wilful', meaning: 'Doing damage on purpose', level: 1 },
  { word: 'Wisdom', meaning: 'Good judgement', level: 1 },
  { word: 'Wistful', meaning: 'Sad and thoughtful for the past', level: 1 },
  { word: 'Wither', meaning: 'Become dry or shriveled', level: 1 },
  { word: 'Witty', meaning: 'Clever and amusing', level: 1 },
  { word: 'Wobble', meaning: 'Move unsteadily from side to side', level: 1 },
  { word: 'Wonder', meaning: 'Feeling amazed', level: 1 },
  { word: 'Wondrous', meaning: 'Amazing', level: 1 },
  { word: 'Worthy', meaning: 'Deserving the accolade', level: 1 },
  { word: 'Wound', meaning: 'An open injury', level: 1 },
  { word: 'Wrangle', meaning: 'Argue in a noisy way', level: 1 },
  { word: 'Wrinkle', meaning: 'Small line or fold in something', level: 1 },
  { word: 'Writhe', meaning: 'Make twisting movements', level: 1 },
  { word: 'X-axis', meaning: 'Horizontal line in a graph', level: 1 },
  { word: 'X-factor', meaning: 'Special quality or talent', level: 1 },
  { word: 'X-ray', meaning: 'A picture that shows bones and inside parts', level: 1 },
  { word: 'Xenon', meaning: 'A kind of gas that glows', level: 1 },
  { word: 'Xerox', meaning: 'A copy of a document', level: 1 },
  { word: 'Xenophobic', meaning: 'Afraid of people from other countries', level: 1 },
  { word: 'Xeriscape', meaning: 'A way of gardening that uses less water', level: 1 },
  { word: 'Xerophyte', meaning: 'Plant that needs very little water', level: 1 },
  { word: 'Xylophone', meaning: 'Musical instrument with wooden bars', level: 1 },
  { word: 'Yawn', meaning: 'Open mouth wide due to tiredness', level: 1 },
  { word: 'Yelp', meaning: 'A short, sharp cry of pain', level: 1 },
  { word: 'Yield', meaning: 'Give way to pressure', level: 1 },
  { word: 'Yearn', meaning: 'Desire strongly', level: 1 },
  { word: 'Yonder', meaning: 'Over there', level: 1 },
  { word: 'Yummy', meaning: 'Very tasty', level: 1 },
  { word: 'Yarn', meaning: 'Long, thin strand of cotton', level: 1 },
  { word: 'Yank', meaning: 'Pull with a jerk', level: 1 },
  { word: 'Yacht', meaning: 'A large boat for pleasure', level: 1 },
  { word: 'Yodel', meaning: 'A song with quick changes in pitch', level: 1 },
  { word: 'Yeoman', meaning: 'A free man performing military service', level: 1 },
  { word: 'Yolk', meaning: 'The yellow part of an egg', level: 1 },
  { word: 'Yield', meaning: 'Produce or provide', level: 1 },
  { word: 'Yoke', meaning: 'An harness for animals like oxen', level: 1 },
  { word: 'Youngster', meaning: 'A child or young person', level: 1 },
  { word: 'Zapper', meaning: 'Something that zaps or destroys', level: 1 },
  { word: 'Zeppelin', meaning: 'A type of airship', level: 1 },
  { word: 'Zany', meaning: 'Amusingly unconventional', level: 1 },
  { word: 'Zealous', meaning: 'Showing great enthusiasm', level: 1 },
  { word: 'Zeitgeist', meaning: 'Opinions and beliefs or the time', level: 1 },
  { word: 'Zenith', meaning: 'The highest point', level: 1 },
  { word: 'Zephyr', meaning: 'A gentle breeze', level: 1 },
  { word: 'Zero', meaning: 'No amount', level: 1 },
  { word: 'Zest', meaning: 'Great enjoyment', level: 1 },
  { word: 'Zestful', meaning: 'Having lots of entusiasm', level: 1 },
  { word: 'Zigzag', meaning: 'A pattern of sharp turns or angles', level: 1 },
  { word: 'Zodiac', meaning: 'Group of star signs', level: 1 },
  { word: 'Zinc', meaning: 'A kind of metal', level: 1 },
  { word: 'Zoom', meaning: 'Move quickly', level: 1 },
  { word: 'Zombie', meaning: 'A creature from the dead', level: 1 },
  { word: 'Zone', meaning: 'Area with different features', level: 1 },
];

export const generateWordMeaningQuestion = (targetLevel) => {
    let filteredWords = wordsWithMeanings.filter(word => word.level === targetLevel);

    if (filteredWords.length === 0) {
        console.warn("No words found for the given level. Defaulting to level 1.");
        filteredWords = wordsWithMeanings.filter(word => word.level === 1);
    }

    // Step 1: Randomly select a word based on the level
    const randomIndex = getRandomNumber(0, filteredWords.length - 1);
    const selectedWord = filteredWords[randomIndex].word;
    const correctMeaning = filteredWords[randomIndex].meaning;

    // Step 2: Create the question
    const question = `What does ${selectedWord} mean?`;

    // Step 3: Randomly select two other meanings for incorrect options
    let incorrectOptions = [];
    while (incorrectOptions.length < 2) {
      const randomIncorrectIndex = getRandomNumber(0, wordsWithMeanings.length - 1);
      if (randomIncorrectIndex !== randomIndex) {
        const incorrectMeaning = wordsWithMeanings[randomIncorrectIndex].meaning;
        if (!incorrectOptions.includes(incorrectMeaning)) {
          incorrectOptions.push(incorrectMeaning);
        }
      }
    }

    // Step 4: Shuffle the options
    const buttonOptions = shuffleArray([correctMeaning, ...incorrectOptions]);

    return {
      question,
      answer: correctMeaning,
      buttonOptions
    };
};

