export const generateTrueFalseQuestion = () => {
    // Example question format
    const questions = [
        {
            statement: "All landlocked countries have no direct access to the ocean. Bolivia is landlocked.",
            conclusion: "Therefore, Bolivia has no direct access to the ocean.",
            answer: true
          },
          {
            statement: "Trees produce oxygen. Some plants are trees.",
            conclusion: "Therefore, plants produce oxygen.",
            answer: true
          },
          {
            statement: "All dogs are mammals. Cats are not dogs.",
            conclusion: "Therefore, cats are not mammals.",
            answer: false
          },
          {
            statement: "All birds have feathers. Ostriches are birds.",
            conclusion: "Therefore, ostriches have feathers.",
            answer: true
          },
          {
            statement: "No reptiles have fur. All dogs have fur.",
            conclusion: "Therefore, no dogs are reptiles.",
            answer: true
          },
          {
            statement: "All cars have wheels. Bicycles have wheels.",
            conclusion: "Therefore, all bicycles are cars.",
            answer: false
          },
          {
            statement: "All planets orbit a star. The Moon orbits the Earth.",
            conclusion: "Therefore, the Moon is a planet.",
            answer: false
          },
          {
            statement: "No birds are bats. All bats can fly.",
            conclusion: "Therefore, no birds can fly.",
            answer: false
          },
          {
            statement: "All flowers need water to grow. Cacti need water to grow.",
            conclusion: "Therefore, all cacti are flowers.",
            answer: false
          },
          {
            statement: "No insects are mammals. Bats are mammals.",
            conclusion: "Therefore, bats are not insects.",
            answer: true
          },
          {
            statement: "Fishes lay eggs. Chickens lay eggs.",
            conclusion: "Therefore, chickens are fish.",
            answer: false // Corrected to false
          },
          {
            statement: "No cars are bicycles. Some vehicles are cars.",
            conclusion: "Therefore, no vehicles are bicycles.",
            answer: false
          },
          {
            statement: "All poets write poems. Some writers are poets.",
            conclusion: "Therefore, some writers write poems.",
            answer: true
          },
          {
            statement: "All computers use electricity. Some phones are computers.",
            conclusion: "Therefore, phones use electricity.",
            answer: true
          },
          {
            statement: "All humans need water to survive. Astronauts are humans.",
            conclusion: "Therefore, astronauts need water to survive.",
            answer: true
          },
          {
            statement: "All bicycles have two wheels. Motorcycles have two wheels.",
            conclusion: "Therefore, all motorcycles are bicycles.",
            answer: false
          },
          {
            statement: "No planets in our solar system are closer to the Sun than Mercury. Venus orbits the Sun.",
            conclusion: "Therefore, Venus is not closer to the Sun than Mercury.",
            answer: true
          },
          {
            statement: "All birds lay eggs. Penguins are birds.",
            conclusion: "Therefore, penguins lay eggs.",
            answer: true
          },
          {
            statement: "All mammals have vertebrae. Dolphins are mammals.",
            conclusion: "Therefore, dolphins have vertebrae.",
            answer: true
          },
          {
            statement: "No squares are circles. All squares have four sides.",
            conclusion: "Therefore, all four-sided figures are squares.",
            answer: false
          },
          {
            statement: "All roses need sunlight to grow. Sunflowers need sunlight to grow.",
            conclusion: "Therefore, all sunflowers are roses.",
            answer: false
          },
          {
            statement: "No reptiles have feathers. All birds have feathers.",
            conclusion: "Therefore, no birds are reptiles.",
            answer: true
          },
          {
            statement: "All fruits contain seeds. Bananas are fruits.",
            conclusion: "Therefore, bananas contain seeds.",
            answer: true
          },
          {
            statement: "All dairy products come from animals. Cheese is a dairy product.",
            conclusion: "Therefore, cheese comes from animals.",
            answer: true
          },
          {
            statement: "All nuts contain fat. Almonds are nuts.",
            conclusion: "Therefore, almonds contain fat.",
            answer: true
          },
          {
            statement: "All animals contain fat. Almonds contains fat.",
            conclusion: "Therefore, almonds are animals.",
            answer: false
          },
          {
            statement: "Dairy products come from animals. Salmons are animals.",
            conclusion: "Therefore, salmon is a dairy product.",
            answer: false
          },
          {
            statement: "All countries have a capital city. France is a country.",
            conclusion: "Therefore, France has a capital city.",
            answer: false
          },
          {
            statement: "All cats have four legs. A tiger has four legs.",
            conclusion: "Therefore, a tiger is a cat.",
            answer: true // Tigers are indeed categorized as large cats.
          },
          {
            statement: "Planets in our Solar System have moons. Mercury is a planet.",
            conclusion: "Therefore, Mercury has a moon.",
            answer: false // Mercury does not have any moons.
          },
          {
            statement: "All ice creams are cold. Soup is sometimes served cold.",
            conclusion: "Therefore, cold soup is ice cream.",
            answer: false // Cold soup is not ice cream, despite the temperature.
          },
          {
            statement: "All birds can fly. Penguins are birds.",
            conclusion: "Therefore, penguins can fly.",
            answer: false // Penguins are birds that cannot fly.
          },
          {
            statement: "Water is necessary for plants to grow. Cacti are plants.",
            conclusion: "Therefore, cacti need water to grow.",
            answer: true // Cacti do need water to grow, albeit in much smaller amounts.
          },
          {
            statement: "Butterflies have wings. Birds have wings.",
            conclusion: "Therefore, butterflies are birds.",
            answer: false // Despite both having wings, butterflies are not birds.
          },
          {
            statement: "All mammals have hair. Dolphins live in the ocean.",
            conclusion: "Therefore, dolphins are fish.",
            answer: false // Dolphins are mammals that breathe air through their lungs.
          },
          {
            statement: "All spiders have eight legs. An octopus has eight legs.",
            conclusion: "Therefore, an octopus is a spider.",
            answer: false // An octopus is not a spider, despite both having eight limbs.
          },
          {
            statement: "Chocolate is made from cocoa beans. Coffee is also made from beans.",
            conclusion: "Therefore, coffee is made from cocoa beans.",
            answer: false // Coffee is made from coffee beans, not cocoa beans.
          },
          {
            statement: "The Earth orbits around the Sun. The Moon orbits around the Earth.",
            conclusion: "Therefore, the Moon also orbits around the Sun.",
            answer: true // The Moon does orbit the Sun indirectly by orbiting the Earth.
          },
          {
            statement: "Rainbows appear after rain. Puddles also appear after rain.",
            conclusion: "Therefore, puddles cause rainbows.",
            answer: false // Puddles do not cause rainbows; rainbows are caused by the reflection, refraction, and dispersion of light in water droplets.
          },
          {
            statement: "Books contain pages. Notebooks contain pages.",
            conclusion: "Therefore, notebooks are books.",
            answer: true // This can be considered true in a broad sense, as notebooks are a type of book designed for writing rather than reading.
          },
          {
            statement: "Trees grow from seeds. Apples come from trees.",
            conclusion: "Therefore, apples can grow from seeds.",
            answer: true // This is true, as apples have seeds that can grow into new apple trees.
          },
          {
            statement: "All humans have hearts. Dogs have hearts.",
            conclusion: "Therefore, dogs are humans.",
            answer: false // The conclusion is false; having a heart is not unique to humans.
          },
          {
            statement: "All bicycles have pedals. Cars have wheels but no pedals.",
            conclusion: "Therefore, cars are not bicycles.",
            answer: true // This is true, as the presence of pedals is a distinguishing feature of bicycles compared to cars.
          },
          {
            statement: "Ghosts can fly. Birds can fly.",
            conclusion: "Therefore, ghosts are birds.",
            answer: false // The conclusion is false; the ability to fly does not make birds witches.
          },
          {
            statement: "The Moon shines at night. Stars also shine at night.",
            conclusion: "Therefore, the Moon is a star.",
            answer: false // The conclusion is false; the Moon is not a star, but a satellite of the Earth.
          },
          {
            statement: "Goldfish live in water. Dolphins also live in water.",
            conclusion: "Therefore, dolphins are goldfish.",
            answer: false // The conclusion is false; both dolphins and goldfish live in water, but dolphins are mammals, not fish.
          },
          {
            statement: "The Sun rises in the east. The Moon also rises.",
            conclusion: "Therefore, the Moon rises in the east.",
            answer: true // This is true; like the Sun, the Moon also rises in the east due to the Earth's rotation.
          },
          {
            statement: "Sharks live in the ocean. Coral reefs are found in the ocean.",
            conclusion: "Therefore, sharks live in coral reefs.",
            answer: false // The conclusion is false; not all sharks live in coral reefs, as sharks inhabit various parts of the ocean.
          },
          {
            statement: "Firefighters fight fires. Police officers fight crime.",
            conclusion: "Therefore, police officers are firefighters.",
            answer: false // The conclusion is false; police officers and firefighters have different responsibilities.
          },
          {
            statement: "Snow is cold. Ice cream is also cold.",
            conclusion: "Therefore, ice cream is made of snow.",
            answer: false // The conclusion is false; despite both being cold, ice cream is not made from snow.
          },
          {
            statement: "Birds lay eggs. Chickens are birds.",
            conclusion: "Therefore, chickens lay eggs.",
            answer: true // This is true; chickens are a type of bird, and they lay eggs.
          },
          {
            statement: "Leaves are part of a tree. Apples grow on trees.",
            conclusion: "Therefore, apples are leaves.",
            answer: false // The conclusion is false; although apples and leaves both grow on trees, apples are fruit, not leaves.
          },
          {
            statement: "Bees make honey. Bears eat honey.",
            conclusion: "Therefore, bears eat bees.",
            answer: false // The conclusion is false; despite bears eating honey, it does not make them bees.
          },
          {
            statement: "All plants need sunlight to grow. Mushrooms grow in the dark.",
            conclusion: "Therefore, mushrooms are not plants.",
            answer: true // This is true; mushrooms are fungi, not plants, and can grow without sunlight.
          },
          {
            statement: "Mercury is the closest planet to the Sun. Venus is the second closest planet to the Sun.",
            conclusion: "Therefore, Venus is hotter than Mercury.",
            answer: false // The conclusion is false; despite being second closest, Venus is indeed hotter than Mercury due to its thick atmosphere causing a greenhouse effect.
          },
          {
            statement: "Elephants are the largest land animals. Mice are smaller than elephants.",
            conclusion: "Therefore, mice are not land animals.",
            answer: false // The conclusion is false; mice being smaller does not disqualify them from being land animals.
          },
          {
            statement: "Cacti are types of plants. Cacti store water in their stems.",
            conclusion: "Therefore, all plants store water in their stems.",
            answer: false // The conclusion is false; not all plants store water in their stems, this is specific to cacti and similar plants adapted to dry environments.
          },
          {
            statement: "All numbers divisible by 2 are even. The number 4 is divisible by 2.",
            conclusion: "Therefore, the number 4 is even.",
            answer: true // This is true; the number 4 is even because it can be divided by 2 without leaving a remainder.
          },
          {
            statement: "All books have pages. Magazines also have pages.",
            conclusion: "Therefore, magazines are books.",
            answer: false // The conclusion is false; despite both having pages, magazines and books serve different purposes and are categorized differently.
          },
          {
            statement: "Rain provides water for plants. Cacti are plants.",
            conclusion: "Therefore, cacti need rain to survive.",
            answer: true // This is true, to an extent; cacti do need water to survive, though they are adapted to require much less water than other plants and can survive long periods of drought.
          },
          {
            statement: "Wolves live in packs. Dogs are descended from wolves.",
            conclusion: "Therefore, all dogs live in packs.",
            answer: false // The conclusion is false; while some dogs may display pack behavior, especially in wild or feral conditions, many domestic dogs live happily as solitary pets or in smaller family units.
          },
          {
            statement: "Butter is made from cream. Cream comes from milk.",
            conclusion: "Therefore, butter is made from milk.",
            answer: true // This is true; butter is indeed made from milk, through the process of churning cream, which is separated from the milk.
          },
          {
            statement: "Kangaroos are native to Australia. Koalas are also native to Australia.",
            conclusion: "Therefore, koalas are kangaroos.",
            answer: false // The conclusion is false; despite both being native to Australia, koalas and kangaroos are different species with different characteristics.
          },
          {
            statement: "All flowers produce seeds. Sunflowers are flowers.",
            conclusion: "Therefore, sunflowers produce seeds.",
            answer: true // This is true; sunflowers are flowers that produce seeds, which are even harvested and sold as sunflower seeds.
          },
          {
            statement: "Ice floats in water. Rocks also sink in water.",
            conclusion: "Therefore, ice is a type of rock.",
            answer: false // The conclusion is false; the buoyancy of ice compared to rocks does not make ice a type of rock.
          },
          {
            statement: "Chocolate is made from cocoa. Cocoa is a plant.",
            conclusion: "Therefore, chocolate is a plant.",
            answer: false // The conclusion is false; while chocolate is made from a product of the cocoa plant, chocolate itself is not a plant.
          },
          {
            statement: "Rabbits are known for hopping. Frogs also hop.",
            conclusion: "Therefore, rabbits are frogs.",
            answer: false // The conclusion is false; although both rabbits and frogs hop, this does not make rabbits a type of frog.
          }

];

    // Select a random question
    const randomIndex = Math.floor(Math.random() * questions.length);
    const selectedQuestion = questions[randomIndex];

    // Format the question text with line breaks for full stops
    const formattedStatement = selectedQuestion.statement.replace('. ', '.\n');
    const formattedConclusion = selectedQuestion.conclusion.replace('. ', '.\n');

    // Prepare the return structure
    return {
      question: formattedStatement + " " + formattedConclusion,
      answer: selectedQuestion.answer ? "True" : "False",
      buttonOptions: ["True", "False"]
    };
  };
