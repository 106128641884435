import { getRandomNumber, shuffleArray } from "./utility";

export const generateMinMaxNumberQuestion = () => {
  // Generate 4 random digits
  const digits = Array.from({length: 4}, () => getRandomNumber(1, 9).toString());

  // Decide the pattern: 1 for largest number, 2 for smallest number
  const patternType = getRandomNumber(1, 2);

  let question, correctAnswer;

  // Copy and shuffle digits for the question
  const shuffledDigitsForQuestion = shuffleArray([...digits]);

  if (patternType === 1) {
    // Find the largest number by sorting digits in descending order
    correctAnswer = parseInt([...digits].sort((a, b) => b - a).join(''), 10);
    question = `Using the digits ${shuffledDigitsForQuestion.join(', ')}, what is the largest number you can make?`;
  } else {
    // Find the smallest number by sorting digits in ascending order
    correctAnswer = parseInt([...digits].sort((a, b) => a - b).join(''), 10);
    question = `Using the digits ${shuffledDigitsForQuestion.join(', ')}, what is the smallest number you can make?`;
  }

  // Generate wrong answers (could be more creative here)
  const wrongAnswers = [
    parseInt(digits.reverse().join(''), 10),
    parseInt(shuffleArray([...digits]).join(''), 10),
    parseInt(shuffleArray([...digits]).join(''), 10)
  ];

  // Ensure all wrong answers are unique and not equal to the correct answer
  const uniqueWrongAnswers = Array.from(new Set(wrongAnswers)).filter(x => x !== correctAnswer);

  return {
    question,
    answer: correctAnswer,
    buttonOptions: shuffleArray([correctAnswer, ...uniqueWrongAnswers])
  };
};

