export const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  export const getRandomLetter = (letters) => {
    const randomIndex = getRandomNumber(0, letters.length - 1);
    return letters[randomIndex];
};


  export const shuffleArray = (array) => {
    let shuffledArray = [...array];  // create a shallow copy of the array

    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }

    return shuffledArray;
};

const units = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberToWords = (n) => {
    if (n < 10) return units[n];
    if (n < 20) return teens[n - 10];
    if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 !== 0 ? ' ' + units[n % 10] : '');
    if (n < 1000) return units[Math.floor(n / 100)] + ' hundred' + (n % 100 !== 0 ? ' and ' + numberToWords(n % 100) : '');

    // Handling thousands, millions, and billions
    if (n < 1000000) { // Less than a million
        return numberToWords(Math.floor(n / 1000)) + ' thousand' + (n % 1000 !== 0 ? ' ' + numberToWords(n % 1000) : '');
    }
    if (n < 1000000000) { // Less than a billion
        return numberToWords(Math.floor(n / 1000000)) + ' million' + (n % 1000000 !== 0 ? ' ' + numberToWords(n % 1000000) : '');
    }
    return numberToWords(Math.floor(n / 1000000000)) + ' billion' + (n % 1000000000 !== 0 ? ' ' + numberToWords(n % 1000000000) : '');
};

export const getRandomDecimal = (min, max, precision = 2, introduceDecimals = false) => {
  if (!introduceDecimals) {
      return getRandomNumber(min, max);  // Just use the integer version for lower levels
  }
  const num = Math.random() * (max - min) + min;
  return parseFloat(num.toFixed(precision)); // Rounds the number to the specified number of decimal places
};

