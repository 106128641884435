import { shuffleArray } from "./utility";

const fetchQuestionsFromDB = async (dbName, book, title) => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';
    // console.log("API URL:", API_BASE_URL);
    const response = await fetch(`${API_BASE_URL}/api/comprehension_questions/${dbName}?book=${book}&title=${title}`);

    if (response.ok) {
      const data = await response.json();
      // console.log("Fetched questions from DB:", data);
      return data; // This should be an array of questions
    } else {
      // console.log("Failed to fetch questions");
      return [];
    }
};


  const generateComprehensionQuestion = (selectedQuestions) => {
    return selectedQuestions.map((q) => {
      const { question, word, badWord1, badWord2, points } = q;
      let buttonOptions = [word, badWord1, badWord2];
      buttonOptions = buttonOptions.sort(() => Math.random() - 0.5);
      return {
        question,
        answer: word,
        buttonOptions,
        points
      };
    });
  };



export const generateComprehensionQuestions = async (dbName, book, title) => {
    // Fetch questions from the database based on dbName, book, and title
    // ...

    // Assuming you have fetched 15 questions into an array called 'fetchedQuestions'
    const fetchedQuestions = await fetchQuestionsFromDB(dbName, book, title); // Replace with your actual fetch function
    // console.log("Fetched Questions:", fetchedQuestions);

    // Randomly pick 10 questions
    const selectedQuestions = shuffleArray(fetchedQuestions).slice(0, 10);
    // console.log("Selected Questions:", selectedQuestions);

    // Generate the questions using your existing function
    return generateComprehensionQuestion(selectedQuestions);
  };
