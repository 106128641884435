import React, { useState, useEffect, useRef } from 'react';

function Stopwatch({ minutes }) {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const canvasRef = useRef(null);

  const startTimer = () => {
    setIsRunning(true);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  const resetTimer = () => {
    setIsRunning(false);
    setElapsedTime(0);
  };

  useEffect(() => {
    let animationFrameId = null;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = 100;

    const drawClock = (percent) => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.beginPath();
      context.arc(centerX, centerY, radius, -0.5 * Math.PI, (-0.5 + percent * 2) * Math.PI);
      context.strokeStyle = '#000';
      context.lineWidth = 10;
      context.stroke();
    };

    const updateTime = (timestamp) => {
      if (!isRunning) {
        return;
      }

      const newElapsedTime = timestamp - startTime.current;
      setElapsedTime(newElapsedTime);

      const percent = newElapsedTime / (minutes * 60 * 1000); // minutes converted to milliseconds
      drawClock(percent);

      if (percent < 1) {
        animationFrameId = requestAnimationFrame(updateTime);
      } else {
        stopTimer();
        alert('Time is up!');
      }
    };

    const startTime = { current: null };

    if (isRunning) {
      startTime.current = performance.now() - elapsedTime;
      animationFrameId = requestAnimationFrame(updateTime);
    } else {
      drawClock(0);
    }

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [elapsedTime, isRunning, minutes]);

  return (
    <div>
      <canvas ref={canvasRef} width="200" height="200"></canvas>
      <div>
        {isRunning ? (
          <>
            <button onClick={stopTimer}>Stop</button>
          </>
        ) : (
            <button onClick={startTimer}>Start</button>
            )}
            <button onClick={resetTimer}>Reset</button>
      </div>
    </div>
  );
}

export default Stopwatch;
