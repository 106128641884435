import BaseScene from "./BaseScene"; // Adjust the path as necessary
import iiBackground from "../assets/backgrounds/InterviewIsland.jpg";
import backButton from "../assets/buttons/BackButton.png";

class InterviewIslandScene extends BaseScene {
    constructor() {
        super('InterviewIslandScene');
    }


    init() {
        this.registry.set('debug', false);
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        if (isPortrait) {
            this.registry.set('isPortrait', true);
        }
    }

    preload() {
        this.load.image('iiBackgroundImage', iiBackground); // Note the key 'backgroundImage' here
        this.load.image('backButton', backButton);
    }

    create() {
        const iiBg = this.setBackground('iiBackgroundImage'); // Use the key you've defined in preload
        this.backgroundOverlay()
        const userData = this.game.registry.get('userData');
        const backButtonXPosition = this.sys.game.config.width * 0.9;
        const backButtonYPosition = this.sys.game.config.height * 0.1;
        this.createButton(backButtonXPosition, backButtonYPosition, 'backButton', null, () => this.scene.start('MenuScene'));

        const musicButtonXPosition = this.sys.game.config.width * 0.8;
        const musicButtonYPosition = this.sys.game.config.height * 0.05;
        this.musicButton = this.createButton(
            musicButtonXPosition, // X position
            musicButtonYPosition, // Y position
            'musicButton', // Texture key for the button
            null, // Hover texture (null if not used)
            () => this.toggleMusicAndTint(), // Action on click
            0.5, // Scale multiplier
            false // Availability
        );
    }
}

export default InterviewIslandScene;
