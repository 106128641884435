import BaseScene from "./BaseScene"; // Adjust the path as necessary
import qp1Background from "../assets/backgrounds/QuestionPage1.jpg";
import logButton from "../assets/buttons/LogButton.png";

class ExamPaper1Scene extends BaseScene {
    constructor() {
        super('ExamPaper1Scene');
        this.questionData = null;
    }

    init(data) {
        // Now you have access to `data`
        this.registry.set('debug', false);
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        if (isPortrait) {
            this.registry.set('isPortrait', true);
        }
        this.questionData = data.questionData;
        this.questionsList = this.registry.get('questions');
        this.userData = this.registry.get('userData');
        this.topicData = data.topic; // Make sure `topic` is being passed correctly when starting the scene
        this.points = 0;
        this.correctCount = 0;
        this.currentQuestionIndex = 0;
        this.startTime = 10;
        this.multiplier = 1;
        this.bestScoreKey = this.questionsList[0].key;
        if (this.userData && this.bestScoreKey in this.userData) {
            this.bestScore = this.userData[this.bestScoreKey]?.bestScore || 0;
        } else {
            this.bestScore = 0;
        }
    }

    preload() {
        this.load.image('qp1BackgroundImage', qp1Background);
        this.load.image('logButton', logButton);
    }

    create() {
        console.log('Questions List:', this.questionsList);
        console.log('Best Score Key:', this.bestScoreKey);
        console.log('User Data:', this.userData);
        console.log('Best Score:', this.bestScore);


        this.setBackground('qp1BackgroundImage');
        this.backgroundOverlay()
        // Setup the back button
        this.createButton(this.sys.game.config.width * 0.9, this.sys.game.config.height * 0.1, 'backButton', null, () => this.scene.start('MenuScene'));

        const musicButtonXPosition = this.sys.game.config.width * 0.8;
        const musicButtonYPosition = this.sys.game.config.height * 0.05;
        this.musicButton = this.createButton(
            musicButtonXPosition, // X position
            musicButtonYPosition, // Y position
            'musicButton', // Texture key for the button
            null, // Hover texture (null if not used)
            () => this.toggleMusicAndTint(), // Action on click
            0.5, // Scale multiplier
            false // Availability
        );
        // console.log('In questions', this.questionData);
        const baseFontSize = parseFloat(this.responsiveFontSize(10, 0.03).replace('px', ''));
        const strokeThickness = this.sys.game.config.width * 0.006;

        this.questionGroup = this.add.group();

        // Assuming you have a group named 'questionGroup'
        const children = this.questionGroup.getChildren();

        // console.log('groups children: ',children);
        // Iterate over each child and log information
        children.forEach(child => {
            // You can log any specific information about each child here
        });


        const bestScoreXPosition = this.sys.game.config.width * 0.25;
        const bestScoreYPosition = this.sys.game.config.height * 0.05;
        const pointsXPosition = this.sys.game.config.width * 0.25;
        const pointsYPosition = this.sys.game.config.height * 0.1;
        const timerXPosition = this.sys.game.config.width * 0.75;
        const timerYPosition = this.sys.game.config.height * 0.1;

        this.pointsText = this.createAnimatedText(bestScoreXPosition, bestScoreYPosition, `Best Score: ${this.bestScore}`, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        });

        this.pointsText = this.createAnimatedText(pointsXPosition, pointsYPosition, `Points: ${this.points}`, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        });

        this.timerText = this.createAnimatedText(timerXPosition, timerYPosition, `Time: ${this.startTime}`, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        });

        //start game and timer
        this.startTimer();



        const questions = this.registry.get('questions');
        // console.log('In questions', questions);
        // let currentQuestionIndex = this.registry.get('currentQuestionIndex');


        const questionData = questions[this.currentQuestionIndex];


        // Display the current question
        if (questionData) {
            this.questionText = this.displayQuestion(questionData, this.currentQuestionIndex);

            // // Assuming you have a group named 'questionGroup'
            // const children = this.questionGroup.getChildren();

            // console.log('groups children: ',children);
            // // Iterate over each child and log information
            // children.forEach(child => {
            //     // You can log any specific information about each child here
            // });


        }

        // Handling button options
        this.displayOptions(questionData, questions, this.currentQuestionIndex);

    }

    update() {
        // Check if the points have changed or any other conditions need to be refreshed
        if (this.startTime <= 3) {
            // Change the timer text color to red when 3 seconds or less remain
            this.timerText.setColor('#FF0000'); // Hex code for red
        } else {
            // Change the timer text color back to the original color if more than 3 seconds remain
            this.timerText.setColor('#f78737');
        }

        // Here you could also include other update logic, such as checking for user inputs or updating other game elements
    }


    startTimer() {
        this.timerEvent = this.time.addEvent({
            delay: 1000,                // 1000 ms = 1 second per tick
            callback: this.updateTimer, // Function to call each tick
            callbackScope: this,        // Context the function will run in
            loop: true                  // Repeat forever until we stop it
        });
    }

    updateTimer() {
        this.startTime -= 1;
        this.timerText.setText(`Time: ${this.startTime}`);
        if (this.startTime <= 0) {
            this.timerEvent.remove(); // Stop the timer
            this.scene.start('GameSummaryScene');
            // For a Phaser-based dialog or text display, consider replacing the alert with in-game graphics
        }
    }

    displayQuestion(questionData, currentQuestionIndex) {

        const textXPosition = this.sys.game.config.width * 0.5;
        const textYPosition = this.sys.game.config.height * 0.15;

        // Create and store new text
        this.questionText = this.add.text(textXPosition, textYPosition, `Q${currentQuestionIndex + 1}: ${questionData.question}`, {
            fontFamily: 'gaegu',
            fontSize: `${this.getBaseFontSize()}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: this.getStrokeThickness(),
            align: 'center'
        }).setOrigin(0.5, 0.5);

        this.questionGroup.add(this.questionText);

        // const children = this.questionGroup.getChildren();

        // // console.log('groups children: ',children);
        // // Iterate over each child and log information
        // children.forEach(child => {
        //     // You can log any specific information about each child here
        // });

    }

    displayOptions(questionData, questions, currentQuestionIndex) {
        const buttons = [];
        const options = questionData.buttonOptions; // Assuming this is an array of strings
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;

        const numRows = isPortrait ? options.length : (options.length > 2 ? 2 : 1); // Calculate rows based on options
        const numCols = isPortrait ? 1 : (options.length > 2 ? 2 : options.length); // Calculate cols based on options
        // Calculate button dimensions based on screen size
        const buttonWidth = this.sys.game.config.width / 8;
        const buttonHeight = buttonWidth / 2;

        // Adjust spacing based on new button dimensions (optional, could be a fixed value as well)
        const spacingX = buttonWidth * 1.75;
        const spacingY = isPortrait ? buttonHeight * 4 : buttonHeight * 1.5; // Similarly, a quarter of the button height
        const Ybuffer = isPortrait ? 0 : this.sys.game.config.height * 0.1;

        const startX = (this.sys.game.config.width - (numCols * buttonWidth + (numCols - 1) * spacingX)) / 2;
        const startY = ((this.sys.game.config.height - (numRows * buttonHeight + (numRows - 1) * spacingY)) / 2) + Ybuffer;

        for (let row = 0; row < numRows; row++) {
            for (let col = 0; col < numCols; col++) {
                const index = row * numCols + col;
                if (index < options.length) {
                    const button = this.createOptionButton(startX, startY, row, col, buttonWidth, buttonHeight, spacingX, spacingY, options[index], questionData, questions, currentQuestionIndex);
                    buttons.push(button);
                }
            }
        }
        return buttons;
    }

    createOptionButton(startX, startY, row, col, buttonWidth, buttonHeight, spacingX, spacingY, text, questionData, questions, currentQuestionIndex) {
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        const buttonScale = isPortrait ? 1.5 : 2.2;

        const buttonX = startX + col * (buttonWidth + spacingX);
        const buttonY = startY + row * (buttonHeight * buttonScale + spacingY);

        //update to font size - higher means smaller

        let initialFontSize = isPortrait ? buttonHeight / 1.5 : buttonHeight / 3.4;

        // Create the button
        const button = this.createButton(buttonX, buttonY, 'logButton', null, () => {
            this.handleAnswer(text, questionData, questions, currentQuestionIndex);
        }, buttonScale);
        this.questionGroup.add(button);


        const leftPadding = isPortrait ? buttonWidth * 0.5: buttonWidth * 0.25;
        let wordWrapWidth = buttonWidth * 0.95;

        // Process the text to manually wrap it
        let processedText = this.customWordWrap(text, wordWrapWidth, initialFontSize, buttonWidth);

        // Add text to the button
        let textObject = this.add.text(buttonX + leftPadding, buttonY, processedText, {
            font: `${initialFontSize}px gaegu`,
            fill: '#ffffff',
            align: 'center'
        }).setOrigin(0.5, 0.5);

        this.questionGroup.add(textObject);

        // const children = this.questionGroup.getChildren();

        // // console.log('groups children: ',children);
        // // Iterate over each child and log information
        // children.forEach(child => {
        //     // You can log any specific information about each child here
        // });

    }

    handleAnswer(selectedAnswer, questionData, questions, currentQuestionIndex) {
        // console.log('Handling question answer');
        const isCorrect = selectedAnswer === questionData.answer;
        let score = this.registry.get('score') || 0;
        let incorrectAnswers = this.registry.get('incorrectAnswers') || [];
        const incorrectAnswerMode = this.registry.get('incorrectAnswerMode') || false;
        let correctAnswers = this.registry.get('correctAnswers') || [];

        if (isCorrect) {
            score++;  // Increase score
            this.points++;  // Increase score (including multipliers) shown in the scene
            this.correctCount++;  // Increase correct questions
            this.registry.set('score', this.points);
            this.pointsText.setText(`Points: ${this.points}`);
            this.startTime += 3;  // Add 2 seconds to the timer
            if (this.timerText) {
                this.timerText.setText(`Time: ${this.startTime}`); // Update timer display
            }
            if (incorrectAnswerMode) {
                // console.log('adding to correct list', questionData.question )
                correctAnswers.push({
                    key: questionData.key,
                    questionText: questionData.question
                });
            }
        } else {
            incorrectAnswers.push({
                key: questionData.key,
                questionText: questionData.question,
                selectedAnswer: selectedAnswer,
                buttonOptions: questionData.buttonOptions,
                correctAnswer: questionData.answer,
                questionScene: 'Question1Scene',
                counter: 5  // Assuming this counter is part of your logic
            });
            this.startTime -= 1;
        }

        this.registry.set('gameName', questionData.key)
        this.registry.set('score', score);
        this.registry.set('correctCount', this.correctCount);
        this.registry.set('incorrectAnswers', incorrectAnswers);
        this.registry.set('correctAnswers', correctAnswers);

        // Check if there are more questions
        if (currentQuestionIndex + 1 < questions.length) {
            this.currentQuestionIndex += 1;
            this.updateQuestionAndOptions();  // Update the display for the new question
        } else {
            // If no more questions, transition to the GameSummaryScene
            this.scene.start('GameSummaryScene');
        }
    }


    customWordWrap(text, wordWrapWidth, fontSize, maxWidth) {
        let wrappedText = '';
        let words = text.split(' ');
        let line = '';

        // Create a temporary text object to measure text width
        let testTextObject = this.add.text(0, 0, '', {
            font: `${fontSize}px gaegu`,
            fill: '#ffffff'
        });

        for (let i = 0; i < words.length; i++) {
            testTextObject.setText(line + words[i] + ' ');
            if (testTextObject.width > maxWidth && i % 3 === 0 && line.length > 0) {
                wrappedText += line.trim() + '\n'; // Add the line to the wrapped text and insert a newline
                line = words[i] + ' '; // Start a new line
            } else {
                line += words[i] + ' ';
            }
        }
        wrappedText += line.trim(); // Add the last line

        // Clean up the temporary text object
        testTextObject.destroy();

        return wrappedText;
    }


    updateQuestionAndOptions() {
        // Clear old question and options
        this.clearQuestionAndOptions();

        // Get new question data
        const questionData = this.registry.get('questions')[this.currentQuestionIndex];

        // Display the new question
        this.displayQuestion(questionData, this.currentQuestionIndex);

        // Display new options
        this.displayOptions(questionData, this.registry.get('questions'), this.currentQuestionIndex);
    }

    clearQuestionAndOptions() {
        // console.log('Removing question text')
        this.questionGroup.clear(true, true);
    }


}

export default ExamPaper1Scene;