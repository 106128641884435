import { getRandomNumber, getRandomLetter, shuffleArray } from "./utility";

export const generateAlgebraQuestion = (level) => {
    let patternType;

    if (level <= 5) {
        patternType = getRandomNumber(1, 5); // Only addition
    } else if (level <= 10) {
        patternType = getRandomNumber(1, 2); // Addition and subtraction
    } else if (level <= 15) {
        patternType = getRandomNumber(1, 3); // Addition, subtraction, and multiplication
    } else if (level <= 20) {
        patternType = getRandomNumber(1, 4); // Addition, subtraction, multiplication, and division
    } else if (level <= 25) {
        patternType = getRandomNumber(1, 4); // Combine operations with two variables on one side
    } else {
        patternType = getRandomNumber(1, 4); // Complex equations with two variables
    }

    let variable1 = getRandomLetter(['a', 'b', 'c', 'x', 'y', 'z']);
    let variable2; // Define a second variable for patterns requiring two variables
    let question, correctAnswer, buttonOptions = [];

    switch (patternType) {
        case 1: // Addition
            let addValue = getRandomNumber(1, 10);
            correctAnswer = addValue + getRandomNumber(1, 10);
            question = `What is ${variable1} + ${addValue} if ${variable1} = ${correctAnswer - addValue}?`;
            break;
        case 2: // Subtraction
            let subValue = getRandomNumber(1, 10);
            correctAnswer = subValue + getRandomNumber(1, 10);
            question = `What is ${variable1} - ${subValue} if ${variable1} = ${correctAnswer + subValue}?`;
            break;
        case 3: // Multiplication
            let mulValue = getRandomNumber(1, 10);
            correctAnswer = mulValue * getRandomNumber(1, 5);
            question = `What is ${variable1} x ${mulValue} if ${variable1} = ${correctAnswer / mulValue}?`;
            break;
        case 4: // Division
            let divValue = getRandomNumber(1, 5); // Keep small to avoid fractions
            correctAnswer = divValue * getRandomNumber(1, 10);
            question = `What is ${variable1} / ${divValue} if ${variable1} = ${correctAnswer * divValue}?`;
            break;
        case 5: // Combine operations with two variables
            // Ensuring that the equation leads to an integer solution for variable2
            variable2 = getRandomLetter(['a', 'b', 'c', 'x', 'y', 'z'].filter(v => v !== variable1));
            let combineValue1 = getRandomNumber(1, 10); // Value for variable1
            // let variable1Value = combineValue1; // Assigning the generated value to variable1
            let totalResult = getRandomNumber(11, 20); // Ensuring totalResult is larger than combineValue1 for simplicity
            correctAnswer = totalResult - combineValue1; // Solving for variable2
            question = `If ${variable1} + ${variable2} = ${totalResult}, and ${variable1} = ${combineValue1}, what is ${variable2}?`;
            break;
        case 6: // Complex equations with two variables
            // Generate random coefficients and results for the equations
            let coefficient1 = getRandomNumber(1, 5) * 2; // Ensure it's even for simplicity in division
            let coefficient2 = getRandomNumber(1, 5);
            let result1 = getRandomNumber(20, 40); // Total result of the first equation
            let bCoefficient = getRandomNumber(1, 5) * 2; // Coefficient for b in the second equation, also even
            variable2Value = getRandomNumber(1, 5); // Assign a random value to variable2 (b)
            let result2 = bCoefficient * variable2Value; // Result of the second equation based on b's value

            // Construct the first equation and solve for variable1 (x)
            // Equation form: coefficient1 * x + coefficient2 * b = result1
            // Second equation gives b's value: bCoefficient * b = result2
            // Solve the first equation for x using b's value from the second equation
            correctAnswer = (result1 - coefficient2 * variable2Value) / coefficient1;

            question = `If ${coefficient1}${variable1} + ${coefficient2}${variable2} = ${result1} and ${bCoefficient}${variable2} = ${result2}, what is ${variable1}?`;

            // Ensure correctAnswer is an integer; if not, recalculate
            if (!Number.isInteger(correctAnswer)) {
                // Fallback strategy or recalculation logic here
                // This is a simplistic adjustment; in practice, you might adjust the coefficients or results to ensure integer solutions
                correctAnswer = Math.round(correctAnswer);
            }

            // Generate distinct wrong answers ensuring they are integers and not equal to the correct answer
            let wrongAnswer1 = correctAnswer + getRandomNumber(1, 3);
            let wrongAnswer2;
            do {
                wrongAnswer2 = correctAnswer - getRandomNumber(1, 3);
            } while (wrongAnswer2 === wrongAnswer1 || wrongAnswer2 === correctAnswer);

            let buttonOptions = shuffleArray([correctAnswer, wrongAnswer1, wrongAnswer2]);
            break;

    }

    // Generate wrong answers
    let wrongAnswer1 = correctAnswer + getRandomNumber(-3, 3);
    let wrongAnswer2 = correctAnswer + getRandomNumber(-3, 3);
    buttonOptions = shuffleArray([correctAnswer, wrongAnswer1, wrongAnswer2]);

    return {
        question,
        answer: correctAnswer,
        buttonOptions
    };
};
