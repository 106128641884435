import BaseScene from "./BaseScene"; // Adjust the path as necessary
import mmBackground from "../assets/backgrounds/MathsMeadow.jpg";
import navButton from "../assets/buttons/NavButton.png";
import oddsEvensButton from "../assets/buttons/OddsEvensButton.png"
import simpleSumsButton from "../assets/buttons/SimpleSumsButton.png"
import algebraButton from "../assets/buttons/AlgebraButton.png"
import moneyButton from "../assets/buttons/MoneyButton.png"
import roundingUpButton from "../assets/buttons/RoundingUpButton.png"
import jumbledNumberButton from "../assets/buttons/JumbledNumberButton.png"
import timeButton from "../assets/buttons/TimeButton.png"
import daysInMonthButton from "../assets/buttons/DaysInMonthButton.png"
import unitConversionButton from "../assets/buttons/UnitConversionButton.png"
import sequencesButton from "../assets/buttons/SequencesButton.png"
import fractionsButton from "../assets/buttons/FractionsButton.png"
import divisionButton from "../assets/buttons/DivisionButton.png"
import timesTablesButton from "../assets/buttons/TimesTablesButton.png"
import missingSignButton from "../assets/buttons/MissingSignButton.png"
import { loadAllAssets } from './sharedAssets';

import sliderBar from "../assets/sliderBarMM.png"
import sliderHandle from "../assets/sliderHandleMM.png"
class MathsMeadowScene extends BaseScene {
    constructor() {
        super('MathsMeadowScene');
        this.currentTopicIndex = 0; // Start at the first topic
        this.currentButton = null; // To keep track of the current button displayed
        this.lastDirection = 1; // Default direction (down)

        this.level = 15;
    }

    init() {
        // this.registry.set('debug', true);
        this.registry.set('debug', false);
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        if (isPortrait) {
            this.registry.set('isPortrait', true);
        }
    }

    preload() {
        // super.preloadCommon();
        this.preloadCommon();
        loadAllAssets(this);
        this.load.image('mmBackgroundImage', mmBackground);
        this.load.image('navButton', navButton);
        this.load.image('oddsEvensButton', oddsEvensButton);
        this.load.image('simpleSumsButton', simpleSumsButton);
        this.load.image('algebraButton', algebraButton);
        this.load.image('moneyButton', moneyButton);
        this.load.image('roundingUpButton', roundingUpButton);
        this.load.image('jumbledNumberButton', jumbledNumberButton);
        this.load.image('timeButton', timeButton);
        this.load.image('daysInMonthButton', daysInMonthButton);
        this.load.image('unitConversionButton', unitConversionButton);
        this.load.image('sequencesButton', sequencesButton);
        this.load.image('fractionsButton', fractionsButton);
        this.load.image('divisionButton', divisionButton);
        this.load.image('timesTablesButton', timesTablesButton);
        this.load.image('missingSignButton', missingSignButton);

        this.load.image('sliderBar', sliderBar);
        this.load.image('sliderHandle', sliderHandle);

    }

    create() {
        const debug = this.registry.get('debug');
        const isPortrait = this.registry.get('isPortrait');

        this.setBackground('mmBackgroundImage');
        this.backgroundOverlay()
        const userData = this.game.registry.get('userData') || {};
        const userRoles =this.game.registry.get('userRoles') || [];
        const baseFontSize = parseFloat(this.responsiveFontSize(10, 0.03).replace('px', ''));
        const strokeThickness = this.sys.game.config.width * 0.006;

        const fontSize = this.sys.game.config.width * 0.05;
        const fontSizeSmall = this.sys.game.config.width * 0.02;

        const pageBackgroundX = this.sys.game.config.width * -0.05;
        const pageBackgroundY = isPortrait ? this.sys.game.config.height * 0.1 : this.sys.game.config.height * 0.10;
        const pageBackgroundWidth = this.sys.game.config.width * 0.3;
        const pageBackgroundHeight = this.sys.game.config.height * 0.05;
        const pageXPosition = this.sys.game.config.width * 0.15;

        // Create the UI background
        this.createGradientBackground(pageBackgroundX, pageBackgroundY, pageBackgroundWidth, pageBackgroundHeight, 0xFBBC05, 0.8);


        // // Now, add other elements to your scene, like the animated text
        this.createAnimatedText(pageXPosition, pageBackgroundY, `Maths Meadow`, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        }).setDepth(1000);

        const pointsBackgroundX = isPortrait ? this.sys.game.config.width * 0.65 : this.sys.game.config.width * 0.8;
        const pointsBackgroundY = isPortrait ? this.sys.game.config.height * 0.1 : this.sys.game.config.height * 0.10;
        const pointsBackgroundWidth = this.sys.game.config.width * 0.5;
        const pointsBackgroundHeight = this.sys.game.config.height * 0.07;
        const starYPosition = isPortrait ? this.sys.game.config.height * 0.07 : this.sys.game.config.height * 0.03;
        const starXPosition = isPortrait ? this.sys.game.config.width * 0.85 : this.sys.game.config.width * 0.9;
        const starScale = isPortrait ? 0.1 : 0.2;
        this.createGradientBackground(pointsBackgroundX, pointsBackgroundY, pointsBackgroundWidth, pointsBackgroundHeight, 0xFBBC05, 0.8, true);


        this.add.image(starXPosition, starYPosition, 'pointsStar')
        .setOrigin(0, 0) // Adjust origin as needed, e.g., 0.5 for center
        .setDepth(1000)
        .setScale(starScale);


        const musicButtonXPosition = this.sys.game.config.width * 0.95;
        const musicButtonYPosition = this.sys.game.config.height * 0.2;
        this.musicButton = this.createButton(
            musicButtonXPosition, // X position
            musicButtonYPosition, // Y position
            'musicButton', // Texture key for the button
            null, // Hover texture (null if not used)
            () => this.toggleMusicAndTint(), // Action on click
            0.5, // Scale multiplier
            false // Availability
        );

        const sliderX = this.sys.game.config.width * 0.5;
        const sliderY = this.sys.game.config.height * 0.8; // Note: Adjusting this to use height instead of width for placement

        // Create and style the text label for the slider
        let difficultyLabel = this.createAnimatedText(sliderX, this.sys.game.config.height * 0.9, "Choose the Difficulty!", {
            fontFamily: 'gaegu',
            fontSize: `${this.getBaseFontSize()}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: this.getStrokeThickness(),
            align: 'center'
        });

        const sliderBarScale = isPortrait ? this.sys.game.config.width * 0.5 : this.sys.game.config.width * 0.15;
        const sliderHandleScale =  sliderBarScale * 0.2;
        this.createSlider(sliderX, sliderY, sliderBarScale, sliderBarScale, sliderHandleScale, sliderHandleScale, difficultyLabel )


        const backButtonXPosition = isPortrait? this.sys.game.config.width * 0.05 : this.sys.game.config.width * 0.05;
        const backButtonYPosition = isPortrait ? this.sys.game.config.height * 0.15 : this.sys.game.config.height * 0.2;
        const backButtonScale = isPortrait ? 0.5 : 1;
        this.createButton(backButtonXPosition, backButtonYPosition, 'backButton', null, () => this.scene.start('MenuScene'), backButtonScale);

        const menuButtonXPosition = isPortrait ? this.sys.game.config.width * 0.83 : this.sys.game.config.width * 0.7;
        const menuButtonYupPosition = isPortrait ? this.sys.game.config.height * 0.35 : this.sys.game.config.height * 0.4;
        const menuButtonYdownPosition = isPortrait ? this.sys.game.config.height * 0.75 : this.sys.game.config.height * 0.6;
        // Rotated and functional nav buttons
        const navButtonUp = this.createButton(menuButtonXPosition, menuButtonYupPosition, 'navButton', null, () => this.changeTopic(1));
        navButtonUp.angle = -90;  // Rotates the button to face North

        const navButtonDown = this.createButton(menuButtonXPosition, menuButtonYdownPosition, 'navButton', null, () => this.changeTopic(-1));
        navButtonDown.angle = 90;  // Rotates the button to face South


        this.currentTopic = [

            {
                key: 'simple',
                button: 'simpleSumsButton',
                reactComponent: 'generateSimpleQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'missingSign',
                button: 'missingSignButton',
                reactComponent: 'generateSymbolQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'timesTables',
                button: 'timesTablesButton',
                reactComponent: 'generateTimesTableQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['MultiplicationScene'],
                available: true
            },
            {
                key: 'division',
                button: 'divisionButton',
                reactComponent: 'generateDivisionQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: userRoles.includes('developer') || userRoles.includes('7plus') || userRoles.includes('8plus')
            },
            {
                key: 'fractions',
                button: 'fractionsButton',
                reactComponent: 'generateFractionMathQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'sequence',
                button: 'sequencesButton',
                reactComponent: 'generateSequenceQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
                // available: userRoles.includes('developer') || userRoles.includes('7plus') || userRoles.includes('8plus')
            },
            {
                key: 'conversion',
                button: 'unitConversionButton',
                reactComponent: 'generateConversionQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'months',
                button: 'daysInMonthButton',
                reactComponent: 'generateDayInMonthQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'time',
                button: 'timeButton',
                reactComponent: 'generateTimeQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'minMax',
                button: 'jumbledNumberButton',
                reactComponent: 'generateMinMaxNumberQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'rounding',
                button: 'roundingUpButton',
                reactComponent: 'generateRoundingQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'coins',
                button: 'moneyButton',
                reactComponent: 'generateMoneyQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'algebra',
                button: 'algebraButton',
                reactComponent: 'generateAlgebraQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'oddOrEven',
                button: 'oddsEvensButton',
                reactComponent: 'generateOddOrEvenQuestion',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: '7plusMaths',
                button: 'sevenPlusButton',
                reactComponent: 'generate7plusMaths',
                parentScene: 'MathsMeadowScene',
                possibleQuestionScenes: ['ExamPaper1Scene'],
                available: userRoles.includes('developer')
            },
        ];

        this.showCurrentButton(this.lastDirection);

        // Set up key listeners
        this.setupKeyboardInputs(
            () => this.changeTopic(1),
            () => this.changeTopic(-1)
        );
        // this.input.keyboard.on('keydown-UP', () => this.changeTopic(1));
        // this.input.keyboard.on('keydown-DOWN', () => this.changeTopic(-1));
    }
}

export default MathsMeadowScene;
