import { shuffleArray } from "./utility";

export const generateSpellingQuestion = async (level, dbName) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';

  // Fetch a single question based on level and dbName
  const response = await fetch(`${API_BASE_URL}/api/questions/${dbName}?level=${level}&limit=1`);

  if (response.ok) {
    const data = await response.json();


    // Assuming that the data is an array and you take the first element
    const fetchedSpelling = data[0];
    const { word, badWord1, badWord2 } = fetchedSpelling;

    return {
      question: `What is the correct spelling?`,
      answer: word,
      buttonOptions: shuffleArray([word, badWord1, badWord2])
    };
  } else {
    console.error('Failed to fetch question from API');
    return null;
  }
};
