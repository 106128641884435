import React, { useEffect, useRef, useState } from 'react';
import '../css/React.css';
import Menu from './Menu';

const Stats = () => {


  return (
    <div className="stats-page">
      <Menu />
      <h1>Stats will show here</h1>
    </div>
  );
};

export default Stats;
