import React, { useState, useEffect } from 'react';
import BusStop from '../animations/busstop';  // Assuming you still want to use this
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';

function Overlay({ overlayVisible, setOverlayVisible, selectedQuestionType, questionData }) {
  const [hint, setHint] = useState("Loading hint...");

  useEffect(() => {
    const getHint = async () => {
      const response = await fetch(`${API_BASE_URL}/generate_hint`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(questionData),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log('Hint from API:', data.hint);
        setHint(data.hint); // Update the hint state
      } else {
        console.error('Failed to fetch hint from the API');
        setHint("Failed to fetch hint."); // Set error message if fetching fails
      }
    };

    if (overlayVisible) {
      // console.log('Selected Question Type:', selectedQuestionType);
      // console.log('Question Data:', questionData);
      getHint(); // Call the getHint function
    }
  }, [overlayVisible, questionData]); // Re-run the effect if overlayVisible or questionData changes

  const handleClick = () => {
    setOverlayVisible(false); // Set the overlay to be not visible
    setHint("Loading hint...");
  };

  // Return the JSX directly from the functional component
  if (selectedQuestionType === 'conversion' && overlayVisible) {
    return (
      <div onClick={handleClick} className="overlay">
        <div className="overlay-content">
          <p>{hint}</p>
          <h1>Common Conversions</h1>
          <ul>
            <li>10 years = 1 decade</li>
            <li>365 days = 1 year</li>
            <li>7 days = 1 week</li>
            <li>1,000 grams = 1 kilogram</li>
            <li>1,000 milligrams = 1 gram</li>
            <li>100 pence = 1 pound (£1)</li>
            <li>1,000 metres = 1 kilometre</li>
            <li>100 centimetres = 1 metre</li>
            <li>1,000 millimetres = 1 metre</li>
            <li>10 millimetres = 1 centimetre</li>
          </ul>

        </div>
      </div>
    );
  } else if (overlayVisible) {
    // General overlay content with the hint from API
    return (
      <div onClick={handleClick} className="overlay">
        <div className="overlay-content">
          <p>{hint}</p>
        </div>
      </div>
    );
  }

  return null;
}

export default Overlay;