import { getRandomNumber, shuffleArray } from "./utility";

const punctuationSentences = [
  'Wow, what a beautiful garden!',
  'Where are you going?',
  'She said, "Hurry up."',
  'Don\'t touch that!',
  'Wait, what?',
  'It\'s raining, but I forgot my umbrella.',
  'Look, a shooting star!',
  'Oh no, I dropped my phone.',
  'What time is it?',
  'Hey, can you help me?',
  'I love ice cream!',
  'Are you coming to the party?',
  'Shh, be quiet!',
  'Mum, I\'m hungry.',
  'No way, that\'s amazing!',
  'Yes, I would love some.',
  'Who ate the last cookie?',
  'Wow, you did it!',
  'He exclaimed, "That\'s awesome!"',
  'Well, what do we have here?',
  'Oh, I see what you did.',
  'Can you pass the salt?',
  'Yay! we won!',
  'Why are you late?',
  'Stop, that\'s enough.',
  'Watch out! There\'s a car coming!',
  'I can\'t believe it\'s already December.',
  'Oops, I forgot my keys.',
  'Is it your birthday today?',
  'Hooray! It\'s the weekend.',
  'Alas, she knew it was too late.',
  'How are you doing?',
];



const possiblePunctuations = ['!', '.', ',', '?', '"'];

export const generatePunctuationQuestion = () => {
    // Step 1: Randomly select a sentence
    const randomIndex = Math.floor(Math.random() * punctuationSentences.length);
    const selectedSentence = punctuationSentences[randomIndex];

    // Step 2: Identify the punctuation marks in the sentence
    const punctuationsInSentence = Array.from(selectedSentence).filter(char => possiblePunctuations.includes(char));

    // Step 3: Randomly choose one punctuation to remove
    const randomPunctuationIndex = getRandomNumber(0, punctuationsInSentence.length - 1);
    const missingPunctuation = punctuationsInSentence[randomPunctuationIndex];

    // Step 4: Replace the missing punctuation with an underscore
    const question = selectedSentence.replace(missingPunctuation, '___');

    // Step 5: Prepare the options
    const buttonOptions = shuffleArray(possiblePunctuations);

    return {
      question,
      answer: missingPunctuation,
      buttonOptions
    };
};
