import { getRandomNumber, shuffleArray } from "./utility";

export const generateDivisionQuestion = (level) => {
    // Default values for the smallest level range
    let minDividend = 10;
    let maxDividend = 20;
    let divisorRange = [2, 3];

    // Adjust values based on level
    if (level <= 3) {
        // Values already set by default
    } else if (level <= 6) {
        minDividend = 20;
        maxDividend = 50;
        divisorRange = [2, 4];
    } else if (level <= 9) {
        minDividend = 50;
        maxDividend = 100;
        divisorRange = [2, 5];
    } else if (level <= 12) {
        minDividend = 100;
        maxDividend = 200;
        divisorRange = [2, 6];
    } else if (level <= 15) {
        minDividend = 200;
        maxDividend = 300;
        divisorRange = [2, 7];
    } else if (level <= 18) {
        minDividend = 300;
        maxDividend = 400;
        divisorRange = [2, 8];
    } else if (level <= 21) {
        minDividend = 400;
        maxDividend = 500;
        divisorRange = [2, 9];
    } else if (level <= 24) {
        minDividend = 500;
        maxDividend = 600;
        divisorRange = [2, 10];
    } else if (level <= 27) {
        minDividend = 600;
        maxDividend = 800;
        divisorRange = [2, 11];
    } else {
        minDividend = 800;
        maxDividend = 1000;  // Up to 1000 for the last levels
        divisorRange = [2, 12];
    }

    let num1, num2, quotient, remainder;
    let attempt = 0;  // To keep track of the number of attempts
    let answer, wrongOption1, wrongOption2;

    do {
        num2 = getRandomNumber(divisorRange[0], divisorRange[1]);

        if (level <= 5) {
            // Ensure no remainder for the first 5 levels
            let multiplier = getRandomNumber(1, Math.floor(maxDividend / num2));
            num1 = num2 * multiplier;
        } else {
            num1 = getRandomNumber(minDividend, maxDividend);
        }

        quotient = Math.floor(num1 / num2);
        remainder = num1 % num2;

        answer = quotient.toString() + (remainder !== 0 ? ` rem ${remainder}` : '');  // Don't show 'rem 0'

        wrongOption1 = `${quotient + (quotient > 1 ? -1 : 1)}` + (remainder !== 0 ? ` rem ${remainder}` : '');
        wrongOption2 = `${quotient}` + (remainder !== 0 ? ` rem ${remainder === 0 ? 1 : remainder - 1}` : '');

        attempt++;

        // Provide a fallback if unique wrong options can't be generated
        if (attempt > 10) {
            wrongOption1 = `${quotient + 2}`;
            wrongOption2 = `${quotient - 2}`;
            break;
        }
    } while (wrongOption1 === wrongOption2 || wrongOption1 === answer || wrongOption2 === answer);

    let question = `What is the result of '${num1} ÷ ${num2}'?`;

    let buttonOptions = shuffleArray([answer, wrongOption1, wrongOption2]);

    return {
        question,
        answer,
        buttonOptions
    };
};